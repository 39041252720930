// Colors
//
// Settings for the color
// サイトの背景カラー
$site_bg_color: #FFF;
// キーカラー
$key_color_1: #1a4fa3;
$key_color_2: #fff500;
// 背景カラー
$bg_color_2: #EAE0C6;
// フォントカラー
$main_color: #FFF;
$main_color_2: #333;

// リンクカラー
$link_color: #FFF;
$link_color_hover: #F00;
$border_color: #666;
// ナビカラー
$gnav_color: #408EA5;
$gnav_color_bg_pc: rgba($key_color_1, 0.9); //RGBで色指定
$gnav_color_bg_sp: #E4EBEF; //RGBで色指定
$gnav_color_txt: #FFF;


//gnav PC リスト 横マージン
$gnav_margin: 1rem;


// SNS
// アイコン数
$sns_quantity: 2;
// pc 横幅と間隔
$sns_width: 44px;
$sns_margin: 26px;
// sp 横幅と間隔
$sns_width_sp: 44px;
$sns_margin_sp: 26px;


// font-family
//
// Settings for the font-family
$base_font: "Shippori Mincho", serif;
$base_font_2: "ＭＳ Ｐ明朝", "MS PMincho","ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", serif;
$nav_font: "Noto Sans JP", sans-serif;
$tit_font: "Pinyon Script", cursive;
// width,height
//
// Settings for the width
$inner_width: 986px;

//mixin
//
//Settings for the flex
@mixin flex() {
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
}
