html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.ds-row {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
}

.dcol {
  position: relative;
  margin: 0 3rem 0 0;
  width: 100%;
}

.ds_col {
  position: relative;
  margin: 0 4rem 0 0;
  width: 100%;
}

@media only screen and (min-width: 992px) {
  .dcolumns_lg_12 .ds_col:nth-child(12n), .dcolumns_lg_12 .dcol:nth-child(12n), .dcolumns_lg_11 .ds_col:nth-child(11n), .dcolumns_lg_11 .dcol:nth-child(11n), .dcolumns_lg_10 .ds_col:nth-child(10n), .dcolumns_lg_10 .dcol:nth-child(10n), .dcolumns_lg_9 .ds_col:nth-child(9n), .dcolumns_lg_9 .dcol:nth-child(9n), .dcolumns_lg_8 .ds_col:nth-child(8n), .dcolumns_lg_8 .dcol:nth-child(8n), .dcolumns_lg_7 .ds_col:nth-child(7n), .dcolumns_lg_7 .dcol:nth-child(7n), .dcolumns_lg_6 .ds_col:nth-child(6n), .dcolumns_lg_6 .dcol:nth-child(6n), .dcolumns_lg_5 .ds_col:nth-child(5n), .dcolumns_lg_5 .dcol:nth-child(5n), .dcolumns_lg_4 .ds_col:nth-child(4n), .dcolumns_lg_4 .dcol:nth-child(4n), .dcolumns_lg_3 .ds_col:nth-child(3n), .dcolumns_lg_3 .dcol:nth-child(3n), .dcolumns_lg_2 .ds_col:nth-child(2n), .dcolumns_lg_2 .dcol:nth-child(2n), .dcolumns_lg_1 .ds_col:nth-child(1n), .dcolumns_lg_1 .dcol:nth-child(1n) {
    margin-right: 0 !important;
  }

  .dcolumns_lg_1 .dcol {
    width: -webkit-calc((100% - 0rem)/1);
    width: -moz-calc((100% - 0rem)/1);
    width: calc((100% - 0rem)/1);
  }
  .dcolumns_lg_1 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 0rem);
    width: -moz-calc(8.3333333333% - 0rem);
    width: calc(8.3333333333% - 0rem);
  }
  .dcolumns_lg_1 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 0rem);
    width: -moz-calc(16.6666666667% - 0rem);
    width: calc(16.6666666667% - 0rem);
  }
  .dcolumns_lg_1 .dcol-lg-3 {
    width: -webkit-calc(25% - 0rem);
    width: -moz-calc(25% - 0rem);
    width: calc(25% - 0rem);
  }
  .dcolumns_lg_1 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 0rem);
    width: -moz-calc(33.3333333333% - 0rem);
    width: calc(33.3333333333% - 0rem);
  }
  .dcolumns_lg_1 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 0rem);
    width: -moz-calc(41.6666666667% - 0rem);
    width: calc(41.6666666667% - 0rem);
  }
  .dcolumns_lg_1 .dcol-lg-6 {
    width: -webkit-calc(50% - 0rem);
    width: -moz-calc(50% - 0rem);
    width: calc(50% - 0rem);
  }
  .dcolumns_lg_1 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 0rem);
    width: -moz-calc(58.3333333333% - 0rem);
    width: calc(58.3333333333% - 0rem);
  }
  .dcolumns_lg_1 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 0rem);
    width: -moz-calc(66.6666666667% - 0rem);
    width: calc(66.6666666667% - 0rem);
  }
  .dcolumns_lg_1 .dcol-lg-9 {
    width: -webkit-calc(75% - 0rem);
    width: -moz-calc(75% - 0rem);
    width: calc(75% - 0rem);
  }
  .dcolumns_lg_1 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 0rem);
    width: -moz-calc(83.3333333333% - 0rem);
    width: calc(83.3333333333% - 0rem);
  }
  .dcolumns_lg_1 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 0rem);
    width: -moz-calc(91.6666666667% - 0rem);
    width: calc(91.6666666667% - 0rem);
  }
  .dcolumns_lg_1 .dcol-lg-12 {
    width: -webkit-calc(100% - 0rem);
    width: -moz-calc(100% - 0rem);
    width: calc(100% - 0rem);
  }
  .dcolumns_lg_2 .dcol {
    width: -webkit-calc((100% - 4rem)/2);
    width: -moz-calc((100% - 4rem)/2);
    width: calc((100% - 4rem)/2);
  }
  .dcolumns_lg_2 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 2rem);
    width: -moz-calc(8.3333333333% - 2rem);
    width: calc(8.3333333333% - 2rem);
  }
  .dcolumns_lg_2 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 2rem);
    width: -moz-calc(16.6666666667% - 2rem);
    width: calc(16.6666666667% - 2rem);
  }
  .dcolumns_lg_2 .dcol-lg-3 {
    width: -webkit-calc(25% - 2rem);
    width: -moz-calc(25% - 2rem);
    width: calc(25% - 2rem);
  }
  .dcolumns_lg_2 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 2rem);
    width: -moz-calc(33.3333333333% - 2rem);
    width: calc(33.3333333333% - 2rem);
  }
  .dcolumns_lg_2 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 2rem);
    width: -moz-calc(41.6666666667% - 2rem);
    width: calc(41.6666666667% - 2rem);
  }
  .dcolumns_lg_2 .dcol-lg-6 {
    width: -webkit-calc(50% - 2rem);
    width: -moz-calc(50% - 2rem);
    width: calc(50% - 2rem);
  }
  .dcolumns_lg_2 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 2rem);
    width: -moz-calc(58.3333333333% - 2rem);
    width: calc(58.3333333333% - 2rem);
  }
  .dcolumns_lg_2 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 2rem);
    width: -moz-calc(66.6666666667% - 2rem);
    width: calc(66.6666666667% - 2rem);
  }
  .dcolumns_lg_2 .dcol-lg-9 {
    width: -webkit-calc(75% - 2rem);
    width: -moz-calc(75% - 2rem);
    width: calc(75% - 2rem);
  }
  .dcolumns_lg_2 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 2rem);
    width: -moz-calc(83.3333333333% - 2rem);
    width: calc(83.3333333333% - 2rem);
  }
  .dcolumns_lg_2 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 2rem);
    width: -moz-calc(91.6666666667% - 2rem);
    width: calc(91.6666666667% - 2rem);
  }
  .dcolumns_lg_2 .dcol-lg-12 {
    width: -webkit-calc(100% - 2rem);
    width: -moz-calc(100% - 2rem);
    width: calc(100% - 2rem);
  }
  .dcolumns_lg_3 .dcol {
    width: -webkit-calc((100% - 8rem)/3);
    width: -moz-calc((100% - 8rem)/3);
    width: calc((100% - 8rem)/3);
  }
  .dcolumns_lg_3 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 2.6666666667rem);
    width: -moz-calc(8.3333333333% - 2.6666666667rem);
    width: calc(8.3333333333% - 2.6666666667rem);
  }
  .dcolumns_lg_3 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 2.6666666667rem);
    width: -moz-calc(16.6666666667% - 2.6666666667rem);
    width: calc(16.6666666667% - 2.6666666667rem);
  }
  .dcolumns_lg_3 .dcol-lg-3 {
    width: -webkit-calc(25% - 2.6666666667rem);
    width: -moz-calc(25% - 2.6666666667rem);
    width: calc(25% - 2.6666666667rem);
  }
  .dcolumns_lg_3 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 2.6666666667rem);
    width: -moz-calc(33.3333333333% - 2.6666666667rem);
    width: calc(33.3333333333% - 2.6666666667rem);
  }
  .dcolumns_lg_3 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 2.6666666667rem);
    width: -moz-calc(41.6666666667% - 2.6666666667rem);
    width: calc(41.6666666667% - 2.6666666667rem);
  }
  .dcolumns_lg_3 .dcol-lg-6 {
    width: -webkit-calc(50% - 2.6666666667rem);
    width: -moz-calc(50% - 2.6666666667rem);
    width: calc(50% - 2.6666666667rem);
  }
  .dcolumns_lg_3 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 2.6666666667rem);
    width: -moz-calc(58.3333333333% - 2.6666666667rem);
    width: calc(58.3333333333% - 2.6666666667rem);
  }
  .dcolumns_lg_3 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 2.6666666667rem);
    width: -moz-calc(66.6666666667% - 2.6666666667rem);
    width: calc(66.6666666667% - 2.6666666667rem);
  }
  .dcolumns_lg_3 .dcol-lg-9 {
    width: -webkit-calc(75% - 2.6666666667rem);
    width: -moz-calc(75% - 2.6666666667rem);
    width: calc(75% - 2.6666666667rem);
  }
  .dcolumns_lg_3 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 2.6666666667rem);
    width: -moz-calc(83.3333333333% - 2.6666666667rem);
    width: calc(83.3333333333% - 2.6666666667rem);
  }
  .dcolumns_lg_3 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 2.6666666667rem);
    width: -moz-calc(91.6666666667% - 2.6666666667rem);
    width: calc(91.6666666667% - 2.6666666667rem);
  }
  .dcolumns_lg_3 .dcol-lg-12 {
    width: -webkit-calc(100% - 2.6666666667rem);
    width: -moz-calc(100% - 2.6666666667rem);
    width: calc(100% - 2.6666666667rem);
  }
  .dcolumns_lg_4 .dcol {
    width: -webkit-calc((100% - 12rem)/4);
    width: -moz-calc((100% - 12rem)/4);
    width: calc((100% - 12rem)/4);
  }
  .dcolumns_lg_4 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 3rem);
    width: -moz-calc(8.3333333333% - 3rem);
    width: calc(8.3333333333% - 3rem);
  }
  .dcolumns_lg_4 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 3rem);
    width: -moz-calc(16.6666666667% - 3rem);
    width: calc(16.6666666667% - 3rem);
  }
  .dcolumns_lg_4 .dcol-lg-3 {
    width: -webkit-calc(25% - 3rem);
    width: -moz-calc(25% - 3rem);
    width: calc(25% - 3rem);
  }
  .dcolumns_lg_4 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 3rem);
    width: -moz-calc(33.3333333333% - 3rem);
    width: calc(33.3333333333% - 3rem);
  }
  .dcolumns_lg_4 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 3rem);
    width: -moz-calc(41.6666666667% - 3rem);
    width: calc(41.6666666667% - 3rem);
  }
  .dcolumns_lg_4 .dcol-lg-6 {
    width: -webkit-calc(50% - 3rem);
    width: -moz-calc(50% - 3rem);
    width: calc(50% - 3rem);
  }
  .dcolumns_lg_4 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 3rem);
    width: -moz-calc(58.3333333333% - 3rem);
    width: calc(58.3333333333% - 3rem);
  }
  .dcolumns_lg_4 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 3rem);
    width: -moz-calc(66.6666666667% - 3rem);
    width: calc(66.6666666667% - 3rem);
  }
  .dcolumns_lg_4 .dcol-lg-9 {
    width: -webkit-calc(75% - 3rem);
    width: -moz-calc(75% - 3rem);
    width: calc(75% - 3rem);
  }
  .dcolumns_lg_4 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 3rem);
    width: -moz-calc(83.3333333333% - 3rem);
    width: calc(83.3333333333% - 3rem);
  }
  .dcolumns_lg_4 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 3rem);
    width: -moz-calc(91.6666666667% - 3rem);
    width: calc(91.6666666667% - 3rem);
  }
  .dcolumns_lg_4 .dcol-lg-12 {
    width: -webkit-calc(100% - 3rem);
    width: -moz-calc(100% - 3rem);
    width: calc(100% - 3rem);
  }
  .dcolumns_lg_5 .dcol {
    width: -webkit-calc((100% - 16rem)/5);
    width: -moz-calc((100% - 16rem)/5);
    width: calc((100% - 16rem)/5);
  }
  .dcolumns_lg_5 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 3.2rem);
    width: -moz-calc(8.3333333333% - 3.2rem);
    width: calc(8.3333333333% - 3.2rem);
  }
  .dcolumns_lg_5 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 3.2rem);
    width: -moz-calc(16.6666666667% - 3.2rem);
    width: calc(16.6666666667% - 3.2rem);
  }
  .dcolumns_lg_5 .dcol-lg-3 {
    width: -webkit-calc(25% - 3.2rem);
    width: -moz-calc(25% - 3.2rem);
    width: calc(25% - 3.2rem);
  }
  .dcolumns_lg_5 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 3.2rem);
    width: -moz-calc(33.3333333333% - 3.2rem);
    width: calc(33.3333333333% - 3.2rem);
  }
  .dcolumns_lg_5 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 3.2rem);
    width: -moz-calc(41.6666666667% - 3.2rem);
    width: calc(41.6666666667% - 3.2rem);
  }
  .dcolumns_lg_5 .dcol-lg-6 {
    width: -webkit-calc(50% - 3.2rem);
    width: -moz-calc(50% - 3.2rem);
    width: calc(50% - 3.2rem);
  }
  .dcolumns_lg_5 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 3.2rem);
    width: -moz-calc(58.3333333333% - 3.2rem);
    width: calc(58.3333333333% - 3.2rem);
  }
  .dcolumns_lg_5 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 3.2rem);
    width: -moz-calc(66.6666666667% - 3.2rem);
    width: calc(66.6666666667% - 3.2rem);
  }
  .dcolumns_lg_5 .dcol-lg-9 {
    width: -webkit-calc(75% - 3.2rem);
    width: -moz-calc(75% - 3.2rem);
    width: calc(75% - 3.2rem);
  }
  .dcolumns_lg_5 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 3.2rem);
    width: -moz-calc(83.3333333333% - 3.2rem);
    width: calc(83.3333333333% - 3.2rem);
  }
  .dcolumns_lg_5 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 3.2rem);
    width: -moz-calc(91.6666666667% - 3.2rem);
    width: calc(91.6666666667% - 3.2rem);
  }
  .dcolumns_lg_5 .dcol-lg-12 {
    width: -webkit-calc(100% - 3.2rem);
    width: -moz-calc(100% - 3.2rem);
    width: calc(100% - 3.2rem);
  }
  .dcolumns_lg_6 .dcol {
    width: -webkit-calc((100% - 20rem)/6);
    width: -moz-calc((100% - 20rem)/6);
    width: calc((100% - 20rem)/6);
  }
  .dcolumns_lg_6 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 3.3333333333rem);
    width: -moz-calc(8.3333333333% - 3.3333333333rem);
    width: calc(8.3333333333% - 3.3333333333rem);
  }
  .dcolumns_lg_6 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 3.3333333333rem);
    width: -moz-calc(16.6666666667% - 3.3333333333rem);
    width: calc(16.6666666667% - 3.3333333333rem);
  }
  .dcolumns_lg_6 .dcol-lg-3 {
    width: -webkit-calc(25% - 3.3333333333rem);
    width: -moz-calc(25% - 3.3333333333rem);
    width: calc(25% - 3.3333333333rem);
  }
  .dcolumns_lg_6 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 3.3333333333rem);
    width: -moz-calc(33.3333333333% - 3.3333333333rem);
    width: calc(33.3333333333% - 3.3333333333rem);
  }
  .dcolumns_lg_6 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 3.3333333333rem);
    width: -moz-calc(41.6666666667% - 3.3333333333rem);
    width: calc(41.6666666667% - 3.3333333333rem);
  }
  .dcolumns_lg_6 .dcol-lg-6 {
    width: -webkit-calc(50% - 3.3333333333rem);
    width: -moz-calc(50% - 3.3333333333rem);
    width: calc(50% - 3.3333333333rem);
  }
  .dcolumns_lg_6 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 3.3333333333rem);
    width: -moz-calc(58.3333333333% - 3.3333333333rem);
    width: calc(58.3333333333% - 3.3333333333rem);
  }
  .dcolumns_lg_6 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 3.3333333333rem);
    width: -moz-calc(66.6666666667% - 3.3333333333rem);
    width: calc(66.6666666667% - 3.3333333333rem);
  }
  .dcolumns_lg_6 .dcol-lg-9 {
    width: -webkit-calc(75% - 3.3333333333rem);
    width: -moz-calc(75% - 3.3333333333rem);
    width: calc(75% - 3.3333333333rem);
  }
  .dcolumns_lg_6 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 3.3333333333rem);
    width: -moz-calc(83.3333333333% - 3.3333333333rem);
    width: calc(83.3333333333% - 3.3333333333rem);
  }
  .dcolumns_lg_6 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 3.3333333333rem);
    width: -moz-calc(91.6666666667% - 3.3333333333rem);
    width: calc(91.6666666667% - 3.3333333333rem);
  }
  .dcolumns_lg_6 .dcol-lg-12 {
    width: -webkit-calc(100% - 3.3333333333rem);
    width: -moz-calc(100% - 3.3333333333rem);
    width: calc(100% - 3.3333333333rem);
  }
  .dcolumns_lg_7 .dcol {
    width: -webkit-calc((100% - 24rem)/7);
    width: -moz-calc((100% - 24rem)/7);
    width: calc((100% - 24rem)/7);
  }
  .dcolumns_lg_7 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 3.4285714286rem);
    width: -moz-calc(8.3333333333% - 3.4285714286rem);
    width: calc(8.3333333333% - 3.4285714286rem);
  }
  .dcolumns_lg_7 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 3.4285714286rem);
    width: -moz-calc(16.6666666667% - 3.4285714286rem);
    width: calc(16.6666666667% - 3.4285714286rem);
  }
  .dcolumns_lg_7 .dcol-lg-3 {
    width: -webkit-calc(25% - 3.4285714286rem);
    width: -moz-calc(25% - 3.4285714286rem);
    width: calc(25% - 3.4285714286rem);
  }
  .dcolumns_lg_7 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 3.4285714286rem);
    width: -moz-calc(33.3333333333% - 3.4285714286rem);
    width: calc(33.3333333333% - 3.4285714286rem);
  }
  .dcolumns_lg_7 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 3.4285714286rem);
    width: -moz-calc(41.6666666667% - 3.4285714286rem);
    width: calc(41.6666666667% - 3.4285714286rem);
  }
  .dcolumns_lg_7 .dcol-lg-6 {
    width: -webkit-calc(50% - 3.4285714286rem);
    width: -moz-calc(50% - 3.4285714286rem);
    width: calc(50% - 3.4285714286rem);
  }
  .dcolumns_lg_7 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 3.4285714286rem);
    width: -moz-calc(58.3333333333% - 3.4285714286rem);
    width: calc(58.3333333333% - 3.4285714286rem);
  }
  .dcolumns_lg_7 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 3.4285714286rem);
    width: -moz-calc(66.6666666667% - 3.4285714286rem);
    width: calc(66.6666666667% - 3.4285714286rem);
  }
  .dcolumns_lg_7 .dcol-lg-9 {
    width: -webkit-calc(75% - 3.4285714286rem);
    width: -moz-calc(75% - 3.4285714286rem);
    width: calc(75% - 3.4285714286rem);
  }
  .dcolumns_lg_7 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 3.4285714286rem);
    width: -moz-calc(83.3333333333% - 3.4285714286rem);
    width: calc(83.3333333333% - 3.4285714286rem);
  }
  .dcolumns_lg_7 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 3.4285714286rem);
    width: -moz-calc(91.6666666667% - 3.4285714286rem);
    width: calc(91.6666666667% - 3.4285714286rem);
  }
  .dcolumns_lg_7 .dcol-lg-12 {
    width: -webkit-calc(100% - 3.4285714286rem);
    width: -moz-calc(100% - 3.4285714286rem);
    width: calc(100% - 3.4285714286rem);
  }
  .dcolumns_lg_8 .dcol {
    width: -webkit-calc((100% - 28rem)/8);
    width: -moz-calc((100% - 28rem)/8);
    width: calc((100% - 28rem)/8);
  }
  .dcolumns_lg_8 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 3.5rem);
    width: -moz-calc(8.3333333333% - 3.5rem);
    width: calc(8.3333333333% - 3.5rem);
  }
  .dcolumns_lg_8 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 3.5rem);
    width: -moz-calc(16.6666666667% - 3.5rem);
    width: calc(16.6666666667% - 3.5rem);
  }
  .dcolumns_lg_8 .dcol-lg-3 {
    width: -webkit-calc(25% - 3.5rem);
    width: -moz-calc(25% - 3.5rem);
    width: calc(25% - 3.5rem);
  }
  .dcolumns_lg_8 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 3.5rem);
    width: -moz-calc(33.3333333333% - 3.5rem);
    width: calc(33.3333333333% - 3.5rem);
  }
  .dcolumns_lg_8 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 3.5rem);
    width: -moz-calc(41.6666666667% - 3.5rem);
    width: calc(41.6666666667% - 3.5rem);
  }
  .dcolumns_lg_8 .dcol-lg-6 {
    width: -webkit-calc(50% - 3.5rem);
    width: -moz-calc(50% - 3.5rem);
    width: calc(50% - 3.5rem);
  }
  .dcolumns_lg_8 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 3.5rem);
    width: -moz-calc(58.3333333333% - 3.5rem);
    width: calc(58.3333333333% - 3.5rem);
  }
  .dcolumns_lg_8 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 3.5rem);
    width: -moz-calc(66.6666666667% - 3.5rem);
    width: calc(66.6666666667% - 3.5rem);
  }
  .dcolumns_lg_8 .dcol-lg-9 {
    width: -webkit-calc(75% - 3.5rem);
    width: -moz-calc(75% - 3.5rem);
    width: calc(75% - 3.5rem);
  }
  .dcolumns_lg_8 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 3.5rem);
    width: -moz-calc(83.3333333333% - 3.5rem);
    width: calc(83.3333333333% - 3.5rem);
  }
  .dcolumns_lg_8 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 3.5rem);
    width: -moz-calc(91.6666666667% - 3.5rem);
    width: calc(91.6666666667% - 3.5rem);
  }
  .dcolumns_lg_8 .dcol-lg-12 {
    width: -webkit-calc(100% - 3.5rem);
    width: -moz-calc(100% - 3.5rem);
    width: calc(100% - 3.5rem);
  }
  .dcolumns_lg_9 .dcol {
    width: -webkit-calc((100% - 32rem)/9);
    width: -moz-calc((100% - 32rem)/9);
    width: calc((100% - 32rem)/9);
  }
  .dcolumns_lg_9 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 3.5555555556rem);
    width: -moz-calc(8.3333333333% - 3.5555555556rem);
    width: calc(8.3333333333% - 3.5555555556rem);
  }
  .dcolumns_lg_9 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 3.5555555556rem);
    width: -moz-calc(16.6666666667% - 3.5555555556rem);
    width: calc(16.6666666667% - 3.5555555556rem);
  }
  .dcolumns_lg_9 .dcol-lg-3 {
    width: -webkit-calc(25% - 3.5555555556rem);
    width: -moz-calc(25% - 3.5555555556rem);
    width: calc(25% - 3.5555555556rem);
  }
  .dcolumns_lg_9 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 3.5555555556rem);
    width: -moz-calc(33.3333333333% - 3.5555555556rem);
    width: calc(33.3333333333% - 3.5555555556rem);
  }
  .dcolumns_lg_9 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 3.5555555556rem);
    width: -moz-calc(41.6666666667% - 3.5555555556rem);
    width: calc(41.6666666667% - 3.5555555556rem);
  }
  .dcolumns_lg_9 .dcol-lg-6 {
    width: -webkit-calc(50% - 3.5555555556rem);
    width: -moz-calc(50% - 3.5555555556rem);
    width: calc(50% - 3.5555555556rem);
  }
  .dcolumns_lg_9 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 3.5555555556rem);
    width: -moz-calc(58.3333333333% - 3.5555555556rem);
    width: calc(58.3333333333% - 3.5555555556rem);
  }
  .dcolumns_lg_9 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 3.5555555556rem);
    width: -moz-calc(66.6666666667% - 3.5555555556rem);
    width: calc(66.6666666667% - 3.5555555556rem);
  }
  .dcolumns_lg_9 .dcol-lg-9 {
    width: -webkit-calc(75% - 3.5555555556rem);
    width: -moz-calc(75% - 3.5555555556rem);
    width: calc(75% - 3.5555555556rem);
  }
  .dcolumns_lg_9 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 3.5555555556rem);
    width: -moz-calc(83.3333333333% - 3.5555555556rem);
    width: calc(83.3333333333% - 3.5555555556rem);
  }
  .dcolumns_lg_9 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 3.5555555556rem);
    width: -moz-calc(91.6666666667% - 3.5555555556rem);
    width: calc(91.6666666667% - 3.5555555556rem);
  }
  .dcolumns_lg_9 .dcol-lg-12 {
    width: -webkit-calc(100% - 3.5555555556rem);
    width: -moz-calc(100% - 3.5555555556rem);
    width: calc(100% - 3.5555555556rem);
  }
  .dcolumns_lg_10 .dcol {
    width: -webkit-calc((100% - 36rem)/10);
    width: -moz-calc((100% - 36rem)/10);
    width: calc((100% - 36rem)/10);
  }
  .dcolumns_lg_10 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 3.6rem);
    width: -moz-calc(8.3333333333% - 3.6rem);
    width: calc(8.3333333333% - 3.6rem);
  }
  .dcolumns_lg_10 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 3.6rem);
    width: -moz-calc(16.6666666667% - 3.6rem);
    width: calc(16.6666666667% - 3.6rem);
  }
  .dcolumns_lg_10 .dcol-lg-3 {
    width: -webkit-calc(25% - 3.6rem);
    width: -moz-calc(25% - 3.6rem);
    width: calc(25% - 3.6rem);
  }
  .dcolumns_lg_10 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 3.6rem);
    width: -moz-calc(33.3333333333% - 3.6rem);
    width: calc(33.3333333333% - 3.6rem);
  }
  .dcolumns_lg_10 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 3.6rem);
    width: -moz-calc(41.6666666667% - 3.6rem);
    width: calc(41.6666666667% - 3.6rem);
  }
  .dcolumns_lg_10 .dcol-lg-6 {
    width: -webkit-calc(50% - 3.6rem);
    width: -moz-calc(50% - 3.6rem);
    width: calc(50% - 3.6rem);
  }
  .dcolumns_lg_10 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 3.6rem);
    width: -moz-calc(58.3333333333% - 3.6rem);
    width: calc(58.3333333333% - 3.6rem);
  }
  .dcolumns_lg_10 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 3.6rem);
    width: -moz-calc(66.6666666667% - 3.6rem);
    width: calc(66.6666666667% - 3.6rem);
  }
  .dcolumns_lg_10 .dcol-lg-9 {
    width: -webkit-calc(75% - 3.6rem);
    width: -moz-calc(75% - 3.6rem);
    width: calc(75% - 3.6rem);
  }
  .dcolumns_lg_10 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 3.6rem);
    width: -moz-calc(83.3333333333% - 3.6rem);
    width: calc(83.3333333333% - 3.6rem);
  }
  .dcolumns_lg_10 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 3.6rem);
    width: -moz-calc(91.6666666667% - 3.6rem);
    width: calc(91.6666666667% - 3.6rem);
  }
  .dcolumns_lg_10 .dcol-lg-12 {
    width: -webkit-calc(100% - 3.6rem);
    width: -moz-calc(100% - 3.6rem);
    width: calc(100% - 3.6rem);
  }
  .dcolumns_lg_11 .dcol {
    width: -webkit-calc((100% - 40rem)/11);
    width: -moz-calc((100% - 40rem)/11);
    width: calc((100% - 40rem)/11);
  }
  .dcolumns_lg_11 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 3.6363636364rem);
    width: -moz-calc(8.3333333333% - 3.6363636364rem);
    width: calc(8.3333333333% - 3.6363636364rem);
  }
  .dcolumns_lg_11 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 3.6363636364rem);
    width: -moz-calc(16.6666666667% - 3.6363636364rem);
    width: calc(16.6666666667% - 3.6363636364rem);
  }
  .dcolumns_lg_11 .dcol-lg-3 {
    width: -webkit-calc(25% - 3.6363636364rem);
    width: -moz-calc(25% - 3.6363636364rem);
    width: calc(25% - 3.6363636364rem);
  }
  .dcolumns_lg_11 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 3.6363636364rem);
    width: -moz-calc(33.3333333333% - 3.6363636364rem);
    width: calc(33.3333333333% - 3.6363636364rem);
  }
  .dcolumns_lg_11 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 3.6363636364rem);
    width: -moz-calc(41.6666666667% - 3.6363636364rem);
    width: calc(41.6666666667% - 3.6363636364rem);
  }
  .dcolumns_lg_11 .dcol-lg-6 {
    width: -webkit-calc(50% - 3.6363636364rem);
    width: -moz-calc(50% - 3.6363636364rem);
    width: calc(50% - 3.6363636364rem);
  }
  .dcolumns_lg_11 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 3.6363636364rem);
    width: -moz-calc(58.3333333333% - 3.6363636364rem);
    width: calc(58.3333333333% - 3.6363636364rem);
  }
  .dcolumns_lg_11 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 3.6363636364rem);
    width: -moz-calc(66.6666666667% - 3.6363636364rem);
    width: calc(66.6666666667% - 3.6363636364rem);
  }
  .dcolumns_lg_11 .dcol-lg-9 {
    width: -webkit-calc(75% - 3.6363636364rem);
    width: -moz-calc(75% - 3.6363636364rem);
    width: calc(75% - 3.6363636364rem);
  }
  .dcolumns_lg_11 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 3.6363636364rem);
    width: -moz-calc(83.3333333333% - 3.6363636364rem);
    width: calc(83.3333333333% - 3.6363636364rem);
  }
  .dcolumns_lg_11 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 3.6363636364rem);
    width: -moz-calc(91.6666666667% - 3.6363636364rem);
    width: calc(91.6666666667% - 3.6363636364rem);
  }
  .dcolumns_lg_11 .dcol-lg-12 {
    width: -webkit-calc(100% - 3.6363636364rem);
    width: -moz-calc(100% - 3.6363636364rem);
    width: calc(100% - 3.6363636364rem);
  }
  .dcolumns_lg_12 .dcol {
    width: -webkit-calc((100% - 44rem)/12);
    width: -moz-calc((100% - 44rem)/12);
    width: calc((100% - 44rem)/12);
  }
  .dcolumns_lg_12 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 3.6666666667rem);
    width: -moz-calc(8.3333333333% - 3.6666666667rem);
    width: calc(8.3333333333% - 3.6666666667rem);
  }
  .dcolumns_lg_12 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 3.6666666667rem);
    width: -moz-calc(16.6666666667% - 3.6666666667rem);
    width: calc(16.6666666667% - 3.6666666667rem);
  }
  .dcolumns_lg_12 .dcol-lg-3 {
    width: -webkit-calc(25% - 3.6666666667rem);
    width: -moz-calc(25% - 3.6666666667rem);
    width: calc(25% - 3.6666666667rem);
  }
  .dcolumns_lg_12 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 3.6666666667rem);
    width: -moz-calc(33.3333333333% - 3.6666666667rem);
    width: calc(33.3333333333% - 3.6666666667rem);
  }
  .dcolumns_lg_12 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 3.6666666667rem);
    width: -moz-calc(41.6666666667% - 3.6666666667rem);
    width: calc(41.6666666667% - 3.6666666667rem);
  }
  .dcolumns_lg_12 .dcol-lg-6 {
    width: -webkit-calc(50% - 3.6666666667rem);
    width: -moz-calc(50% - 3.6666666667rem);
    width: calc(50% - 3.6666666667rem);
  }
  .dcolumns_lg_12 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 3.6666666667rem);
    width: -moz-calc(58.3333333333% - 3.6666666667rem);
    width: calc(58.3333333333% - 3.6666666667rem);
  }
  .dcolumns_lg_12 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 3.6666666667rem);
    width: -moz-calc(66.6666666667% - 3.6666666667rem);
    width: calc(66.6666666667% - 3.6666666667rem);
  }
  .dcolumns_lg_12 .dcol-lg-9 {
    width: -webkit-calc(75% - 3.6666666667rem);
    width: -moz-calc(75% - 3.6666666667rem);
    width: calc(75% - 3.6666666667rem);
  }
  .dcolumns_lg_12 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 3.6666666667rem);
    width: -moz-calc(83.3333333333% - 3.6666666667rem);
    width: calc(83.3333333333% - 3.6666666667rem);
  }
  .dcolumns_lg_12 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 3.6666666667rem);
    width: -moz-calc(91.6666666667% - 3.6666666667rem);
    width: calc(91.6666666667% - 3.6666666667rem);
  }
  .dcolumns_lg_12 .dcol-lg-12 {
    width: -webkit-calc(100% - 3.6666666667rem);
    width: -moz-calc(100% - 3.6666666667rem);
    width: calc(100% - 3.6666666667rem);
  }
}
@media only screen and (min-width: 1200px) {
  .dcolumns_xl_12 .ds_col:nth-child(12n), .dcolumns_xl_12 .dcol:nth-child(12n), .dcolumns_xl_11 .ds_col:nth-child(11n), .dcolumns_xl_11 .dcol:nth-child(11n), .dcolumns_xl_10 .ds_col:nth-child(10n), .dcolumns_xl_10 .dcol:nth-child(10n), .dcolumns_xl_9 .ds_col:nth-child(9n), .dcolumns_xl_9 .dcol:nth-child(9n), .dcolumns_xl_8 .ds_col:nth-child(8n), .dcolumns_xl_8 .dcol:nth-child(8n), .dcolumns_xl_7 .ds_col:nth-child(7n), .dcolumns_xl_7 .dcol:nth-child(7n), .dcolumns_xl_6 .ds_col:nth-child(6n), .dcolumns_xl_6 .dcol:nth-child(6n), .dcolumns_xl_5 .ds_col:nth-child(5n), .dcolumns_xl_5 .dcol:nth-child(5n), .dcolumns_xl_4 .ds_col:nth-child(4n), .dcolumns_xl_4 .dcol:nth-child(4n), .dcolumns_xl_3 .ds_col:nth-child(3n), .dcolumns_xl_3 .dcol:nth-child(3n), .dcolumns_xl_2 .ds_col:nth-child(2n), .dcolumns_xl_2 .dcol:nth-child(2n), .dcolumns_xl_1 .ds_col:nth-child(1n), .dcolumns_xl_1 .dcol:nth-child(1n) {
    margin-right: 0 !important;
  }

  .dcolumns_xl_1 .dcol {
    width: -webkit-calc((100% - 0rem)/1);
    width: -moz-calc((100% - 0rem)/1);
    width: calc((100% - 0rem)/1);
  }
  .dcolumns_xl_1 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 0rem);
    width: -moz-calc(8.3333333333% - 0rem);
    width: calc(8.3333333333% - 0rem);
  }
  .dcolumns_xl_1 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 0rem);
    width: -moz-calc(16.6666666667% - 0rem);
    width: calc(16.6666666667% - 0rem);
  }
  .dcolumns_xl_1 .dcol-lg-3 {
    width: -webkit-calc(25% - 0rem);
    width: -moz-calc(25% - 0rem);
    width: calc(25% - 0rem);
  }
  .dcolumns_xl_1 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 0rem);
    width: -moz-calc(33.3333333333% - 0rem);
    width: calc(33.3333333333% - 0rem);
  }
  .dcolumns_xl_1 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 0rem);
    width: -moz-calc(41.6666666667% - 0rem);
    width: calc(41.6666666667% - 0rem);
  }
  .dcolumns_xl_1 .dcol-lg-6 {
    width: -webkit-calc(50% - 0rem);
    width: -moz-calc(50% - 0rem);
    width: calc(50% - 0rem);
  }
  .dcolumns_xl_1 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 0rem);
    width: -moz-calc(58.3333333333% - 0rem);
    width: calc(58.3333333333% - 0rem);
  }
  .dcolumns_xl_1 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 0rem);
    width: -moz-calc(66.6666666667% - 0rem);
    width: calc(66.6666666667% - 0rem);
  }
  .dcolumns_xl_1 .dcol-lg-9 {
    width: -webkit-calc(75% - 0rem);
    width: -moz-calc(75% - 0rem);
    width: calc(75% - 0rem);
  }
  .dcolumns_xl_1 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 0rem);
    width: -moz-calc(83.3333333333% - 0rem);
    width: calc(83.3333333333% - 0rem);
  }
  .dcolumns_xl_1 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 0rem);
    width: -moz-calc(91.6666666667% - 0rem);
    width: calc(91.6666666667% - 0rem);
  }
  .dcolumns_xl_1 .dcol-lg-12 {
    width: -webkit-calc(100% - 0rem);
    width: -moz-calc(100% - 0rem);
    width: calc(100% - 0rem);
  }
  .dcolumns_xl_2 .dcol {
    width: -webkit-calc((100% - 4rem)/2);
    width: -moz-calc((100% - 4rem)/2);
    width: calc((100% - 4rem)/2);
  }
  .dcolumns_xl_2 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 2rem);
    width: -moz-calc(8.3333333333% - 2rem);
    width: calc(8.3333333333% - 2rem);
  }
  .dcolumns_xl_2 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 2rem);
    width: -moz-calc(16.6666666667% - 2rem);
    width: calc(16.6666666667% - 2rem);
  }
  .dcolumns_xl_2 .dcol-lg-3 {
    width: -webkit-calc(25% - 2rem);
    width: -moz-calc(25% - 2rem);
    width: calc(25% - 2rem);
  }
  .dcolumns_xl_2 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 2rem);
    width: -moz-calc(33.3333333333% - 2rem);
    width: calc(33.3333333333% - 2rem);
  }
  .dcolumns_xl_2 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 2rem);
    width: -moz-calc(41.6666666667% - 2rem);
    width: calc(41.6666666667% - 2rem);
  }
  .dcolumns_xl_2 .dcol-lg-6 {
    width: -webkit-calc(50% - 2rem);
    width: -moz-calc(50% - 2rem);
    width: calc(50% - 2rem);
  }
  .dcolumns_xl_2 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 2rem);
    width: -moz-calc(58.3333333333% - 2rem);
    width: calc(58.3333333333% - 2rem);
  }
  .dcolumns_xl_2 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 2rem);
    width: -moz-calc(66.6666666667% - 2rem);
    width: calc(66.6666666667% - 2rem);
  }
  .dcolumns_xl_2 .dcol-lg-9 {
    width: -webkit-calc(75% - 2rem);
    width: -moz-calc(75% - 2rem);
    width: calc(75% - 2rem);
  }
  .dcolumns_xl_2 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 2rem);
    width: -moz-calc(83.3333333333% - 2rem);
    width: calc(83.3333333333% - 2rem);
  }
  .dcolumns_xl_2 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 2rem);
    width: -moz-calc(91.6666666667% - 2rem);
    width: calc(91.6666666667% - 2rem);
  }
  .dcolumns_xl_2 .dcol-lg-12 {
    width: -webkit-calc(100% - 2rem);
    width: -moz-calc(100% - 2rem);
    width: calc(100% - 2rem);
  }
  .dcolumns_xl_3 .dcol {
    width: -webkit-calc((100% - 8rem)/3);
    width: -moz-calc((100% - 8rem)/3);
    width: calc((100% - 8rem)/3);
  }
  .dcolumns_xl_3 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 2.6666666667rem);
    width: -moz-calc(8.3333333333% - 2.6666666667rem);
    width: calc(8.3333333333% - 2.6666666667rem);
  }
  .dcolumns_xl_3 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 2.6666666667rem);
    width: -moz-calc(16.6666666667% - 2.6666666667rem);
    width: calc(16.6666666667% - 2.6666666667rem);
  }
  .dcolumns_xl_3 .dcol-lg-3 {
    width: -webkit-calc(25% - 2.6666666667rem);
    width: -moz-calc(25% - 2.6666666667rem);
    width: calc(25% - 2.6666666667rem);
  }
  .dcolumns_xl_3 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 2.6666666667rem);
    width: -moz-calc(33.3333333333% - 2.6666666667rem);
    width: calc(33.3333333333% - 2.6666666667rem);
  }
  .dcolumns_xl_3 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 2.6666666667rem);
    width: -moz-calc(41.6666666667% - 2.6666666667rem);
    width: calc(41.6666666667% - 2.6666666667rem);
  }
  .dcolumns_xl_3 .dcol-lg-6 {
    width: -webkit-calc(50% - 2.6666666667rem);
    width: -moz-calc(50% - 2.6666666667rem);
    width: calc(50% - 2.6666666667rem);
  }
  .dcolumns_xl_3 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 2.6666666667rem);
    width: -moz-calc(58.3333333333% - 2.6666666667rem);
    width: calc(58.3333333333% - 2.6666666667rem);
  }
  .dcolumns_xl_3 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 2.6666666667rem);
    width: -moz-calc(66.6666666667% - 2.6666666667rem);
    width: calc(66.6666666667% - 2.6666666667rem);
  }
  .dcolumns_xl_3 .dcol-lg-9 {
    width: -webkit-calc(75% - 2.6666666667rem);
    width: -moz-calc(75% - 2.6666666667rem);
    width: calc(75% - 2.6666666667rem);
  }
  .dcolumns_xl_3 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 2.6666666667rem);
    width: -moz-calc(83.3333333333% - 2.6666666667rem);
    width: calc(83.3333333333% - 2.6666666667rem);
  }
  .dcolumns_xl_3 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 2.6666666667rem);
    width: -moz-calc(91.6666666667% - 2.6666666667rem);
    width: calc(91.6666666667% - 2.6666666667rem);
  }
  .dcolumns_xl_3 .dcol-lg-12 {
    width: -webkit-calc(100% - 2.6666666667rem);
    width: -moz-calc(100% - 2.6666666667rem);
    width: calc(100% - 2.6666666667rem);
  }
  .dcolumns_xl_4 .dcol {
    width: -webkit-calc((100% - 12rem)/4);
    width: -moz-calc((100% - 12rem)/4);
    width: calc((100% - 12rem)/4);
  }
  .dcolumns_xl_4 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 3rem);
    width: -moz-calc(8.3333333333% - 3rem);
    width: calc(8.3333333333% - 3rem);
  }
  .dcolumns_xl_4 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 3rem);
    width: -moz-calc(16.6666666667% - 3rem);
    width: calc(16.6666666667% - 3rem);
  }
  .dcolumns_xl_4 .dcol-lg-3 {
    width: -webkit-calc(25% - 3rem);
    width: -moz-calc(25% - 3rem);
    width: calc(25% - 3rem);
  }
  .dcolumns_xl_4 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 3rem);
    width: -moz-calc(33.3333333333% - 3rem);
    width: calc(33.3333333333% - 3rem);
  }
  .dcolumns_xl_4 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 3rem);
    width: -moz-calc(41.6666666667% - 3rem);
    width: calc(41.6666666667% - 3rem);
  }
  .dcolumns_xl_4 .dcol-lg-6 {
    width: -webkit-calc(50% - 3rem);
    width: -moz-calc(50% - 3rem);
    width: calc(50% - 3rem);
  }
  .dcolumns_xl_4 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 3rem);
    width: -moz-calc(58.3333333333% - 3rem);
    width: calc(58.3333333333% - 3rem);
  }
  .dcolumns_xl_4 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 3rem);
    width: -moz-calc(66.6666666667% - 3rem);
    width: calc(66.6666666667% - 3rem);
  }
  .dcolumns_xl_4 .dcol-lg-9 {
    width: -webkit-calc(75% - 3rem);
    width: -moz-calc(75% - 3rem);
    width: calc(75% - 3rem);
  }
  .dcolumns_xl_4 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 3rem);
    width: -moz-calc(83.3333333333% - 3rem);
    width: calc(83.3333333333% - 3rem);
  }
  .dcolumns_xl_4 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 3rem);
    width: -moz-calc(91.6666666667% - 3rem);
    width: calc(91.6666666667% - 3rem);
  }
  .dcolumns_xl_4 .dcol-lg-12 {
    width: -webkit-calc(100% - 3rem);
    width: -moz-calc(100% - 3rem);
    width: calc(100% - 3rem);
  }
  .dcolumns_xl_5 .dcol {
    width: -webkit-calc((100% - 16rem)/5);
    width: -moz-calc((100% - 16rem)/5);
    width: calc((100% - 16rem)/5);
  }
  .dcolumns_xl_5 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 3.2rem);
    width: -moz-calc(8.3333333333% - 3.2rem);
    width: calc(8.3333333333% - 3.2rem);
  }
  .dcolumns_xl_5 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 3.2rem);
    width: -moz-calc(16.6666666667% - 3.2rem);
    width: calc(16.6666666667% - 3.2rem);
  }
  .dcolumns_xl_5 .dcol-lg-3 {
    width: -webkit-calc(25% - 3.2rem);
    width: -moz-calc(25% - 3.2rem);
    width: calc(25% - 3.2rem);
  }
  .dcolumns_xl_5 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 3.2rem);
    width: -moz-calc(33.3333333333% - 3.2rem);
    width: calc(33.3333333333% - 3.2rem);
  }
  .dcolumns_xl_5 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 3.2rem);
    width: -moz-calc(41.6666666667% - 3.2rem);
    width: calc(41.6666666667% - 3.2rem);
  }
  .dcolumns_xl_5 .dcol-lg-6 {
    width: -webkit-calc(50% - 3.2rem);
    width: -moz-calc(50% - 3.2rem);
    width: calc(50% - 3.2rem);
  }
  .dcolumns_xl_5 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 3.2rem);
    width: -moz-calc(58.3333333333% - 3.2rem);
    width: calc(58.3333333333% - 3.2rem);
  }
  .dcolumns_xl_5 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 3.2rem);
    width: -moz-calc(66.6666666667% - 3.2rem);
    width: calc(66.6666666667% - 3.2rem);
  }
  .dcolumns_xl_5 .dcol-lg-9 {
    width: -webkit-calc(75% - 3.2rem);
    width: -moz-calc(75% - 3.2rem);
    width: calc(75% - 3.2rem);
  }
  .dcolumns_xl_5 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 3.2rem);
    width: -moz-calc(83.3333333333% - 3.2rem);
    width: calc(83.3333333333% - 3.2rem);
  }
  .dcolumns_xl_5 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 3.2rem);
    width: -moz-calc(91.6666666667% - 3.2rem);
    width: calc(91.6666666667% - 3.2rem);
  }
  .dcolumns_xl_5 .dcol-lg-12 {
    width: -webkit-calc(100% - 3.2rem);
    width: -moz-calc(100% - 3.2rem);
    width: calc(100% - 3.2rem);
  }
  .dcolumns_xl_6 .dcol {
    width: -webkit-calc((100% - 20rem)/6);
    width: -moz-calc((100% - 20rem)/6);
    width: calc((100% - 20rem)/6);
  }
  .dcolumns_xl_6 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 3.3333333333rem);
    width: -moz-calc(8.3333333333% - 3.3333333333rem);
    width: calc(8.3333333333% - 3.3333333333rem);
  }
  .dcolumns_xl_6 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 3.3333333333rem);
    width: -moz-calc(16.6666666667% - 3.3333333333rem);
    width: calc(16.6666666667% - 3.3333333333rem);
  }
  .dcolumns_xl_6 .dcol-lg-3 {
    width: -webkit-calc(25% - 3.3333333333rem);
    width: -moz-calc(25% - 3.3333333333rem);
    width: calc(25% - 3.3333333333rem);
  }
  .dcolumns_xl_6 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 3.3333333333rem);
    width: -moz-calc(33.3333333333% - 3.3333333333rem);
    width: calc(33.3333333333% - 3.3333333333rem);
  }
  .dcolumns_xl_6 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 3.3333333333rem);
    width: -moz-calc(41.6666666667% - 3.3333333333rem);
    width: calc(41.6666666667% - 3.3333333333rem);
  }
  .dcolumns_xl_6 .dcol-lg-6 {
    width: -webkit-calc(50% - 3.3333333333rem);
    width: -moz-calc(50% - 3.3333333333rem);
    width: calc(50% - 3.3333333333rem);
  }
  .dcolumns_xl_6 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 3.3333333333rem);
    width: -moz-calc(58.3333333333% - 3.3333333333rem);
    width: calc(58.3333333333% - 3.3333333333rem);
  }
  .dcolumns_xl_6 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 3.3333333333rem);
    width: -moz-calc(66.6666666667% - 3.3333333333rem);
    width: calc(66.6666666667% - 3.3333333333rem);
  }
  .dcolumns_xl_6 .dcol-lg-9 {
    width: -webkit-calc(75% - 3.3333333333rem);
    width: -moz-calc(75% - 3.3333333333rem);
    width: calc(75% - 3.3333333333rem);
  }
  .dcolumns_xl_6 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 3.3333333333rem);
    width: -moz-calc(83.3333333333% - 3.3333333333rem);
    width: calc(83.3333333333% - 3.3333333333rem);
  }
  .dcolumns_xl_6 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 3.3333333333rem);
    width: -moz-calc(91.6666666667% - 3.3333333333rem);
    width: calc(91.6666666667% - 3.3333333333rem);
  }
  .dcolumns_xl_6 .dcol-lg-12 {
    width: -webkit-calc(100% - 3.3333333333rem);
    width: -moz-calc(100% - 3.3333333333rem);
    width: calc(100% - 3.3333333333rem);
  }
  .dcolumns_xl_7 .dcol {
    width: -webkit-calc((100% - 24rem)/7);
    width: -moz-calc((100% - 24rem)/7);
    width: calc((100% - 24rem)/7);
  }
  .dcolumns_xl_7 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 3.4285714286rem);
    width: -moz-calc(8.3333333333% - 3.4285714286rem);
    width: calc(8.3333333333% - 3.4285714286rem);
  }
  .dcolumns_xl_7 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 3.4285714286rem);
    width: -moz-calc(16.6666666667% - 3.4285714286rem);
    width: calc(16.6666666667% - 3.4285714286rem);
  }
  .dcolumns_xl_7 .dcol-lg-3 {
    width: -webkit-calc(25% - 3.4285714286rem);
    width: -moz-calc(25% - 3.4285714286rem);
    width: calc(25% - 3.4285714286rem);
  }
  .dcolumns_xl_7 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 3.4285714286rem);
    width: -moz-calc(33.3333333333% - 3.4285714286rem);
    width: calc(33.3333333333% - 3.4285714286rem);
  }
  .dcolumns_xl_7 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 3.4285714286rem);
    width: -moz-calc(41.6666666667% - 3.4285714286rem);
    width: calc(41.6666666667% - 3.4285714286rem);
  }
  .dcolumns_xl_7 .dcol-lg-6 {
    width: -webkit-calc(50% - 3.4285714286rem);
    width: -moz-calc(50% - 3.4285714286rem);
    width: calc(50% - 3.4285714286rem);
  }
  .dcolumns_xl_7 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 3.4285714286rem);
    width: -moz-calc(58.3333333333% - 3.4285714286rem);
    width: calc(58.3333333333% - 3.4285714286rem);
  }
  .dcolumns_xl_7 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 3.4285714286rem);
    width: -moz-calc(66.6666666667% - 3.4285714286rem);
    width: calc(66.6666666667% - 3.4285714286rem);
  }
  .dcolumns_xl_7 .dcol-lg-9 {
    width: -webkit-calc(75% - 3.4285714286rem);
    width: -moz-calc(75% - 3.4285714286rem);
    width: calc(75% - 3.4285714286rem);
  }
  .dcolumns_xl_7 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 3.4285714286rem);
    width: -moz-calc(83.3333333333% - 3.4285714286rem);
    width: calc(83.3333333333% - 3.4285714286rem);
  }
  .dcolumns_xl_7 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 3.4285714286rem);
    width: -moz-calc(91.6666666667% - 3.4285714286rem);
    width: calc(91.6666666667% - 3.4285714286rem);
  }
  .dcolumns_xl_7 .dcol-lg-12 {
    width: -webkit-calc(100% - 3.4285714286rem);
    width: -moz-calc(100% - 3.4285714286rem);
    width: calc(100% - 3.4285714286rem);
  }
  .dcolumns_xl_8 .dcol {
    width: -webkit-calc((100% - 28rem)/8);
    width: -moz-calc((100% - 28rem)/8);
    width: calc((100% - 28rem)/8);
  }
  .dcolumns_xl_8 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 3.5rem);
    width: -moz-calc(8.3333333333% - 3.5rem);
    width: calc(8.3333333333% - 3.5rem);
  }
  .dcolumns_xl_8 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 3.5rem);
    width: -moz-calc(16.6666666667% - 3.5rem);
    width: calc(16.6666666667% - 3.5rem);
  }
  .dcolumns_xl_8 .dcol-lg-3 {
    width: -webkit-calc(25% - 3.5rem);
    width: -moz-calc(25% - 3.5rem);
    width: calc(25% - 3.5rem);
  }
  .dcolumns_xl_8 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 3.5rem);
    width: -moz-calc(33.3333333333% - 3.5rem);
    width: calc(33.3333333333% - 3.5rem);
  }
  .dcolumns_xl_8 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 3.5rem);
    width: -moz-calc(41.6666666667% - 3.5rem);
    width: calc(41.6666666667% - 3.5rem);
  }
  .dcolumns_xl_8 .dcol-lg-6 {
    width: -webkit-calc(50% - 3.5rem);
    width: -moz-calc(50% - 3.5rem);
    width: calc(50% - 3.5rem);
  }
  .dcolumns_xl_8 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 3.5rem);
    width: -moz-calc(58.3333333333% - 3.5rem);
    width: calc(58.3333333333% - 3.5rem);
  }
  .dcolumns_xl_8 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 3.5rem);
    width: -moz-calc(66.6666666667% - 3.5rem);
    width: calc(66.6666666667% - 3.5rem);
  }
  .dcolumns_xl_8 .dcol-lg-9 {
    width: -webkit-calc(75% - 3.5rem);
    width: -moz-calc(75% - 3.5rem);
    width: calc(75% - 3.5rem);
  }
  .dcolumns_xl_8 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 3.5rem);
    width: -moz-calc(83.3333333333% - 3.5rem);
    width: calc(83.3333333333% - 3.5rem);
  }
  .dcolumns_xl_8 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 3.5rem);
    width: -moz-calc(91.6666666667% - 3.5rem);
    width: calc(91.6666666667% - 3.5rem);
  }
  .dcolumns_xl_8 .dcol-lg-12 {
    width: -webkit-calc(100% - 3.5rem);
    width: -moz-calc(100% - 3.5rem);
    width: calc(100% - 3.5rem);
  }
  .dcolumns_xl_9 .dcol {
    width: -webkit-calc((100% - 32rem)/9);
    width: -moz-calc((100% - 32rem)/9);
    width: calc((100% - 32rem)/9);
  }
  .dcolumns_xl_9 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 3.5555555556rem);
    width: -moz-calc(8.3333333333% - 3.5555555556rem);
    width: calc(8.3333333333% - 3.5555555556rem);
  }
  .dcolumns_xl_9 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 3.5555555556rem);
    width: -moz-calc(16.6666666667% - 3.5555555556rem);
    width: calc(16.6666666667% - 3.5555555556rem);
  }
  .dcolumns_xl_9 .dcol-lg-3 {
    width: -webkit-calc(25% - 3.5555555556rem);
    width: -moz-calc(25% - 3.5555555556rem);
    width: calc(25% - 3.5555555556rem);
  }
  .dcolumns_xl_9 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 3.5555555556rem);
    width: -moz-calc(33.3333333333% - 3.5555555556rem);
    width: calc(33.3333333333% - 3.5555555556rem);
  }
  .dcolumns_xl_9 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 3.5555555556rem);
    width: -moz-calc(41.6666666667% - 3.5555555556rem);
    width: calc(41.6666666667% - 3.5555555556rem);
  }
  .dcolumns_xl_9 .dcol-lg-6 {
    width: -webkit-calc(50% - 3.5555555556rem);
    width: -moz-calc(50% - 3.5555555556rem);
    width: calc(50% - 3.5555555556rem);
  }
  .dcolumns_xl_9 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 3.5555555556rem);
    width: -moz-calc(58.3333333333% - 3.5555555556rem);
    width: calc(58.3333333333% - 3.5555555556rem);
  }
  .dcolumns_xl_9 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 3.5555555556rem);
    width: -moz-calc(66.6666666667% - 3.5555555556rem);
    width: calc(66.6666666667% - 3.5555555556rem);
  }
  .dcolumns_xl_9 .dcol-lg-9 {
    width: -webkit-calc(75% - 3.5555555556rem);
    width: -moz-calc(75% - 3.5555555556rem);
    width: calc(75% - 3.5555555556rem);
  }
  .dcolumns_xl_9 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 3.5555555556rem);
    width: -moz-calc(83.3333333333% - 3.5555555556rem);
    width: calc(83.3333333333% - 3.5555555556rem);
  }
  .dcolumns_xl_9 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 3.5555555556rem);
    width: -moz-calc(91.6666666667% - 3.5555555556rem);
    width: calc(91.6666666667% - 3.5555555556rem);
  }
  .dcolumns_xl_9 .dcol-lg-12 {
    width: -webkit-calc(100% - 3.5555555556rem);
    width: -moz-calc(100% - 3.5555555556rem);
    width: calc(100% - 3.5555555556rem);
  }
  .dcolumns_xl_10 .dcol {
    width: -webkit-calc((100% - 36rem)/10);
    width: -moz-calc((100% - 36rem)/10);
    width: calc((100% - 36rem)/10);
  }
  .dcolumns_xl_10 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 3.6rem);
    width: -moz-calc(8.3333333333% - 3.6rem);
    width: calc(8.3333333333% - 3.6rem);
  }
  .dcolumns_xl_10 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 3.6rem);
    width: -moz-calc(16.6666666667% - 3.6rem);
    width: calc(16.6666666667% - 3.6rem);
  }
  .dcolumns_xl_10 .dcol-lg-3 {
    width: -webkit-calc(25% - 3.6rem);
    width: -moz-calc(25% - 3.6rem);
    width: calc(25% - 3.6rem);
  }
  .dcolumns_xl_10 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 3.6rem);
    width: -moz-calc(33.3333333333% - 3.6rem);
    width: calc(33.3333333333% - 3.6rem);
  }
  .dcolumns_xl_10 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 3.6rem);
    width: -moz-calc(41.6666666667% - 3.6rem);
    width: calc(41.6666666667% - 3.6rem);
  }
  .dcolumns_xl_10 .dcol-lg-6 {
    width: -webkit-calc(50% - 3.6rem);
    width: -moz-calc(50% - 3.6rem);
    width: calc(50% - 3.6rem);
  }
  .dcolumns_xl_10 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 3.6rem);
    width: -moz-calc(58.3333333333% - 3.6rem);
    width: calc(58.3333333333% - 3.6rem);
  }
  .dcolumns_xl_10 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 3.6rem);
    width: -moz-calc(66.6666666667% - 3.6rem);
    width: calc(66.6666666667% - 3.6rem);
  }
  .dcolumns_xl_10 .dcol-lg-9 {
    width: -webkit-calc(75% - 3.6rem);
    width: -moz-calc(75% - 3.6rem);
    width: calc(75% - 3.6rem);
  }
  .dcolumns_xl_10 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 3.6rem);
    width: -moz-calc(83.3333333333% - 3.6rem);
    width: calc(83.3333333333% - 3.6rem);
  }
  .dcolumns_xl_10 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 3.6rem);
    width: -moz-calc(91.6666666667% - 3.6rem);
    width: calc(91.6666666667% - 3.6rem);
  }
  .dcolumns_xl_10 .dcol-lg-12 {
    width: -webkit-calc(100% - 3.6rem);
    width: -moz-calc(100% - 3.6rem);
    width: calc(100% - 3.6rem);
  }
  .dcolumns_xl_11 .dcol {
    width: -webkit-calc((100% - 40rem)/11);
    width: -moz-calc((100% - 40rem)/11);
    width: calc((100% - 40rem)/11);
  }
  .dcolumns_xl_11 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 3.6363636364rem);
    width: -moz-calc(8.3333333333% - 3.6363636364rem);
    width: calc(8.3333333333% - 3.6363636364rem);
  }
  .dcolumns_xl_11 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 3.6363636364rem);
    width: -moz-calc(16.6666666667% - 3.6363636364rem);
    width: calc(16.6666666667% - 3.6363636364rem);
  }
  .dcolumns_xl_11 .dcol-lg-3 {
    width: -webkit-calc(25% - 3.6363636364rem);
    width: -moz-calc(25% - 3.6363636364rem);
    width: calc(25% - 3.6363636364rem);
  }
  .dcolumns_xl_11 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 3.6363636364rem);
    width: -moz-calc(33.3333333333% - 3.6363636364rem);
    width: calc(33.3333333333% - 3.6363636364rem);
  }
  .dcolumns_xl_11 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 3.6363636364rem);
    width: -moz-calc(41.6666666667% - 3.6363636364rem);
    width: calc(41.6666666667% - 3.6363636364rem);
  }
  .dcolumns_xl_11 .dcol-lg-6 {
    width: -webkit-calc(50% - 3.6363636364rem);
    width: -moz-calc(50% - 3.6363636364rem);
    width: calc(50% - 3.6363636364rem);
  }
  .dcolumns_xl_11 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 3.6363636364rem);
    width: -moz-calc(58.3333333333% - 3.6363636364rem);
    width: calc(58.3333333333% - 3.6363636364rem);
  }
  .dcolumns_xl_11 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 3.6363636364rem);
    width: -moz-calc(66.6666666667% - 3.6363636364rem);
    width: calc(66.6666666667% - 3.6363636364rem);
  }
  .dcolumns_xl_11 .dcol-lg-9 {
    width: -webkit-calc(75% - 3.6363636364rem);
    width: -moz-calc(75% - 3.6363636364rem);
    width: calc(75% - 3.6363636364rem);
  }
  .dcolumns_xl_11 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 3.6363636364rem);
    width: -moz-calc(83.3333333333% - 3.6363636364rem);
    width: calc(83.3333333333% - 3.6363636364rem);
  }
  .dcolumns_xl_11 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 3.6363636364rem);
    width: -moz-calc(91.6666666667% - 3.6363636364rem);
    width: calc(91.6666666667% - 3.6363636364rem);
  }
  .dcolumns_xl_11 .dcol-lg-12 {
    width: -webkit-calc(100% - 3.6363636364rem);
    width: -moz-calc(100% - 3.6363636364rem);
    width: calc(100% - 3.6363636364rem);
  }
  .dcolumns_xl_12 .dcol {
    width: -webkit-calc((100% - 44rem)/12);
    width: -moz-calc((100% - 44rem)/12);
    width: calc((100% - 44rem)/12);
  }
  .dcolumns_xl_12 .dcol-lg-1 {
    width: -webkit-calc(8.3333333333% - 3.6666666667rem);
    width: -moz-calc(8.3333333333% - 3.6666666667rem);
    width: calc(8.3333333333% - 3.6666666667rem);
  }
  .dcolumns_xl_12 .dcol-lg-2 {
    width: -webkit-calc(16.6666666667% - 3.6666666667rem);
    width: -moz-calc(16.6666666667% - 3.6666666667rem);
    width: calc(16.6666666667% - 3.6666666667rem);
  }
  .dcolumns_xl_12 .dcol-lg-3 {
    width: -webkit-calc(25% - 3.6666666667rem);
    width: -moz-calc(25% - 3.6666666667rem);
    width: calc(25% - 3.6666666667rem);
  }
  .dcolumns_xl_12 .dcol-lg-4 {
    width: -webkit-calc(33.3333333333% - 3.6666666667rem);
    width: -moz-calc(33.3333333333% - 3.6666666667rem);
    width: calc(33.3333333333% - 3.6666666667rem);
  }
  .dcolumns_xl_12 .dcol-lg-5 {
    width: -webkit-calc(41.6666666667% - 3.6666666667rem);
    width: -moz-calc(41.6666666667% - 3.6666666667rem);
    width: calc(41.6666666667% - 3.6666666667rem);
  }
  .dcolumns_xl_12 .dcol-lg-6 {
    width: -webkit-calc(50% - 3.6666666667rem);
    width: -moz-calc(50% - 3.6666666667rem);
    width: calc(50% - 3.6666666667rem);
  }
  .dcolumns_xl_12 .dcol-lg-7 {
    width: -webkit-calc(58.3333333333% - 3.6666666667rem);
    width: -moz-calc(58.3333333333% - 3.6666666667rem);
    width: calc(58.3333333333% - 3.6666666667rem);
  }
  .dcolumns_xl_12 .dcol-lg-8 {
    width: -webkit-calc(66.6666666667% - 3.6666666667rem);
    width: -moz-calc(66.6666666667% - 3.6666666667rem);
    width: calc(66.6666666667% - 3.6666666667rem);
  }
  .dcolumns_xl_12 .dcol-lg-9 {
    width: -webkit-calc(75% - 3.6666666667rem);
    width: -moz-calc(75% - 3.6666666667rem);
    width: calc(75% - 3.6666666667rem);
  }
  .dcolumns_xl_12 .dcol-lg-10 {
    width: -webkit-calc(83.3333333333% - 3.6666666667rem);
    width: -moz-calc(83.3333333333% - 3.6666666667rem);
    width: calc(83.3333333333% - 3.6666666667rem);
  }
  .dcolumns_xl_12 .dcol-lg-11 {
    width: -webkit-calc(91.6666666667% - 3.6666666667rem);
    width: -moz-calc(91.6666666667% - 3.6666666667rem);
    width: calc(91.6666666667% - 3.6666666667rem);
  }
  .dcolumns_xl_12 .dcol-lg-12 {
    width: -webkit-calc(100% - 3.6666666667rem);
    width: -moz-calc(100% - 3.6666666667rem);
    width: calc(100% - 3.6666666667rem);
  }
}
@media only screen and (min-width: 768px) {
  .dcolumns_md_12 .ds_col:nth-child(12n), .dcolumns_md_12 .dcol:nth-child(12n), .dcolumns_md_11 .ds_col:nth-child(11n), .dcolumns_md_11 .dcol:nth-child(11n), .dcolumns_md_10 .ds_col:nth-child(10n), .dcolumns_md_10 .dcol:nth-child(10n), .dcolumns_md_9 .ds_col:nth-child(9n), .dcolumns_md_9 .dcol:nth-child(9n), .dcolumns_md_8 .ds_col:nth-child(8n), .dcolumns_md_8 .dcol:nth-child(8n), .dcolumns_md_7 .ds_col:nth-child(7n), .dcolumns_md_7 .dcol:nth-child(7n), .dcolumns_md_6 .ds_col:nth-child(6n), .dcolumns_md_6 .dcol:nth-child(6n), .dcolumns_md_5 .ds_col:nth-child(5n), .dcolumns_md_5 .dcol:nth-child(5n), .dcolumns_md_4 .ds_col:nth-child(4n), .dcolumns_md_4 .dcol:nth-child(4n), .dcolumns_md_3 .ds_col:nth-child(3n), .dcolumns_md_3 .dcol:nth-child(3n), .dcolumns_md_2 .ds_col:nth-child(2n), .dcolumns_md_2 .dcol:nth-child(2n), .dcolumns_md_1 .ds_col:nth-child(1n), .dcolumns_md_1 .dcol:nth-child(1n) {
    margin-right: 0 !important;
  }

  .dcolumns_md_1 .dcol {
    width: -webkit-calc((100% - 0rem)/1);
    width: -moz-calc((100% - 0rem)/1);
    width: calc((100% - 0rem)/1);
  }
  .dcolumns_md_1 .dcol-md-1 {
    width: -webkit-calc(8.3333333333% - 0rem);
    width: -moz-calc(8.3333333333% - 0rem);
    width: calc(8.3333333333% - 0rem);
  }
  .dcolumns_md_1 .dcol-md-2 {
    width: -webkit-calc(16.6666666667% - 0rem);
    width: -moz-calc(16.6666666667% - 0rem);
    width: calc(16.6666666667% - 0rem);
  }
  .dcolumns_md_1 .dcol-md-3 {
    width: -webkit-calc(25% - 0rem);
    width: -moz-calc(25% - 0rem);
    width: calc(25% - 0rem);
  }
  .dcolumns_md_1 .dcol-md-4 {
    width: -webkit-calc(33.3333333333% - 0rem);
    width: -moz-calc(33.3333333333% - 0rem);
    width: calc(33.3333333333% - 0rem);
  }
  .dcolumns_md_1 .dcol-md-5 {
    width: -webkit-calc(41.6666666667% - 0rem);
    width: -moz-calc(41.6666666667% - 0rem);
    width: calc(41.6666666667% - 0rem);
  }
  .dcolumns_md_1 .dcol-md-6 {
    width: -webkit-calc(50% - 0rem);
    width: -moz-calc(50% - 0rem);
    width: calc(50% - 0rem);
  }
  .dcolumns_md_1 .dcol-md-7 {
    width: -webkit-calc(58.3333333333% - 0rem);
    width: -moz-calc(58.3333333333% - 0rem);
    width: calc(58.3333333333% - 0rem);
  }
  .dcolumns_md_1 .dcol-md-8 {
    width: -webkit-calc(66.6666666667% - 0rem);
    width: -moz-calc(66.6666666667% - 0rem);
    width: calc(66.6666666667% - 0rem);
  }
  .dcolumns_md_1 .dcol-md-9 {
    width: -webkit-calc(75% - 0rem);
    width: -moz-calc(75% - 0rem);
    width: calc(75% - 0rem);
  }
  .dcolumns_md_1 .dcol-md-10 {
    width: -webkit-calc(83.3333333333% - 0rem);
    width: -moz-calc(83.3333333333% - 0rem);
    width: calc(83.3333333333% - 0rem);
  }
  .dcolumns_md_1 .dcol-md-11 {
    width: -webkit-calc(91.6666666667% - 0rem);
    width: -moz-calc(91.6666666667% - 0rem);
    width: calc(91.6666666667% - 0rem);
  }
  .dcolumns_md_1 .dcol-md-12 {
    width: -webkit-calc(100% - 0rem);
    width: -moz-calc(100% - 0rem);
    width: calc(100% - 0rem);
  }
  .dcolumns_md_2 .dcol {
    width: -webkit-calc((100% - 3rem)/2);
    width: -moz-calc((100% - 3rem)/2);
    width: calc((100% - 3rem)/2);
  }
  .dcolumns_md_2 .dcol-md-1 {
    width: -webkit-calc(8.3333333333% - 1.5rem);
    width: -moz-calc(8.3333333333% - 1.5rem);
    width: calc(8.3333333333% - 1.5rem);
  }
  .dcolumns_md_2 .dcol-md-2 {
    width: -webkit-calc(16.6666666667% - 1.5rem);
    width: -moz-calc(16.6666666667% - 1.5rem);
    width: calc(16.6666666667% - 1.5rem);
  }
  .dcolumns_md_2 .dcol-md-3 {
    width: -webkit-calc(25% - 1.5rem);
    width: -moz-calc(25% - 1.5rem);
    width: calc(25% - 1.5rem);
  }
  .dcolumns_md_2 .dcol-md-4 {
    width: -webkit-calc(33.3333333333% - 1.5rem);
    width: -moz-calc(33.3333333333% - 1.5rem);
    width: calc(33.3333333333% - 1.5rem);
  }
  .dcolumns_md_2 .dcol-md-5 {
    width: -webkit-calc(41.6666666667% - 1.5rem);
    width: -moz-calc(41.6666666667% - 1.5rem);
    width: calc(41.6666666667% - 1.5rem);
  }
  .dcolumns_md_2 .dcol-md-6 {
    width: -webkit-calc(50% - 1.5rem);
    width: -moz-calc(50% - 1.5rem);
    width: calc(50% - 1.5rem);
  }
  .dcolumns_md_2 .dcol-md-7 {
    width: -webkit-calc(58.3333333333% - 1.5rem);
    width: -moz-calc(58.3333333333% - 1.5rem);
    width: calc(58.3333333333% - 1.5rem);
  }
  .dcolumns_md_2 .dcol-md-8 {
    width: -webkit-calc(66.6666666667% - 1.5rem);
    width: -moz-calc(66.6666666667% - 1.5rem);
    width: calc(66.6666666667% - 1.5rem);
  }
  .dcolumns_md_2 .dcol-md-9 {
    width: -webkit-calc(75% - 1.5rem);
    width: -moz-calc(75% - 1.5rem);
    width: calc(75% - 1.5rem);
  }
  .dcolumns_md_2 .dcol-md-10 {
    width: -webkit-calc(83.3333333333% - 1.5rem);
    width: -moz-calc(83.3333333333% - 1.5rem);
    width: calc(83.3333333333% - 1.5rem);
  }
  .dcolumns_md_2 .dcol-md-11 {
    width: -webkit-calc(91.6666666667% - 1.5rem);
    width: -moz-calc(91.6666666667% - 1.5rem);
    width: calc(91.6666666667% - 1.5rem);
  }
  .dcolumns_md_2 .dcol-md-12 {
    width: -webkit-calc(100% - 1.5rem);
    width: -moz-calc(100% - 1.5rem);
    width: calc(100% - 1.5rem);
  }
  .dcolumns_md_3 .dcol {
    width: -webkit-calc((100% - 6rem)/3);
    width: -moz-calc((100% - 6rem)/3);
    width: calc((100% - 6rem)/3);
  }
  .dcolumns_md_3 .dcol-md-1 {
    width: -webkit-calc(8.3333333333% - 2rem);
    width: -moz-calc(8.3333333333% - 2rem);
    width: calc(8.3333333333% - 2rem);
  }
  .dcolumns_md_3 .dcol-md-2 {
    width: -webkit-calc(16.6666666667% - 2rem);
    width: -moz-calc(16.6666666667% - 2rem);
    width: calc(16.6666666667% - 2rem);
  }
  .dcolumns_md_3 .dcol-md-3 {
    width: -webkit-calc(25% - 2rem);
    width: -moz-calc(25% - 2rem);
    width: calc(25% - 2rem);
  }
  .dcolumns_md_3 .dcol-md-4 {
    width: -webkit-calc(33.3333333333% - 2rem);
    width: -moz-calc(33.3333333333% - 2rem);
    width: calc(33.3333333333% - 2rem);
  }
  .dcolumns_md_3 .dcol-md-5 {
    width: -webkit-calc(41.6666666667% - 2rem);
    width: -moz-calc(41.6666666667% - 2rem);
    width: calc(41.6666666667% - 2rem);
  }
  .dcolumns_md_3 .dcol-md-6 {
    width: -webkit-calc(50% - 2rem);
    width: -moz-calc(50% - 2rem);
    width: calc(50% - 2rem);
  }
  .dcolumns_md_3 .dcol-md-7 {
    width: -webkit-calc(58.3333333333% - 2rem);
    width: -moz-calc(58.3333333333% - 2rem);
    width: calc(58.3333333333% - 2rem);
  }
  .dcolumns_md_3 .dcol-md-8 {
    width: -webkit-calc(66.6666666667% - 2rem);
    width: -moz-calc(66.6666666667% - 2rem);
    width: calc(66.6666666667% - 2rem);
  }
  .dcolumns_md_3 .dcol-md-9 {
    width: -webkit-calc(75% - 2rem);
    width: -moz-calc(75% - 2rem);
    width: calc(75% - 2rem);
  }
  .dcolumns_md_3 .dcol-md-10 {
    width: -webkit-calc(83.3333333333% - 2rem);
    width: -moz-calc(83.3333333333% - 2rem);
    width: calc(83.3333333333% - 2rem);
  }
  .dcolumns_md_3 .dcol-md-11 {
    width: -webkit-calc(91.6666666667% - 2rem);
    width: -moz-calc(91.6666666667% - 2rem);
    width: calc(91.6666666667% - 2rem);
  }
  .dcolumns_md_3 .dcol-md-12 {
    width: -webkit-calc(100% - 2rem);
    width: -moz-calc(100% - 2rem);
    width: calc(100% - 2rem);
  }
  .dcolumns_md_4 .dcol {
    width: -webkit-calc((100% - 9rem)/4);
    width: -moz-calc((100% - 9rem)/4);
    width: calc((100% - 9rem)/4);
  }
  .dcolumns_md_4 .dcol-md-1 {
    width: -webkit-calc(8.3333333333% - 2.25rem);
    width: -moz-calc(8.3333333333% - 2.25rem);
    width: calc(8.3333333333% - 2.25rem);
  }
  .dcolumns_md_4 .dcol-md-2 {
    width: -webkit-calc(16.6666666667% - 2.25rem);
    width: -moz-calc(16.6666666667% - 2.25rem);
    width: calc(16.6666666667% - 2.25rem);
  }
  .dcolumns_md_4 .dcol-md-3 {
    width: -webkit-calc(25% - 2.25rem);
    width: -moz-calc(25% - 2.25rem);
    width: calc(25% - 2.25rem);
  }
  .dcolumns_md_4 .dcol-md-4 {
    width: -webkit-calc(33.3333333333% - 2.25rem);
    width: -moz-calc(33.3333333333% - 2.25rem);
    width: calc(33.3333333333% - 2.25rem);
  }
  .dcolumns_md_4 .dcol-md-5 {
    width: -webkit-calc(41.6666666667% - 2.25rem);
    width: -moz-calc(41.6666666667% - 2.25rem);
    width: calc(41.6666666667% - 2.25rem);
  }
  .dcolumns_md_4 .dcol-md-6 {
    width: -webkit-calc(50% - 2.25rem);
    width: -moz-calc(50% - 2.25rem);
    width: calc(50% - 2.25rem);
  }
  .dcolumns_md_4 .dcol-md-7 {
    width: -webkit-calc(58.3333333333% - 2.25rem);
    width: -moz-calc(58.3333333333% - 2.25rem);
    width: calc(58.3333333333% - 2.25rem);
  }
  .dcolumns_md_4 .dcol-md-8 {
    width: -webkit-calc(66.6666666667% - 2.25rem);
    width: -moz-calc(66.6666666667% - 2.25rem);
    width: calc(66.6666666667% - 2.25rem);
  }
  .dcolumns_md_4 .dcol-md-9 {
    width: -webkit-calc(75% - 2.25rem);
    width: -moz-calc(75% - 2.25rem);
    width: calc(75% - 2.25rem);
  }
  .dcolumns_md_4 .dcol-md-10 {
    width: -webkit-calc(83.3333333333% - 2.25rem);
    width: -moz-calc(83.3333333333% - 2.25rem);
    width: calc(83.3333333333% - 2.25rem);
  }
  .dcolumns_md_4 .dcol-md-11 {
    width: -webkit-calc(91.6666666667% - 2.25rem);
    width: -moz-calc(91.6666666667% - 2.25rem);
    width: calc(91.6666666667% - 2.25rem);
  }
  .dcolumns_md_4 .dcol-md-12 {
    width: -webkit-calc(100% - 2.25rem);
    width: -moz-calc(100% - 2.25rem);
    width: calc(100% - 2.25rem);
  }
  .dcolumns_md_5 .dcol {
    width: -webkit-calc((100% - 12rem)/5);
    width: -moz-calc((100% - 12rem)/5);
    width: calc((100% - 12rem)/5);
  }
  .dcolumns_md_5 .dcol-md-1 {
    width: -webkit-calc(8.3333333333% - 2.4rem);
    width: -moz-calc(8.3333333333% - 2.4rem);
    width: calc(8.3333333333% - 2.4rem);
  }
  .dcolumns_md_5 .dcol-md-2 {
    width: -webkit-calc(16.6666666667% - 2.4rem);
    width: -moz-calc(16.6666666667% - 2.4rem);
    width: calc(16.6666666667% - 2.4rem);
  }
  .dcolumns_md_5 .dcol-md-3 {
    width: -webkit-calc(25% - 2.4rem);
    width: -moz-calc(25% - 2.4rem);
    width: calc(25% - 2.4rem);
  }
  .dcolumns_md_5 .dcol-md-4 {
    width: -webkit-calc(33.3333333333% - 2.4rem);
    width: -moz-calc(33.3333333333% - 2.4rem);
    width: calc(33.3333333333% - 2.4rem);
  }
  .dcolumns_md_5 .dcol-md-5 {
    width: -webkit-calc(41.6666666667% - 2.4rem);
    width: -moz-calc(41.6666666667% - 2.4rem);
    width: calc(41.6666666667% - 2.4rem);
  }
  .dcolumns_md_5 .dcol-md-6 {
    width: -webkit-calc(50% - 2.4rem);
    width: -moz-calc(50% - 2.4rem);
    width: calc(50% - 2.4rem);
  }
  .dcolumns_md_5 .dcol-md-7 {
    width: -webkit-calc(58.3333333333% - 2.4rem);
    width: -moz-calc(58.3333333333% - 2.4rem);
    width: calc(58.3333333333% - 2.4rem);
  }
  .dcolumns_md_5 .dcol-md-8 {
    width: -webkit-calc(66.6666666667% - 2.4rem);
    width: -moz-calc(66.6666666667% - 2.4rem);
    width: calc(66.6666666667% - 2.4rem);
  }
  .dcolumns_md_5 .dcol-md-9 {
    width: -webkit-calc(75% - 2.4rem);
    width: -moz-calc(75% - 2.4rem);
    width: calc(75% - 2.4rem);
  }
  .dcolumns_md_5 .dcol-md-10 {
    width: -webkit-calc(83.3333333333% - 2.4rem);
    width: -moz-calc(83.3333333333% - 2.4rem);
    width: calc(83.3333333333% - 2.4rem);
  }
  .dcolumns_md_5 .dcol-md-11 {
    width: -webkit-calc(91.6666666667% - 2.4rem);
    width: -moz-calc(91.6666666667% - 2.4rem);
    width: calc(91.6666666667% - 2.4rem);
  }
  .dcolumns_md_5 .dcol-md-12 {
    width: -webkit-calc(100% - 2.4rem);
    width: -moz-calc(100% - 2.4rem);
    width: calc(100% - 2.4rem);
  }
  .dcolumns_md_6 .dcol {
    width: -webkit-calc((100% - 15rem)/6);
    width: -moz-calc((100% - 15rem)/6);
    width: calc((100% - 15rem)/6);
  }
  .dcolumns_md_6 .dcol-md-1 {
    width: -webkit-calc(8.3333333333% - 2.5rem);
    width: -moz-calc(8.3333333333% - 2.5rem);
    width: calc(8.3333333333% - 2.5rem);
  }
  .dcolumns_md_6 .dcol-md-2 {
    width: -webkit-calc(16.6666666667% - 2.5rem);
    width: -moz-calc(16.6666666667% - 2.5rem);
    width: calc(16.6666666667% - 2.5rem);
  }
  .dcolumns_md_6 .dcol-md-3 {
    width: -webkit-calc(25% - 2.5rem);
    width: -moz-calc(25% - 2.5rem);
    width: calc(25% - 2.5rem);
  }
  .dcolumns_md_6 .dcol-md-4 {
    width: -webkit-calc(33.3333333333% - 2.5rem);
    width: -moz-calc(33.3333333333% - 2.5rem);
    width: calc(33.3333333333% - 2.5rem);
  }
  .dcolumns_md_6 .dcol-md-5 {
    width: -webkit-calc(41.6666666667% - 2.5rem);
    width: -moz-calc(41.6666666667% - 2.5rem);
    width: calc(41.6666666667% - 2.5rem);
  }
  .dcolumns_md_6 .dcol-md-6 {
    width: -webkit-calc(50% - 2.5rem);
    width: -moz-calc(50% - 2.5rem);
    width: calc(50% - 2.5rem);
  }
  .dcolumns_md_6 .dcol-md-7 {
    width: -webkit-calc(58.3333333333% - 2.5rem);
    width: -moz-calc(58.3333333333% - 2.5rem);
    width: calc(58.3333333333% - 2.5rem);
  }
  .dcolumns_md_6 .dcol-md-8 {
    width: -webkit-calc(66.6666666667% - 2.5rem);
    width: -moz-calc(66.6666666667% - 2.5rem);
    width: calc(66.6666666667% - 2.5rem);
  }
  .dcolumns_md_6 .dcol-md-9 {
    width: -webkit-calc(75% - 2.5rem);
    width: -moz-calc(75% - 2.5rem);
    width: calc(75% - 2.5rem);
  }
  .dcolumns_md_6 .dcol-md-10 {
    width: -webkit-calc(83.3333333333% - 2.5rem);
    width: -moz-calc(83.3333333333% - 2.5rem);
    width: calc(83.3333333333% - 2.5rem);
  }
  .dcolumns_md_6 .dcol-md-11 {
    width: -webkit-calc(91.6666666667% - 2.5rem);
    width: -moz-calc(91.6666666667% - 2.5rem);
    width: calc(91.6666666667% - 2.5rem);
  }
  .dcolumns_md_6 .dcol-md-12 {
    width: -webkit-calc(100% - 2.5rem);
    width: -moz-calc(100% - 2.5rem);
    width: calc(100% - 2.5rem);
  }
  .dcolumns_md_7 .dcol {
    width: -webkit-calc((100% - 18rem)/7);
    width: -moz-calc((100% - 18rem)/7);
    width: calc((100% - 18rem)/7);
  }
  .dcolumns_md_7 .dcol-md-1 {
    width: -webkit-calc(8.3333333333% - 2.5714285714rem);
    width: -moz-calc(8.3333333333% - 2.5714285714rem);
    width: calc(8.3333333333% - 2.5714285714rem);
  }
  .dcolumns_md_7 .dcol-md-2 {
    width: -webkit-calc(16.6666666667% - 2.5714285714rem);
    width: -moz-calc(16.6666666667% - 2.5714285714rem);
    width: calc(16.6666666667% - 2.5714285714rem);
  }
  .dcolumns_md_7 .dcol-md-3 {
    width: -webkit-calc(25% - 2.5714285714rem);
    width: -moz-calc(25% - 2.5714285714rem);
    width: calc(25% - 2.5714285714rem);
  }
  .dcolumns_md_7 .dcol-md-4 {
    width: -webkit-calc(33.3333333333% - 2.5714285714rem);
    width: -moz-calc(33.3333333333% - 2.5714285714rem);
    width: calc(33.3333333333% - 2.5714285714rem);
  }
  .dcolumns_md_7 .dcol-md-5 {
    width: -webkit-calc(41.6666666667% - 2.5714285714rem);
    width: -moz-calc(41.6666666667% - 2.5714285714rem);
    width: calc(41.6666666667% - 2.5714285714rem);
  }
  .dcolumns_md_7 .dcol-md-6 {
    width: -webkit-calc(50% - 2.5714285714rem);
    width: -moz-calc(50% - 2.5714285714rem);
    width: calc(50% - 2.5714285714rem);
  }
  .dcolumns_md_7 .dcol-md-7 {
    width: -webkit-calc(58.3333333333% - 2.5714285714rem);
    width: -moz-calc(58.3333333333% - 2.5714285714rem);
    width: calc(58.3333333333% - 2.5714285714rem);
  }
  .dcolumns_md_7 .dcol-md-8 {
    width: -webkit-calc(66.6666666667% - 2.5714285714rem);
    width: -moz-calc(66.6666666667% - 2.5714285714rem);
    width: calc(66.6666666667% - 2.5714285714rem);
  }
  .dcolumns_md_7 .dcol-md-9 {
    width: -webkit-calc(75% - 2.5714285714rem);
    width: -moz-calc(75% - 2.5714285714rem);
    width: calc(75% - 2.5714285714rem);
  }
  .dcolumns_md_7 .dcol-md-10 {
    width: -webkit-calc(83.3333333333% - 2.5714285714rem);
    width: -moz-calc(83.3333333333% - 2.5714285714rem);
    width: calc(83.3333333333% - 2.5714285714rem);
  }
  .dcolumns_md_7 .dcol-md-11 {
    width: -webkit-calc(91.6666666667% - 2.5714285714rem);
    width: -moz-calc(91.6666666667% - 2.5714285714rem);
    width: calc(91.6666666667% - 2.5714285714rem);
  }
  .dcolumns_md_7 .dcol-md-12 {
    width: -webkit-calc(100% - 2.5714285714rem);
    width: -moz-calc(100% - 2.5714285714rem);
    width: calc(100% - 2.5714285714rem);
  }
  .dcolumns_md_8 .dcol {
    width: -webkit-calc((100% - 21rem)/8);
    width: -moz-calc((100% - 21rem)/8);
    width: calc((100% - 21rem)/8);
  }
  .dcolumns_md_8 .dcol-md-1 {
    width: -webkit-calc(8.3333333333% - 2.625rem);
    width: -moz-calc(8.3333333333% - 2.625rem);
    width: calc(8.3333333333% - 2.625rem);
  }
  .dcolumns_md_8 .dcol-md-2 {
    width: -webkit-calc(16.6666666667% - 2.625rem);
    width: -moz-calc(16.6666666667% - 2.625rem);
    width: calc(16.6666666667% - 2.625rem);
  }
  .dcolumns_md_8 .dcol-md-3 {
    width: -webkit-calc(25% - 2.625rem);
    width: -moz-calc(25% - 2.625rem);
    width: calc(25% - 2.625rem);
  }
  .dcolumns_md_8 .dcol-md-4 {
    width: -webkit-calc(33.3333333333% - 2.625rem);
    width: -moz-calc(33.3333333333% - 2.625rem);
    width: calc(33.3333333333% - 2.625rem);
  }
  .dcolumns_md_8 .dcol-md-5 {
    width: -webkit-calc(41.6666666667% - 2.625rem);
    width: -moz-calc(41.6666666667% - 2.625rem);
    width: calc(41.6666666667% - 2.625rem);
  }
  .dcolumns_md_8 .dcol-md-6 {
    width: -webkit-calc(50% - 2.625rem);
    width: -moz-calc(50% - 2.625rem);
    width: calc(50% - 2.625rem);
  }
  .dcolumns_md_8 .dcol-md-7 {
    width: -webkit-calc(58.3333333333% - 2.625rem);
    width: -moz-calc(58.3333333333% - 2.625rem);
    width: calc(58.3333333333% - 2.625rem);
  }
  .dcolumns_md_8 .dcol-md-8 {
    width: -webkit-calc(66.6666666667% - 2.625rem);
    width: -moz-calc(66.6666666667% - 2.625rem);
    width: calc(66.6666666667% - 2.625rem);
  }
  .dcolumns_md_8 .dcol-md-9 {
    width: -webkit-calc(75% - 2.625rem);
    width: -moz-calc(75% - 2.625rem);
    width: calc(75% - 2.625rem);
  }
  .dcolumns_md_8 .dcol-md-10 {
    width: -webkit-calc(83.3333333333% - 2.625rem);
    width: -moz-calc(83.3333333333% - 2.625rem);
    width: calc(83.3333333333% - 2.625rem);
  }
  .dcolumns_md_8 .dcol-md-11 {
    width: -webkit-calc(91.6666666667% - 2.625rem);
    width: -moz-calc(91.6666666667% - 2.625rem);
    width: calc(91.6666666667% - 2.625rem);
  }
  .dcolumns_md_8 .dcol-md-12 {
    width: -webkit-calc(100% - 2.625rem);
    width: -moz-calc(100% - 2.625rem);
    width: calc(100% - 2.625rem);
  }
  .dcolumns_md_9 .dcol {
    width: -webkit-calc((100% - 24rem)/9);
    width: -moz-calc((100% - 24rem)/9);
    width: calc((100% - 24rem)/9);
  }
  .dcolumns_md_9 .dcol-md-1 {
    width: -webkit-calc(8.3333333333% - 2.6666666667rem);
    width: -moz-calc(8.3333333333% - 2.6666666667rem);
    width: calc(8.3333333333% - 2.6666666667rem);
  }
  .dcolumns_md_9 .dcol-md-2 {
    width: -webkit-calc(16.6666666667% - 2.6666666667rem);
    width: -moz-calc(16.6666666667% - 2.6666666667rem);
    width: calc(16.6666666667% - 2.6666666667rem);
  }
  .dcolumns_md_9 .dcol-md-3 {
    width: -webkit-calc(25% - 2.6666666667rem);
    width: -moz-calc(25% - 2.6666666667rem);
    width: calc(25% - 2.6666666667rem);
  }
  .dcolumns_md_9 .dcol-md-4 {
    width: -webkit-calc(33.3333333333% - 2.6666666667rem);
    width: -moz-calc(33.3333333333% - 2.6666666667rem);
    width: calc(33.3333333333% - 2.6666666667rem);
  }
  .dcolumns_md_9 .dcol-md-5 {
    width: -webkit-calc(41.6666666667% - 2.6666666667rem);
    width: -moz-calc(41.6666666667% - 2.6666666667rem);
    width: calc(41.6666666667% - 2.6666666667rem);
  }
  .dcolumns_md_9 .dcol-md-6 {
    width: -webkit-calc(50% - 2.6666666667rem);
    width: -moz-calc(50% - 2.6666666667rem);
    width: calc(50% - 2.6666666667rem);
  }
  .dcolumns_md_9 .dcol-md-7 {
    width: -webkit-calc(58.3333333333% - 2.6666666667rem);
    width: -moz-calc(58.3333333333% - 2.6666666667rem);
    width: calc(58.3333333333% - 2.6666666667rem);
  }
  .dcolumns_md_9 .dcol-md-8 {
    width: -webkit-calc(66.6666666667% - 2.6666666667rem);
    width: -moz-calc(66.6666666667% - 2.6666666667rem);
    width: calc(66.6666666667% - 2.6666666667rem);
  }
  .dcolumns_md_9 .dcol-md-9 {
    width: -webkit-calc(75% - 2.6666666667rem);
    width: -moz-calc(75% - 2.6666666667rem);
    width: calc(75% - 2.6666666667rem);
  }
  .dcolumns_md_9 .dcol-md-10 {
    width: -webkit-calc(83.3333333333% - 2.6666666667rem);
    width: -moz-calc(83.3333333333% - 2.6666666667rem);
    width: calc(83.3333333333% - 2.6666666667rem);
  }
  .dcolumns_md_9 .dcol-md-11 {
    width: -webkit-calc(91.6666666667% - 2.6666666667rem);
    width: -moz-calc(91.6666666667% - 2.6666666667rem);
    width: calc(91.6666666667% - 2.6666666667rem);
  }
  .dcolumns_md_9 .dcol-md-12 {
    width: -webkit-calc(100% - 2.6666666667rem);
    width: -moz-calc(100% - 2.6666666667rem);
    width: calc(100% - 2.6666666667rem);
  }
  .dcolumns_md_10 .dcol {
    width: -webkit-calc((100% - 27rem)/10);
    width: -moz-calc((100% - 27rem)/10);
    width: calc((100% - 27rem)/10);
  }
  .dcolumns_md_10 .dcol-md-1 {
    width: -webkit-calc(8.3333333333% - 2.7rem);
    width: -moz-calc(8.3333333333% - 2.7rem);
    width: calc(8.3333333333% - 2.7rem);
  }
  .dcolumns_md_10 .dcol-md-2 {
    width: -webkit-calc(16.6666666667% - 2.7rem);
    width: -moz-calc(16.6666666667% - 2.7rem);
    width: calc(16.6666666667% - 2.7rem);
  }
  .dcolumns_md_10 .dcol-md-3 {
    width: -webkit-calc(25% - 2.7rem);
    width: -moz-calc(25% - 2.7rem);
    width: calc(25% - 2.7rem);
  }
  .dcolumns_md_10 .dcol-md-4 {
    width: -webkit-calc(33.3333333333% - 2.7rem);
    width: -moz-calc(33.3333333333% - 2.7rem);
    width: calc(33.3333333333% - 2.7rem);
  }
  .dcolumns_md_10 .dcol-md-5 {
    width: -webkit-calc(41.6666666667% - 2.7rem);
    width: -moz-calc(41.6666666667% - 2.7rem);
    width: calc(41.6666666667% - 2.7rem);
  }
  .dcolumns_md_10 .dcol-md-6 {
    width: -webkit-calc(50% - 2.7rem);
    width: -moz-calc(50% - 2.7rem);
    width: calc(50% - 2.7rem);
  }
  .dcolumns_md_10 .dcol-md-7 {
    width: -webkit-calc(58.3333333333% - 2.7rem);
    width: -moz-calc(58.3333333333% - 2.7rem);
    width: calc(58.3333333333% - 2.7rem);
  }
  .dcolumns_md_10 .dcol-md-8 {
    width: -webkit-calc(66.6666666667% - 2.7rem);
    width: -moz-calc(66.6666666667% - 2.7rem);
    width: calc(66.6666666667% - 2.7rem);
  }
  .dcolumns_md_10 .dcol-md-9 {
    width: -webkit-calc(75% - 2.7rem);
    width: -moz-calc(75% - 2.7rem);
    width: calc(75% - 2.7rem);
  }
  .dcolumns_md_10 .dcol-md-10 {
    width: -webkit-calc(83.3333333333% - 2.7rem);
    width: -moz-calc(83.3333333333% - 2.7rem);
    width: calc(83.3333333333% - 2.7rem);
  }
  .dcolumns_md_10 .dcol-md-11 {
    width: -webkit-calc(91.6666666667% - 2.7rem);
    width: -moz-calc(91.6666666667% - 2.7rem);
    width: calc(91.6666666667% - 2.7rem);
  }
  .dcolumns_md_10 .dcol-md-12 {
    width: -webkit-calc(100% - 2.7rem);
    width: -moz-calc(100% - 2.7rem);
    width: calc(100% - 2.7rem);
  }
  .dcolumns_md_11 .dcol {
    width: -webkit-calc((100% - 30rem)/11);
    width: -moz-calc((100% - 30rem)/11);
    width: calc((100% - 30rem)/11);
  }
  .dcolumns_md_11 .dcol-md-1 {
    width: -webkit-calc(8.3333333333% - 2.7272727273rem);
    width: -moz-calc(8.3333333333% - 2.7272727273rem);
    width: calc(8.3333333333% - 2.7272727273rem);
  }
  .dcolumns_md_11 .dcol-md-2 {
    width: -webkit-calc(16.6666666667% - 2.7272727273rem);
    width: -moz-calc(16.6666666667% - 2.7272727273rem);
    width: calc(16.6666666667% - 2.7272727273rem);
  }
  .dcolumns_md_11 .dcol-md-3 {
    width: -webkit-calc(25% - 2.7272727273rem);
    width: -moz-calc(25% - 2.7272727273rem);
    width: calc(25% - 2.7272727273rem);
  }
  .dcolumns_md_11 .dcol-md-4 {
    width: -webkit-calc(33.3333333333% - 2.7272727273rem);
    width: -moz-calc(33.3333333333% - 2.7272727273rem);
    width: calc(33.3333333333% - 2.7272727273rem);
  }
  .dcolumns_md_11 .dcol-md-5 {
    width: -webkit-calc(41.6666666667% - 2.7272727273rem);
    width: -moz-calc(41.6666666667% - 2.7272727273rem);
    width: calc(41.6666666667% - 2.7272727273rem);
  }
  .dcolumns_md_11 .dcol-md-6 {
    width: -webkit-calc(50% - 2.7272727273rem);
    width: -moz-calc(50% - 2.7272727273rem);
    width: calc(50% - 2.7272727273rem);
  }
  .dcolumns_md_11 .dcol-md-7 {
    width: -webkit-calc(58.3333333333% - 2.7272727273rem);
    width: -moz-calc(58.3333333333% - 2.7272727273rem);
    width: calc(58.3333333333% - 2.7272727273rem);
  }
  .dcolumns_md_11 .dcol-md-8 {
    width: -webkit-calc(66.6666666667% - 2.7272727273rem);
    width: -moz-calc(66.6666666667% - 2.7272727273rem);
    width: calc(66.6666666667% - 2.7272727273rem);
  }
  .dcolumns_md_11 .dcol-md-9 {
    width: -webkit-calc(75% - 2.7272727273rem);
    width: -moz-calc(75% - 2.7272727273rem);
    width: calc(75% - 2.7272727273rem);
  }
  .dcolumns_md_11 .dcol-md-10 {
    width: -webkit-calc(83.3333333333% - 2.7272727273rem);
    width: -moz-calc(83.3333333333% - 2.7272727273rem);
    width: calc(83.3333333333% - 2.7272727273rem);
  }
  .dcolumns_md_11 .dcol-md-11 {
    width: -webkit-calc(91.6666666667% - 2.7272727273rem);
    width: -moz-calc(91.6666666667% - 2.7272727273rem);
    width: calc(91.6666666667% - 2.7272727273rem);
  }
  .dcolumns_md_11 .dcol-md-12 {
    width: -webkit-calc(100% - 2.7272727273rem);
    width: -moz-calc(100% - 2.7272727273rem);
    width: calc(100% - 2.7272727273rem);
  }
  .dcolumns_md_12 .dcol {
    width: -webkit-calc((100% - 33rem)/12);
    width: -moz-calc((100% - 33rem)/12);
    width: calc((100% - 33rem)/12);
  }
  .dcolumns_md_12 .dcol-md-1 {
    width: -webkit-calc(8.3333333333% - 2.75rem);
    width: -moz-calc(8.3333333333% - 2.75rem);
    width: calc(8.3333333333% - 2.75rem);
  }
  .dcolumns_md_12 .dcol-md-2 {
    width: -webkit-calc(16.6666666667% - 2.75rem);
    width: -moz-calc(16.6666666667% - 2.75rem);
    width: calc(16.6666666667% - 2.75rem);
  }
  .dcolumns_md_12 .dcol-md-3 {
    width: -webkit-calc(25% - 2.75rem);
    width: -moz-calc(25% - 2.75rem);
    width: calc(25% - 2.75rem);
  }
  .dcolumns_md_12 .dcol-md-4 {
    width: -webkit-calc(33.3333333333% - 2.75rem);
    width: -moz-calc(33.3333333333% - 2.75rem);
    width: calc(33.3333333333% - 2.75rem);
  }
  .dcolumns_md_12 .dcol-md-5 {
    width: -webkit-calc(41.6666666667% - 2.75rem);
    width: -moz-calc(41.6666666667% - 2.75rem);
    width: calc(41.6666666667% - 2.75rem);
  }
  .dcolumns_md_12 .dcol-md-6 {
    width: -webkit-calc(50% - 2.75rem);
    width: -moz-calc(50% - 2.75rem);
    width: calc(50% - 2.75rem);
  }
  .dcolumns_md_12 .dcol-md-7 {
    width: -webkit-calc(58.3333333333% - 2.75rem);
    width: -moz-calc(58.3333333333% - 2.75rem);
    width: calc(58.3333333333% - 2.75rem);
  }
  .dcolumns_md_12 .dcol-md-8 {
    width: -webkit-calc(66.6666666667% - 2.75rem);
    width: -moz-calc(66.6666666667% - 2.75rem);
    width: calc(66.6666666667% - 2.75rem);
  }
  .dcolumns_md_12 .dcol-md-9 {
    width: -webkit-calc(75% - 2.75rem);
    width: -moz-calc(75% - 2.75rem);
    width: calc(75% - 2.75rem);
  }
  .dcolumns_md_12 .dcol-md-10 {
    width: -webkit-calc(83.3333333333% - 2.75rem);
    width: -moz-calc(83.3333333333% - 2.75rem);
    width: calc(83.3333333333% - 2.75rem);
  }
  .dcolumns_md_12 .dcol-md-11 {
    width: -webkit-calc(91.6666666667% - 2.75rem);
    width: -moz-calc(91.6666666667% - 2.75rem);
    width: calc(91.6666666667% - 2.75rem);
  }
  .dcolumns_md_12 .dcol-md-12 {
    width: -webkit-calc(100% - 2.75rem);
    width: -moz-calc(100% - 2.75rem);
    width: calc(100% - 2.75rem);
  }
}
@media only screen and (max-width: 767px) {
  .dcol {
    margin-right: 1.5rem;
  }

  .ds_col {
    margin-right: 0;
  }

  .dcol-sp-12, .dcol-sp-11, .dcol-sp-10, .dcol-sp-9, .dcol-sp-8, .dcol-sp-7, .dcol-sp-6, .dcol-sp-5, .dcol-sp-4, .dcol-sp-3, .dcol-sp-2, .dcol-sp-1 {
    margin-right: 1.5rem;
  }

  .dcolumns_sp_12 .ds_col:nth-child(12n), .dcolumns_sp_12 .dcol:nth-child(12n), .dcolumns_sp_11 .ds_col:nth-child(11n), .dcolumns_sp_11 .dcol:nth-child(11n), .dcolumns_sp_10 .ds_col:nth-child(10n), .dcolumns_sp_10 .dcol:nth-child(10n), .dcolumns_sp_9 .ds_col:nth-child(9n), .dcolumns_sp_9 .dcol:nth-child(9n), .dcolumns_sp_8 .ds_col:nth-child(8n), .dcolumns_sp_8 .dcol:nth-child(8n), .dcolumns_sp_7 .ds_col:nth-child(7n), .dcolumns_sp_7 .dcol:nth-child(7n), .dcolumns_sp_6 .ds_col:nth-child(6n), .dcolumns_sp_6 .dcol:nth-child(6n), .dcolumns_sp_5 .ds_col:nth-child(5n), .dcolumns_sp_5 .dcol:nth-child(5n), .dcolumns_sp_4 .ds_col:nth-child(4n), .dcolumns_sp_4 .dcol:nth-child(4n), .dcolumns_sp_3 .ds_col:nth-child(3n), .dcolumns_sp_3 .dcol:nth-child(3n), .dcolumns_sp_2 .ds_col:nth-child(2n), .dcolumns_sp_2 .dcol:nth-child(2n), .dcolumns_sp_1 .ds_col:nth-child(1n), .dcolumns_sp_1 .dcol:nth-child(1n) {
    margin-right: 0;
  }

  .dcolumns_sp_1 .dcol {
    width: -webkit-calc((100% - 0rem)/1);
    width: -moz-calc((100% - 0rem)/1);
    width: calc((100% - 0rem)/1);
  }
  .dcolumns_sp_1 .dcol-sp-1 {
    width: -webkit-calc(8.3333333333% - 0rem);
    width: -moz-calc(8.3333333333% - 0rem);
    width: calc(8.3333333333% - 0rem);
  }
  .dcolumns_sp_1 .dcol-sp-2 {
    width: -webkit-calc(16.6666666667% - 0rem);
    width: -moz-calc(16.6666666667% - 0rem);
    width: calc(16.6666666667% - 0rem);
  }
  .dcolumns_sp_1 .dcol-sp-3 {
    width: -webkit-calc(25% - 0rem);
    width: -moz-calc(25% - 0rem);
    width: calc(25% - 0rem);
  }
  .dcolumns_sp_1 .dcol-sp-4 {
    width: -webkit-calc(33.3333333333% - 0rem);
    width: -moz-calc(33.3333333333% - 0rem);
    width: calc(33.3333333333% - 0rem);
  }
  .dcolumns_sp_1 .dcol-sp-5 {
    width: -webkit-calc(41.6666666667% - 0rem);
    width: -moz-calc(41.6666666667% - 0rem);
    width: calc(41.6666666667% - 0rem);
  }
  .dcolumns_sp_1 .dcol-sp-6 {
    width: -webkit-calc(50% - 0rem);
    width: -moz-calc(50% - 0rem);
    width: calc(50% - 0rem);
  }
  .dcolumns_sp_1 .dcol-sp-7 {
    width: -webkit-calc(58.3333333333% - 0rem);
    width: -moz-calc(58.3333333333% - 0rem);
    width: calc(58.3333333333% - 0rem);
  }
  .dcolumns_sp_1 .dcol-sp-8 {
    width: -webkit-calc(66.6666666667% - 0rem);
    width: -moz-calc(66.6666666667% - 0rem);
    width: calc(66.6666666667% - 0rem);
  }
  .dcolumns_sp_1 .dcol-sp-9 {
    width: -webkit-calc(75% - 0rem);
    width: -moz-calc(75% - 0rem);
    width: calc(75% - 0rem);
  }
  .dcolumns_sp_1 .dcol-sp-10 {
    width: -webkit-calc(83.3333333333% - 0rem);
    width: -moz-calc(83.3333333333% - 0rem);
    width: calc(83.3333333333% - 0rem);
  }
  .dcolumns_sp_1 .dcol-sp-11 {
    width: -webkit-calc(91.6666666667% - 0rem);
    width: -moz-calc(91.6666666667% - 0rem);
    width: calc(91.6666666667% - 0rem);
  }
  .dcolumns_sp_1 .dcol-sp-12 {
    width: -webkit-calc(100% - 0rem);
    width: -moz-calc(100% - 0rem);
    width: calc(100% - 0rem);
  }
  .dcolumns_sp_2 .dcol {
    width: -webkit-calc((100% - 1.5rem)/2);
    width: -moz-calc((100% - 1.5rem)/2);
    width: calc((100% - 1.5rem)/2);
  }
  .dcolumns_sp_2 .dcol-sp-1 {
    width: -webkit-calc(8.3333333333% - 0.75rem);
    width: -moz-calc(8.3333333333% - 0.75rem);
    width: calc(8.3333333333% - 0.75rem);
  }
  .dcolumns_sp_2 .dcol-sp-2 {
    width: -webkit-calc(16.6666666667% - 0.75rem);
    width: -moz-calc(16.6666666667% - 0.75rem);
    width: calc(16.6666666667% - 0.75rem);
  }
  .dcolumns_sp_2 .dcol-sp-3 {
    width: -webkit-calc(25% - 0.75rem);
    width: -moz-calc(25% - 0.75rem);
    width: calc(25% - 0.75rem);
  }
  .dcolumns_sp_2 .dcol-sp-4 {
    width: -webkit-calc(33.3333333333% - 0.75rem);
    width: -moz-calc(33.3333333333% - 0.75rem);
    width: calc(33.3333333333% - 0.75rem);
  }
  .dcolumns_sp_2 .dcol-sp-5 {
    width: -webkit-calc(41.6666666667% - 0.75rem);
    width: -moz-calc(41.6666666667% - 0.75rem);
    width: calc(41.6666666667% - 0.75rem);
  }
  .dcolumns_sp_2 .dcol-sp-6 {
    width: -webkit-calc(50% - 0.75rem);
    width: -moz-calc(50% - 0.75rem);
    width: calc(50% - 0.75rem);
  }
  .dcolumns_sp_2 .dcol-sp-7 {
    width: -webkit-calc(58.3333333333% - 0.75rem);
    width: -moz-calc(58.3333333333% - 0.75rem);
    width: calc(58.3333333333% - 0.75rem);
  }
  .dcolumns_sp_2 .dcol-sp-8 {
    width: -webkit-calc(66.6666666667% - 0.75rem);
    width: -moz-calc(66.6666666667% - 0.75rem);
    width: calc(66.6666666667% - 0.75rem);
  }
  .dcolumns_sp_2 .dcol-sp-9 {
    width: -webkit-calc(75% - 0.75rem);
    width: -moz-calc(75% - 0.75rem);
    width: calc(75% - 0.75rem);
  }
  .dcolumns_sp_2 .dcol-sp-10 {
    width: -webkit-calc(83.3333333333% - 0.75rem);
    width: -moz-calc(83.3333333333% - 0.75rem);
    width: calc(83.3333333333% - 0.75rem);
  }
  .dcolumns_sp_2 .dcol-sp-11 {
    width: -webkit-calc(91.6666666667% - 0.75rem);
    width: -moz-calc(91.6666666667% - 0.75rem);
    width: calc(91.6666666667% - 0.75rem);
  }
  .dcolumns_sp_2 .dcol-sp-12 {
    width: -webkit-calc(100% - 0.75rem);
    width: -moz-calc(100% - 0.75rem);
    width: calc(100% - 0.75rem);
  }
  .dcolumns_sp_3 .dcol {
    width: -webkit-calc((100% - 3rem)/3);
    width: -moz-calc((100% - 3rem)/3);
    width: calc((100% - 3rem)/3);
  }
  .dcolumns_sp_3 .dcol-sp-1 {
    width: -webkit-calc(8.3333333333% - 1rem);
    width: -moz-calc(8.3333333333% - 1rem);
    width: calc(8.3333333333% - 1rem);
  }
  .dcolumns_sp_3 .dcol-sp-2 {
    width: -webkit-calc(16.6666666667% - 1rem);
    width: -moz-calc(16.6666666667% - 1rem);
    width: calc(16.6666666667% - 1rem);
  }
  .dcolumns_sp_3 .dcol-sp-3 {
    width: -webkit-calc(25% - 1rem);
    width: -moz-calc(25% - 1rem);
    width: calc(25% - 1rem);
  }
  .dcolumns_sp_3 .dcol-sp-4 {
    width: -webkit-calc(33.3333333333% - 1rem);
    width: -moz-calc(33.3333333333% - 1rem);
    width: calc(33.3333333333% - 1rem);
  }
  .dcolumns_sp_3 .dcol-sp-5 {
    width: -webkit-calc(41.6666666667% - 1rem);
    width: -moz-calc(41.6666666667% - 1rem);
    width: calc(41.6666666667% - 1rem);
  }
  .dcolumns_sp_3 .dcol-sp-6 {
    width: -webkit-calc(50% - 1rem);
    width: -moz-calc(50% - 1rem);
    width: calc(50% - 1rem);
  }
  .dcolumns_sp_3 .dcol-sp-7 {
    width: -webkit-calc(58.3333333333% - 1rem);
    width: -moz-calc(58.3333333333% - 1rem);
    width: calc(58.3333333333% - 1rem);
  }
  .dcolumns_sp_3 .dcol-sp-8 {
    width: -webkit-calc(66.6666666667% - 1rem);
    width: -moz-calc(66.6666666667% - 1rem);
    width: calc(66.6666666667% - 1rem);
  }
  .dcolumns_sp_3 .dcol-sp-9 {
    width: -webkit-calc(75% - 1rem);
    width: -moz-calc(75% - 1rem);
    width: calc(75% - 1rem);
  }
  .dcolumns_sp_3 .dcol-sp-10 {
    width: -webkit-calc(83.3333333333% - 1rem);
    width: -moz-calc(83.3333333333% - 1rem);
    width: calc(83.3333333333% - 1rem);
  }
  .dcolumns_sp_3 .dcol-sp-11 {
    width: -webkit-calc(91.6666666667% - 1rem);
    width: -moz-calc(91.6666666667% - 1rem);
    width: calc(91.6666666667% - 1rem);
  }
  .dcolumns_sp_3 .dcol-sp-12 {
    width: -webkit-calc(100% - 1rem);
    width: -moz-calc(100% - 1rem);
    width: calc(100% - 1rem);
  }
  .dcolumns_sp_4 .dcol {
    width: -webkit-calc((100% - 4.5rem)/4);
    width: -moz-calc((100% - 4.5rem)/4);
    width: calc((100% - 4.5rem)/4);
  }
  .dcolumns_sp_4 .dcol-sp-1 {
    width: -webkit-calc(8.3333333333% - 1.125rem);
    width: -moz-calc(8.3333333333% - 1.125rem);
    width: calc(8.3333333333% - 1.125rem);
  }
  .dcolumns_sp_4 .dcol-sp-2 {
    width: -webkit-calc(16.6666666667% - 1.125rem);
    width: -moz-calc(16.6666666667% - 1.125rem);
    width: calc(16.6666666667% - 1.125rem);
  }
  .dcolumns_sp_4 .dcol-sp-3 {
    width: -webkit-calc(25% - 1.125rem);
    width: -moz-calc(25% - 1.125rem);
    width: calc(25% - 1.125rem);
  }
  .dcolumns_sp_4 .dcol-sp-4 {
    width: -webkit-calc(33.3333333333% - 1.125rem);
    width: -moz-calc(33.3333333333% - 1.125rem);
    width: calc(33.3333333333% - 1.125rem);
  }
  .dcolumns_sp_4 .dcol-sp-5 {
    width: -webkit-calc(41.6666666667% - 1.125rem);
    width: -moz-calc(41.6666666667% - 1.125rem);
    width: calc(41.6666666667% - 1.125rem);
  }
  .dcolumns_sp_4 .dcol-sp-6 {
    width: -webkit-calc(50% - 1.125rem);
    width: -moz-calc(50% - 1.125rem);
    width: calc(50% - 1.125rem);
  }
  .dcolumns_sp_4 .dcol-sp-7 {
    width: -webkit-calc(58.3333333333% - 1.125rem);
    width: -moz-calc(58.3333333333% - 1.125rem);
    width: calc(58.3333333333% - 1.125rem);
  }
  .dcolumns_sp_4 .dcol-sp-8 {
    width: -webkit-calc(66.6666666667% - 1.125rem);
    width: -moz-calc(66.6666666667% - 1.125rem);
    width: calc(66.6666666667% - 1.125rem);
  }
  .dcolumns_sp_4 .dcol-sp-9 {
    width: -webkit-calc(75% - 1.125rem);
    width: -moz-calc(75% - 1.125rem);
    width: calc(75% - 1.125rem);
  }
  .dcolumns_sp_4 .dcol-sp-10 {
    width: -webkit-calc(83.3333333333% - 1.125rem);
    width: -moz-calc(83.3333333333% - 1.125rem);
    width: calc(83.3333333333% - 1.125rem);
  }
  .dcolumns_sp_4 .dcol-sp-11 {
    width: -webkit-calc(91.6666666667% - 1.125rem);
    width: -moz-calc(91.6666666667% - 1.125rem);
    width: calc(91.6666666667% - 1.125rem);
  }
  .dcolumns_sp_4 .dcol-sp-12 {
    width: -webkit-calc(100% - 1.125rem);
    width: -moz-calc(100% - 1.125rem);
    width: calc(100% - 1.125rem);
  }
  .dcolumns_sp_5 .dcol {
    width: -webkit-calc((100% - 6rem)/5);
    width: -moz-calc((100% - 6rem)/5);
    width: calc((100% - 6rem)/5);
  }
  .dcolumns_sp_5 .dcol-sp-1 {
    width: -webkit-calc(8.3333333333% - 1.2rem);
    width: -moz-calc(8.3333333333% - 1.2rem);
    width: calc(8.3333333333% - 1.2rem);
  }
  .dcolumns_sp_5 .dcol-sp-2 {
    width: -webkit-calc(16.6666666667% - 1.2rem);
    width: -moz-calc(16.6666666667% - 1.2rem);
    width: calc(16.6666666667% - 1.2rem);
  }
  .dcolumns_sp_5 .dcol-sp-3 {
    width: -webkit-calc(25% - 1.2rem);
    width: -moz-calc(25% - 1.2rem);
    width: calc(25% - 1.2rem);
  }
  .dcolumns_sp_5 .dcol-sp-4 {
    width: -webkit-calc(33.3333333333% - 1.2rem);
    width: -moz-calc(33.3333333333% - 1.2rem);
    width: calc(33.3333333333% - 1.2rem);
  }
  .dcolumns_sp_5 .dcol-sp-5 {
    width: -webkit-calc(41.6666666667% - 1.2rem);
    width: -moz-calc(41.6666666667% - 1.2rem);
    width: calc(41.6666666667% - 1.2rem);
  }
  .dcolumns_sp_5 .dcol-sp-6 {
    width: -webkit-calc(50% - 1.2rem);
    width: -moz-calc(50% - 1.2rem);
    width: calc(50% - 1.2rem);
  }
  .dcolumns_sp_5 .dcol-sp-7 {
    width: -webkit-calc(58.3333333333% - 1.2rem);
    width: -moz-calc(58.3333333333% - 1.2rem);
    width: calc(58.3333333333% - 1.2rem);
  }
  .dcolumns_sp_5 .dcol-sp-8 {
    width: -webkit-calc(66.6666666667% - 1.2rem);
    width: -moz-calc(66.6666666667% - 1.2rem);
    width: calc(66.6666666667% - 1.2rem);
  }
  .dcolumns_sp_5 .dcol-sp-9 {
    width: -webkit-calc(75% - 1.2rem);
    width: -moz-calc(75% - 1.2rem);
    width: calc(75% - 1.2rem);
  }
  .dcolumns_sp_5 .dcol-sp-10 {
    width: -webkit-calc(83.3333333333% - 1.2rem);
    width: -moz-calc(83.3333333333% - 1.2rem);
    width: calc(83.3333333333% - 1.2rem);
  }
  .dcolumns_sp_5 .dcol-sp-11 {
    width: -webkit-calc(91.6666666667% - 1.2rem);
    width: -moz-calc(91.6666666667% - 1.2rem);
    width: calc(91.6666666667% - 1.2rem);
  }
  .dcolumns_sp_5 .dcol-sp-12 {
    width: -webkit-calc(100% - 1.2rem);
    width: -moz-calc(100% - 1.2rem);
    width: calc(100% - 1.2rem);
  }
  .dcolumns_sp_6 .dcol {
    width: -webkit-calc((100% - 7.5rem)/6);
    width: -moz-calc((100% - 7.5rem)/6);
    width: calc((100% - 7.5rem)/6);
  }
  .dcolumns_sp_6 .dcol-sp-1 {
    width: -webkit-calc(8.3333333333% - 1.25rem);
    width: -moz-calc(8.3333333333% - 1.25rem);
    width: calc(8.3333333333% - 1.25rem);
  }
  .dcolumns_sp_6 .dcol-sp-2 {
    width: -webkit-calc(16.6666666667% - 1.25rem);
    width: -moz-calc(16.6666666667% - 1.25rem);
    width: calc(16.6666666667% - 1.25rem);
  }
  .dcolumns_sp_6 .dcol-sp-3 {
    width: -webkit-calc(25% - 1.25rem);
    width: -moz-calc(25% - 1.25rem);
    width: calc(25% - 1.25rem);
  }
  .dcolumns_sp_6 .dcol-sp-4 {
    width: -webkit-calc(33.3333333333% - 1.25rem);
    width: -moz-calc(33.3333333333% - 1.25rem);
    width: calc(33.3333333333% - 1.25rem);
  }
  .dcolumns_sp_6 .dcol-sp-5 {
    width: -webkit-calc(41.6666666667% - 1.25rem);
    width: -moz-calc(41.6666666667% - 1.25rem);
    width: calc(41.6666666667% - 1.25rem);
  }
  .dcolumns_sp_6 .dcol-sp-6 {
    width: -webkit-calc(50% - 1.25rem);
    width: -moz-calc(50% - 1.25rem);
    width: calc(50% - 1.25rem);
  }
  .dcolumns_sp_6 .dcol-sp-7 {
    width: -webkit-calc(58.3333333333% - 1.25rem);
    width: -moz-calc(58.3333333333% - 1.25rem);
    width: calc(58.3333333333% - 1.25rem);
  }
  .dcolumns_sp_6 .dcol-sp-8 {
    width: -webkit-calc(66.6666666667% - 1.25rem);
    width: -moz-calc(66.6666666667% - 1.25rem);
    width: calc(66.6666666667% - 1.25rem);
  }
  .dcolumns_sp_6 .dcol-sp-9 {
    width: -webkit-calc(75% - 1.25rem);
    width: -moz-calc(75% - 1.25rem);
    width: calc(75% - 1.25rem);
  }
  .dcolumns_sp_6 .dcol-sp-10 {
    width: -webkit-calc(83.3333333333% - 1.25rem);
    width: -moz-calc(83.3333333333% - 1.25rem);
    width: calc(83.3333333333% - 1.25rem);
  }
  .dcolumns_sp_6 .dcol-sp-11 {
    width: -webkit-calc(91.6666666667% - 1.25rem);
    width: -moz-calc(91.6666666667% - 1.25rem);
    width: calc(91.6666666667% - 1.25rem);
  }
  .dcolumns_sp_6 .dcol-sp-12 {
    width: -webkit-calc(100% - 1.25rem);
    width: -moz-calc(100% - 1.25rem);
    width: calc(100% - 1.25rem);
  }
  .dcolumns_sp_7 .dcol {
    width: -webkit-calc((100% - 9rem)/7);
    width: -moz-calc((100% - 9rem)/7);
    width: calc((100% - 9rem)/7);
  }
  .dcolumns_sp_7 .dcol-sp-1 {
    width: -webkit-calc(8.3333333333% - 1.2857142857rem);
    width: -moz-calc(8.3333333333% - 1.2857142857rem);
    width: calc(8.3333333333% - 1.2857142857rem);
  }
  .dcolumns_sp_7 .dcol-sp-2 {
    width: -webkit-calc(16.6666666667% - 1.2857142857rem);
    width: -moz-calc(16.6666666667% - 1.2857142857rem);
    width: calc(16.6666666667% - 1.2857142857rem);
  }
  .dcolumns_sp_7 .dcol-sp-3 {
    width: -webkit-calc(25% - 1.2857142857rem);
    width: -moz-calc(25% - 1.2857142857rem);
    width: calc(25% - 1.2857142857rem);
  }
  .dcolumns_sp_7 .dcol-sp-4 {
    width: -webkit-calc(33.3333333333% - 1.2857142857rem);
    width: -moz-calc(33.3333333333% - 1.2857142857rem);
    width: calc(33.3333333333% - 1.2857142857rem);
  }
  .dcolumns_sp_7 .dcol-sp-5 {
    width: -webkit-calc(41.6666666667% - 1.2857142857rem);
    width: -moz-calc(41.6666666667% - 1.2857142857rem);
    width: calc(41.6666666667% - 1.2857142857rem);
  }
  .dcolumns_sp_7 .dcol-sp-6 {
    width: -webkit-calc(50% - 1.2857142857rem);
    width: -moz-calc(50% - 1.2857142857rem);
    width: calc(50% - 1.2857142857rem);
  }
  .dcolumns_sp_7 .dcol-sp-7 {
    width: -webkit-calc(58.3333333333% - 1.2857142857rem);
    width: -moz-calc(58.3333333333% - 1.2857142857rem);
    width: calc(58.3333333333% - 1.2857142857rem);
  }
  .dcolumns_sp_7 .dcol-sp-8 {
    width: -webkit-calc(66.6666666667% - 1.2857142857rem);
    width: -moz-calc(66.6666666667% - 1.2857142857rem);
    width: calc(66.6666666667% - 1.2857142857rem);
  }
  .dcolumns_sp_7 .dcol-sp-9 {
    width: -webkit-calc(75% - 1.2857142857rem);
    width: -moz-calc(75% - 1.2857142857rem);
    width: calc(75% - 1.2857142857rem);
  }
  .dcolumns_sp_7 .dcol-sp-10 {
    width: -webkit-calc(83.3333333333% - 1.2857142857rem);
    width: -moz-calc(83.3333333333% - 1.2857142857rem);
    width: calc(83.3333333333% - 1.2857142857rem);
  }
  .dcolumns_sp_7 .dcol-sp-11 {
    width: -webkit-calc(91.6666666667% - 1.2857142857rem);
    width: -moz-calc(91.6666666667% - 1.2857142857rem);
    width: calc(91.6666666667% - 1.2857142857rem);
  }
  .dcolumns_sp_7 .dcol-sp-12 {
    width: -webkit-calc(100% - 1.2857142857rem);
    width: -moz-calc(100% - 1.2857142857rem);
    width: calc(100% - 1.2857142857rem);
  }
  .dcolumns_sp_8 .dcol {
    width: -webkit-calc((100% - 10.5rem)/8);
    width: -moz-calc((100% - 10.5rem)/8);
    width: calc((100% - 10.5rem)/8);
  }
  .dcolumns_sp_8 .dcol-sp-1 {
    width: -webkit-calc(8.3333333333% - 1.3125rem);
    width: -moz-calc(8.3333333333% - 1.3125rem);
    width: calc(8.3333333333% - 1.3125rem);
  }
  .dcolumns_sp_8 .dcol-sp-2 {
    width: -webkit-calc(16.6666666667% - 1.3125rem);
    width: -moz-calc(16.6666666667% - 1.3125rem);
    width: calc(16.6666666667% - 1.3125rem);
  }
  .dcolumns_sp_8 .dcol-sp-3 {
    width: -webkit-calc(25% - 1.3125rem);
    width: -moz-calc(25% - 1.3125rem);
    width: calc(25% - 1.3125rem);
  }
  .dcolumns_sp_8 .dcol-sp-4 {
    width: -webkit-calc(33.3333333333% - 1.3125rem);
    width: -moz-calc(33.3333333333% - 1.3125rem);
    width: calc(33.3333333333% - 1.3125rem);
  }
  .dcolumns_sp_8 .dcol-sp-5 {
    width: -webkit-calc(41.6666666667% - 1.3125rem);
    width: -moz-calc(41.6666666667% - 1.3125rem);
    width: calc(41.6666666667% - 1.3125rem);
  }
  .dcolumns_sp_8 .dcol-sp-6 {
    width: -webkit-calc(50% - 1.3125rem);
    width: -moz-calc(50% - 1.3125rem);
    width: calc(50% - 1.3125rem);
  }
  .dcolumns_sp_8 .dcol-sp-7 {
    width: -webkit-calc(58.3333333333% - 1.3125rem);
    width: -moz-calc(58.3333333333% - 1.3125rem);
    width: calc(58.3333333333% - 1.3125rem);
  }
  .dcolumns_sp_8 .dcol-sp-8 {
    width: -webkit-calc(66.6666666667% - 1.3125rem);
    width: -moz-calc(66.6666666667% - 1.3125rem);
    width: calc(66.6666666667% - 1.3125rem);
  }
  .dcolumns_sp_8 .dcol-sp-9 {
    width: -webkit-calc(75% - 1.3125rem);
    width: -moz-calc(75% - 1.3125rem);
    width: calc(75% - 1.3125rem);
  }
  .dcolumns_sp_8 .dcol-sp-10 {
    width: -webkit-calc(83.3333333333% - 1.3125rem);
    width: -moz-calc(83.3333333333% - 1.3125rem);
    width: calc(83.3333333333% - 1.3125rem);
  }
  .dcolumns_sp_8 .dcol-sp-11 {
    width: -webkit-calc(91.6666666667% - 1.3125rem);
    width: -moz-calc(91.6666666667% - 1.3125rem);
    width: calc(91.6666666667% - 1.3125rem);
  }
  .dcolumns_sp_8 .dcol-sp-12 {
    width: -webkit-calc(100% - 1.3125rem);
    width: -moz-calc(100% - 1.3125rem);
    width: calc(100% - 1.3125rem);
  }
  .dcolumns_sp_9 .dcol {
    width: -webkit-calc((100% - 12rem)/9);
    width: -moz-calc((100% - 12rem)/9);
    width: calc((100% - 12rem)/9);
  }
  .dcolumns_sp_9 .dcol-sp-1 {
    width: -webkit-calc(8.3333333333% - 1.3333333333rem);
    width: -moz-calc(8.3333333333% - 1.3333333333rem);
    width: calc(8.3333333333% - 1.3333333333rem);
  }
  .dcolumns_sp_9 .dcol-sp-2 {
    width: -webkit-calc(16.6666666667% - 1.3333333333rem);
    width: -moz-calc(16.6666666667% - 1.3333333333rem);
    width: calc(16.6666666667% - 1.3333333333rem);
  }
  .dcolumns_sp_9 .dcol-sp-3 {
    width: -webkit-calc(25% - 1.3333333333rem);
    width: -moz-calc(25% - 1.3333333333rem);
    width: calc(25% - 1.3333333333rem);
  }
  .dcolumns_sp_9 .dcol-sp-4 {
    width: -webkit-calc(33.3333333333% - 1.3333333333rem);
    width: -moz-calc(33.3333333333% - 1.3333333333rem);
    width: calc(33.3333333333% - 1.3333333333rem);
  }
  .dcolumns_sp_9 .dcol-sp-5 {
    width: -webkit-calc(41.6666666667% - 1.3333333333rem);
    width: -moz-calc(41.6666666667% - 1.3333333333rem);
    width: calc(41.6666666667% - 1.3333333333rem);
  }
  .dcolumns_sp_9 .dcol-sp-6 {
    width: -webkit-calc(50% - 1.3333333333rem);
    width: -moz-calc(50% - 1.3333333333rem);
    width: calc(50% - 1.3333333333rem);
  }
  .dcolumns_sp_9 .dcol-sp-7 {
    width: -webkit-calc(58.3333333333% - 1.3333333333rem);
    width: -moz-calc(58.3333333333% - 1.3333333333rem);
    width: calc(58.3333333333% - 1.3333333333rem);
  }
  .dcolumns_sp_9 .dcol-sp-8 {
    width: -webkit-calc(66.6666666667% - 1.3333333333rem);
    width: -moz-calc(66.6666666667% - 1.3333333333rem);
    width: calc(66.6666666667% - 1.3333333333rem);
  }
  .dcolumns_sp_9 .dcol-sp-9 {
    width: -webkit-calc(75% - 1.3333333333rem);
    width: -moz-calc(75% - 1.3333333333rem);
    width: calc(75% - 1.3333333333rem);
  }
  .dcolumns_sp_9 .dcol-sp-10 {
    width: -webkit-calc(83.3333333333% - 1.3333333333rem);
    width: -moz-calc(83.3333333333% - 1.3333333333rem);
    width: calc(83.3333333333% - 1.3333333333rem);
  }
  .dcolumns_sp_9 .dcol-sp-11 {
    width: -webkit-calc(91.6666666667% - 1.3333333333rem);
    width: -moz-calc(91.6666666667% - 1.3333333333rem);
    width: calc(91.6666666667% - 1.3333333333rem);
  }
  .dcolumns_sp_9 .dcol-sp-12 {
    width: -webkit-calc(100% - 1.3333333333rem);
    width: -moz-calc(100% - 1.3333333333rem);
    width: calc(100% - 1.3333333333rem);
  }
  .dcolumns_sp_10 .dcol {
    width: -webkit-calc((100% - 13.5rem)/10);
    width: -moz-calc((100% - 13.5rem)/10);
    width: calc((100% - 13.5rem)/10);
  }
  .dcolumns_sp_10 .dcol-sp-1 {
    width: -webkit-calc(8.3333333333% - 1.35rem);
    width: -moz-calc(8.3333333333% - 1.35rem);
    width: calc(8.3333333333% - 1.35rem);
  }
  .dcolumns_sp_10 .dcol-sp-2 {
    width: -webkit-calc(16.6666666667% - 1.35rem);
    width: -moz-calc(16.6666666667% - 1.35rem);
    width: calc(16.6666666667% - 1.35rem);
  }
  .dcolumns_sp_10 .dcol-sp-3 {
    width: -webkit-calc(25% - 1.35rem);
    width: -moz-calc(25% - 1.35rem);
    width: calc(25% - 1.35rem);
  }
  .dcolumns_sp_10 .dcol-sp-4 {
    width: -webkit-calc(33.3333333333% - 1.35rem);
    width: -moz-calc(33.3333333333% - 1.35rem);
    width: calc(33.3333333333% - 1.35rem);
  }
  .dcolumns_sp_10 .dcol-sp-5 {
    width: -webkit-calc(41.6666666667% - 1.35rem);
    width: -moz-calc(41.6666666667% - 1.35rem);
    width: calc(41.6666666667% - 1.35rem);
  }
  .dcolumns_sp_10 .dcol-sp-6 {
    width: -webkit-calc(50% - 1.35rem);
    width: -moz-calc(50% - 1.35rem);
    width: calc(50% - 1.35rem);
  }
  .dcolumns_sp_10 .dcol-sp-7 {
    width: -webkit-calc(58.3333333333% - 1.35rem);
    width: -moz-calc(58.3333333333% - 1.35rem);
    width: calc(58.3333333333% - 1.35rem);
  }
  .dcolumns_sp_10 .dcol-sp-8 {
    width: -webkit-calc(66.6666666667% - 1.35rem);
    width: -moz-calc(66.6666666667% - 1.35rem);
    width: calc(66.6666666667% - 1.35rem);
  }
  .dcolumns_sp_10 .dcol-sp-9 {
    width: -webkit-calc(75% - 1.35rem);
    width: -moz-calc(75% - 1.35rem);
    width: calc(75% - 1.35rem);
  }
  .dcolumns_sp_10 .dcol-sp-10 {
    width: -webkit-calc(83.3333333333% - 1.35rem);
    width: -moz-calc(83.3333333333% - 1.35rem);
    width: calc(83.3333333333% - 1.35rem);
  }
  .dcolumns_sp_10 .dcol-sp-11 {
    width: -webkit-calc(91.6666666667% - 1.35rem);
    width: -moz-calc(91.6666666667% - 1.35rem);
    width: calc(91.6666666667% - 1.35rem);
  }
  .dcolumns_sp_10 .dcol-sp-12 {
    width: -webkit-calc(100% - 1.35rem);
    width: -moz-calc(100% - 1.35rem);
    width: calc(100% - 1.35rem);
  }
  .dcolumns_sp_11 .dcol {
    width: -webkit-calc((100% - 15rem)/11);
    width: -moz-calc((100% - 15rem)/11);
    width: calc((100% - 15rem)/11);
  }
  .dcolumns_sp_11 .dcol-sp-1 {
    width: -webkit-calc(8.3333333333% - 1.3636363636rem);
    width: -moz-calc(8.3333333333% - 1.3636363636rem);
    width: calc(8.3333333333% - 1.3636363636rem);
  }
  .dcolumns_sp_11 .dcol-sp-2 {
    width: -webkit-calc(16.6666666667% - 1.3636363636rem);
    width: -moz-calc(16.6666666667% - 1.3636363636rem);
    width: calc(16.6666666667% - 1.3636363636rem);
  }
  .dcolumns_sp_11 .dcol-sp-3 {
    width: -webkit-calc(25% - 1.3636363636rem);
    width: -moz-calc(25% - 1.3636363636rem);
    width: calc(25% - 1.3636363636rem);
  }
  .dcolumns_sp_11 .dcol-sp-4 {
    width: -webkit-calc(33.3333333333% - 1.3636363636rem);
    width: -moz-calc(33.3333333333% - 1.3636363636rem);
    width: calc(33.3333333333% - 1.3636363636rem);
  }
  .dcolumns_sp_11 .dcol-sp-5 {
    width: -webkit-calc(41.6666666667% - 1.3636363636rem);
    width: -moz-calc(41.6666666667% - 1.3636363636rem);
    width: calc(41.6666666667% - 1.3636363636rem);
  }
  .dcolumns_sp_11 .dcol-sp-6 {
    width: -webkit-calc(50% - 1.3636363636rem);
    width: -moz-calc(50% - 1.3636363636rem);
    width: calc(50% - 1.3636363636rem);
  }
  .dcolumns_sp_11 .dcol-sp-7 {
    width: -webkit-calc(58.3333333333% - 1.3636363636rem);
    width: -moz-calc(58.3333333333% - 1.3636363636rem);
    width: calc(58.3333333333% - 1.3636363636rem);
  }
  .dcolumns_sp_11 .dcol-sp-8 {
    width: -webkit-calc(66.6666666667% - 1.3636363636rem);
    width: -moz-calc(66.6666666667% - 1.3636363636rem);
    width: calc(66.6666666667% - 1.3636363636rem);
  }
  .dcolumns_sp_11 .dcol-sp-9 {
    width: -webkit-calc(75% - 1.3636363636rem);
    width: -moz-calc(75% - 1.3636363636rem);
    width: calc(75% - 1.3636363636rem);
  }
  .dcolumns_sp_11 .dcol-sp-10 {
    width: -webkit-calc(83.3333333333% - 1.3636363636rem);
    width: -moz-calc(83.3333333333% - 1.3636363636rem);
    width: calc(83.3333333333% - 1.3636363636rem);
  }
  .dcolumns_sp_11 .dcol-sp-11 {
    width: -webkit-calc(91.6666666667% - 1.3636363636rem);
    width: -moz-calc(91.6666666667% - 1.3636363636rem);
    width: calc(91.6666666667% - 1.3636363636rem);
  }
  .dcolumns_sp_11 .dcol-sp-12 {
    width: -webkit-calc(100% - 1.3636363636rem);
    width: -moz-calc(100% - 1.3636363636rem);
    width: calc(100% - 1.3636363636rem);
  }
  .dcolumns_sp_12 .dcol {
    width: -webkit-calc((100% - 16.5rem)/12);
    width: -moz-calc((100% - 16.5rem)/12);
    width: calc((100% - 16.5rem)/12);
  }
  .dcolumns_sp_12 .dcol-sp-1 {
    width: -webkit-calc(8.3333333333% - 1.375rem);
    width: -moz-calc(8.3333333333% - 1.375rem);
    width: calc(8.3333333333% - 1.375rem);
  }
  .dcolumns_sp_12 .dcol-sp-2 {
    width: -webkit-calc(16.6666666667% - 1.375rem);
    width: -moz-calc(16.6666666667% - 1.375rem);
    width: calc(16.6666666667% - 1.375rem);
  }
  .dcolumns_sp_12 .dcol-sp-3 {
    width: -webkit-calc(25% - 1.375rem);
    width: -moz-calc(25% - 1.375rem);
    width: calc(25% - 1.375rem);
  }
  .dcolumns_sp_12 .dcol-sp-4 {
    width: -webkit-calc(33.3333333333% - 1.375rem);
    width: -moz-calc(33.3333333333% - 1.375rem);
    width: calc(33.3333333333% - 1.375rem);
  }
  .dcolumns_sp_12 .dcol-sp-5 {
    width: -webkit-calc(41.6666666667% - 1.375rem);
    width: -moz-calc(41.6666666667% - 1.375rem);
    width: calc(41.6666666667% - 1.375rem);
  }
  .dcolumns_sp_12 .dcol-sp-6 {
    width: -webkit-calc(50% - 1.375rem);
    width: -moz-calc(50% - 1.375rem);
    width: calc(50% - 1.375rem);
  }
  .dcolumns_sp_12 .dcol-sp-7 {
    width: -webkit-calc(58.3333333333% - 1.375rem);
    width: -moz-calc(58.3333333333% - 1.375rem);
    width: calc(58.3333333333% - 1.375rem);
  }
  .dcolumns_sp_12 .dcol-sp-8 {
    width: -webkit-calc(66.6666666667% - 1.375rem);
    width: -moz-calc(66.6666666667% - 1.375rem);
    width: calc(66.6666666667% - 1.375rem);
  }
  .dcolumns_sp_12 .dcol-sp-9 {
    width: -webkit-calc(75% - 1.375rem);
    width: -moz-calc(75% - 1.375rem);
    width: calc(75% - 1.375rem);
  }
  .dcolumns_sp_12 .dcol-sp-10 {
    width: -webkit-calc(83.3333333333% - 1.375rem);
    width: -moz-calc(83.3333333333% - 1.375rem);
    width: calc(83.3333333333% - 1.375rem);
  }
  .dcolumns_sp_12 .dcol-sp-11 {
    width: -webkit-calc(91.6666666667% - 1.375rem);
    width: -moz-calc(91.6666666667% - 1.375rem);
    width: calc(91.6666666667% - 1.375rem);
  }
  .dcolumns_sp_12 .dcol-sp-12 {
    width: -webkit-calc(100% - 1.375rem);
    width: -moz-calc(100% - 1.375rem);
    width: calc(100% - 1.375rem);
  }
}
@media only screen and (max-width: 576px) {
  .dcolumns_sm_12 .ds_col:nth-child(12n), .dcolumns_sm_12 .dcol:nth-child(12n), .dcolumns_sm_11 .ds_col:nth-child(11n), .dcolumns_sm_11 .dcol:nth-child(11n), .dcolumns_sm_10 .ds_col:nth-child(10n), .dcolumns_sm_10 .dcol:nth-child(10n), .dcolumns_sm_9 .ds_col:nth-child(9n), .dcolumns_sm_9 .dcol:nth-child(9n), .dcolumns_sm_8 .ds_col:nth-child(8n), .dcolumns_sm_8 .dcol:nth-child(8n), .dcolumns_sm_7 .ds_col:nth-child(7n), .dcolumns_sm_7 .dcol:nth-child(7n), .dcolumns_sm_6 .ds_col:nth-child(6n), .dcolumns_sm_6 .dcol:nth-child(6n), .dcolumns_sm_5 .ds_col:nth-child(5n), .dcolumns_sm_5 .dcol:nth-child(5n), .dcolumns_sm_4 .ds_col:nth-child(4n), .dcolumns_sm_4 .dcol:nth-child(4n), .dcolumns_sm_3 .ds_col:nth-child(3n), .dcolumns_sm_3 .dcol:nth-child(3n), .dcolumns_sm_2 .ds_col:nth-child(2n), .dcolumns_sm_2 .dcol:nth-child(2n), .dcolumns_sm_1 .ds_col:nth-child(1n), .dcolumns_sm_1 .dcol:nth-child(1n) {
    margin-right: 0 !important;
  }

  .dcolumns_sm_1 .dcol {
    width: -webkit-calc((100% - 0rem)/1);
    width: -moz-calc((100% - 0rem)/1);
    width: calc((100% - 0rem)/1);
  }
  .dcolumns_sm_1 .dcol-sm-1 {
    width: -webkit-calc(8.3333333333% - 0rem);
    width: -moz-calc(8.3333333333% - 0rem);
    width: calc(8.3333333333% - 0rem);
  }
  .dcolumns_sm_1 .dcol-sm-2 {
    width: -webkit-calc(16.6666666667% - 0rem);
    width: -moz-calc(16.6666666667% - 0rem);
    width: calc(16.6666666667% - 0rem);
  }
  .dcolumns_sm_1 .dcol-sm-3 {
    width: -webkit-calc(25% - 0rem);
    width: -moz-calc(25% - 0rem);
    width: calc(25% - 0rem);
  }
  .dcolumns_sm_1 .dcol-sm-4 {
    width: -webkit-calc(33.3333333333% - 0rem);
    width: -moz-calc(33.3333333333% - 0rem);
    width: calc(33.3333333333% - 0rem);
  }
  .dcolumns_sm_1 .dcol-sm-5 {
    width: -webkit-calc(41.6666666667% - 0rem);
    width: -moz-calc(41.6666666667% - 0rem);
    width: calc(41.6666666667% - 0rem);
  }
  .dcolumns_sm_1 .dcol-sm-6 {
    width: -webkit-calc(50% - 0rem);
    width: -moz-calc(50% - 0rem);
    width: calc(50% - 0rem);
  }
  .dcolumns_sm_1 .dcol-sm-7 {
    width: -webkit-calc(58.3333333333% - 0rem);
    width: -moz-calc(58.3333333333% - 0rem);
    width: calc(58.3333333333% - 0rem);
  }
  .dcolumns_sm_1 .dcol-sm-8 {
    width: -webkit-calc(66.6666666667% - 0rem);
    width: -moz-calc(66.6666666667% - 0rem);
    width: calc(66.6666666667% - 0rem);
  }
  .dcolumns_sm_1 .dcol-sm-9 {
    width: -webkit-calc(75% - 0rem);
    width: -moz-calc(75% - 0rem);
    width: calc(75% - 0rem);
  }
  .dcolumns_sm_1 .dcol-sm-10 {
    width: -webkit-calc(83.3333333333% - 0rem);
    width: -moz-calc(83.3333333333% - 0rem);
    width: calc(83.3333333333% - 0rem);
  }
  .dcolumns_sm_1 .dcol-sm-11 {
    width: -webkit-calc(91.6666666667% - 0rem);
    width: -moz-calc(91.6666666667% - 0rem);
    width: calc(91.6666666667% - 0rem);
  }
  .dcolumns_sm_1 .dcol-sm-12 {
    width: -webkit-calc(100% - 0rem);
    width: -moz-calc(100% - 0rem);
    width: calc(100% - 0rem);
  }
  .dcolumns_sm_2 .dcol {
    width: -webkit-calc((100% - 1.5rem)/2);
    width: -moz-calc((100% - 1.5rem)/2);
    width: calc((100% - 1.5rem)/2);
  }
  .dcolumns_sm_2 .dcol-sm-1 {
    width: -webkit-calc(8.3333333333% - 0.75rem);
    width: -moz-calc(8.3333333333% - 0.75rem);
    width: calc(8.3333333333% - 0.75rem);
  }
  .dcolumns_sm_2 .dcol-sm-2 {
    width: -webkit-calc(16.6666666667% - 0.75rem);
    width: -moz-calc(16.6666666667% - 0.75rem);
    width: calc(16.6666666667% - 0.75rem);
  }
  .dcolumns_sm_2 .dcol-sm-3 {
    width: -webkit-calc(25% - 0.75rem);
    width: -moz-calc(25% - 0.75rem);
    width: calc(25% - 0.75rem);
  }
  .dcolumns_sm_2 .dcol-sm-4 {
    width: -webkit-calc(33.3333333333% - 0.75rem);
    width: -moz-calc(33.3333333333% - 0.75rem);
    width: calc(33.3333333333% - 0.75rem);
  }
  .dcolumns_sm_2 .dcol-sm-5 {
    width: -webkit-calc(41.6666666667% - 0.75rem);
    width: -moz-calc(41.6666666667% - 0.75rem);
    width: calc(41.6666666667% - 0.75rem);
  }
  .dcolumns_sm_2 .dcol-sm-6 {
    width: -webkit-calc(50% - 0.75rem);
    width: -moz-calc(50% - 0.75rem);
    width: calc(50% - 0.75rem);
  }
  .dcolumns_sm_2 .dcol-sm-7 {
    width: -webkit-calc(58.3333333333% - 0.75rem);
    width: -moz-calc(58.3333333333% - 0.75rem);
    width: calc(58.3333333333% - 0.75rem);
  }
  .dcolumns_sm_2 .dcol-sm-8 {
    width: -webkit-calc(66.6666666667% - 0.75rem);
    width: -moz-calc(66.6666666667% - 0.75rem);
    width: calc(66.6666666667% - 0.75rem);
  }
  .dcolumns_sm_2 .dcol-sm-9 {
    width: -webkit-calc(75% - 0.75rem);
    width: -moz-calc(75% - 0.75rem);
    width: calc(75% - 0.75rem);
  }
  .dcolumns_sm_2 .dcol-sm-10 {
    width: -webkit-calc(83.3333333333% - 0.75rem);
    width: -moz-calc(83.3333333333% - 0.75rem);
    width: calc(83.3333333333% - 0.75rem);
  }
  .dcolumns_sm_2 .dcol-sm-11 {
    width: -webkit-calc(91.6666666667% - 0.75rem);
    width: -moz-calc(91.6666666667% - 0.75rem);
    width: calc(91.6666666667% - 0.75rem);
  }
  .dcolumns_sm_2 .dcol-sm-12 {
    width: -webkit-calc(100% - 0.75rem);
    width: -moz-calc(100% - 0.75rem);
    width: calc(100% - 0.75rem);
  }
  .dcolumns_sm_3 .dcol {
    width: -webkit-calc((100% - 3rem)/3);
    width: -moz-calc((100% - 3rem)/3);
    width: calc((100% - 3rem)/3);
  }
  .dcolumns_sm_3 .dcol-sm-1 {
    width: -webkit-calc(8.3333333333% - 1rem);
    width: -moz-calc(8.3333333333% - 1rem);
    width: calc(8.3333333333% - 1rem);
  }
  .dcolumns_sm_3 .dcol-sm-2 {
    width: -webkit-calc(16.6666666667% - 1rem);
    width: -moz-calc(16.6666666667% - 1rem);
    width: calc(16.6666666667% - 1rem);
  }
  .dcolumns_sm_3 .dcol-sm-3 {
    width: -webkit-calc(25% - 1rem);
    width: -moz-calc(25% - 1rem);
    width: calc(25% - 1rem);
  }
  .dcolumns_sm_3 .dcol-sm-4 {
    width: -webkit-calc(33.3333333333% - 1rem);
    width: -moz-calc(33.3333333333% - 1rem);
    width: calc(33.3333333333% - 1rem);
  }
  .dcolumns_sm_3 .dcol-sm-5 {
    width: -webkit-calc(41.6666666667% - 1rem);
    width: -moz-calc(41.6666666667% - 1rem);
    width: calc(41.6666666667% - 1rem);
  }
  .dcolumns_sm_3 .dcol-sm-6 {
    width: -webkit-calc(50% - 1rem);
    width: -moz-calc(50% - 1rem);
    width: calc(50% - 1rem);
  }
  .dcolumns_sm_3 .dcol-sm-7 {
    width: -webkit-calc(58.3333333333% - 1rem);
    width: -moz-calc(58.3333333333% - 1rem);
    width: calc(58.3333333333% - 1rem);
  }
  .dcolumns_sm_3 .dcol-sm-8 {
    width: -webkit-calc(66.6666666667% - 1rem);
    width: -moz-calc(66.6666666667% - 1rem);
    width: calc(66.6666666667% - 1rem);
  }
  .dcolumns_sm_3 .dcol-sm-9 {
    width: -webkit-calc(75% - 1rem);
    width: -moz-calc(75% - 1rem);
    width: calc(75% - 1rem);
  }
  .dcolumns_sm_3 .dcol-sm-10 {
    width: -webkit-calc(83.3333333333% - 1rem);
    width: -moz-calc(83.3333333333% - 1rem);
    width: calc(83.3333333333% - 1rem);
  }
  .dcolumns_sm_3 .dcol-sm-11 {
    width: -webkit-calc(91.6666666667% - 1rem);
    width: -moz-calc(91.6666666667% - 1rem);
    width: calc(91.6666666667% - 1rem);
  }
  .dcolumns_sm_3 .dcol-sm-12 {
    width: -webkit-calc(100% - 1rem);
    width: -moz-calc(100% - 1rem);
    width: calc(100% - 1rem);
  }
  .dcolumns_sm_4 .dcol {
    width: -webkit-calc((100% - 4.5rem)/4);
    width: -moz-calc((100% - 4.5rem)/4);
    width: calc((100% - 4.5rem)/4);
  }
  .dcolumns_sm_4 .dcol-sm-1 {
    width: -webkit-calc(8.3333333333% - 1.125rem);
    width: -moz-calc(8.3333333333% - 1.125rem);
    width: calc(8.3333333333% - 1.125rem);
  }
  .dcolumns_sm_4 .dcol-sm-2 {
    width: -webkit-calc(16.6666666667% - 1.125rem);
    width: -moz-calc(16.6666666667% - 1.125rem);
    width: calc(16.6666666667% - 1.125rem);
  }
  .dcolumns_sm_4 .dcol-sm-3 {
    width: -webkit-calc(25% - 1.125rem);
    width: -moz-calc(25% - 1.125rem);
    width: calc(25% - 1.125rem);
  }
  .dcolumns_sm_4 .dcol-sm-4 {
    width: -webkit-calc(33.3333333333% - 1.125rem);
    width: -moz-calc(33.3333333333% - 1.125rem);
    width: calc(33.3333333333% - 1.125rem);
  }
  .dcolumns_sm_4 .dcol-sm-5 {
    width: -webkit-calc(41.6666666667% - 1.125rem);
    width: -moz-calc(41.6666666667% - 1.125rem);
    width: calc(41.6666666667% - 1.125rem);
  }
  .dcolumns_sm_4 .dcol-sm-6 {
    width: -webkit-calc(50% - 1.125rem);
    width: -moz-calc(50% - 1.125rem);
    width: calc(50% - 1.125rem);
  }
  .dcolumns_sm_4 .dcol-sm-7 {
    width: -webkit-calc(58.3333333333% - 1.125rem);
    width: -moz-calc(58.3333333333% - 1.125rem);
    width: calc(58.3333333333% - 1.125rem);
  }
  .dcolumns_sm_4 .dcol-sm-8 {
    width: -webkit-calc(66.6666666667% - 1.125rem);
    width: -moz-calc(66.6666666667% - 1.125rem);
    width: calc(66.6666666667% - 1.125rem);
  }
  .dcolumns_sm_4 .dcol-sm-9 {
    width: -webkit-calc(75% - 1.125rem);
    width: -moz-calc(75% - 1.125rem);
    width: calc(75% - 1.125rem);
  }
  .dcolumns_sm_4 .dcol-sm-10 {
    width: -webkit-calc(83.3333333333% - 1.125rem);
    width: -moz-calc(83.3333333333% - 1.125rem);
    width: calc(83.3333333333% - 1.125rem);
  }
  .dcolumns_sm_4 .dcol-sm-11 {
    width: -webkit-calc(91.6666666667% - 1.125rem);
    width: -moz-calc(91.6666666667% - 1.125rem);
    width: calc(91.6666666667% - 1.125rem);
  }
  .dcolumns_sm_4 .dcol-sm-12 {
    width: -webkit-calc(100% - 1.125rem);
    width: -moz-calc(100% - 1.125rem);
    width: calc(100% - 1.125rem);
  }
  .dcolumns_sm_5 .dcol {
    width: -webkit-calc((100% - 6rem)/5);
    width: -moz-calc((100% - 6rem)/5);
    width: calc((100% - 6rem)/5);
  }
  .dcolumns_sm_5 .dcol-sm-1 {
    width: -webkit-calc(8.3333333333% - 1.2rem);
    width: -moz-calc(8.3333333333% - 1.2rem);
    width: calc(8.3333333333% - 1.2rem);
  }
  .dcolumns_sm_5 .dcol-sm-2 {
    width: -webkit-calc(16.6666666667% - 1.2rem);
    width: -moz-calc(16.6666666667% - 1.2rem);
    width: calc(16.6666666667% - 1.2rem);
  }
  .dcolumns_sm_5 .dcol-sm-3 {
    width: -webkit-calc(25% - 1.2rem);
    width: -moz-calc(25% - 1.2rem);
    width: calc(25% - 1.2rem);
  }
  .dcolumns_sm_5 .dcol-sm-4 {
    width: -webkit-calc(33.3333333333% - 1.2rem);
    width: -moz-calc(33.3333333333% - 1.2rem);
    width: calc(33.3333333333% - 1.2rem);
  }
  .dcolumns_sm_5 .dcol-sm-5 {
    width: -webkit-calc(41.6666666667% - 1.2rem);
    width: -moz-calc(41.6666666667% - 1.2rem);
    width: calc(41.6666666667% - 1.2rem);
  }
  .dcolumns_sm_5 .dcol-sm-6 {
    width: -webkit-calc(50% - 1.2rem);
    width: -moz-calc(50% - 1.2rem);
    width: calc(50% - 1.2rem);
  }
  .dcolumns_sm_5 .dcol-sm-7 {
    width: -webkit-calc(58.3333333333% - 1.2rem);
    width: -moz-calc(58.3333333333% - 1.2rem);
    width: calc(58.3333333333% - 1.2rem);
  }
  .dcolumns_sm_5 .dcol-sm-8 {
    width: -webkit-calc(66.6666666667% - 1.2rem);
    width: -moz-calc(66.6666666667% - 1.2rem);
    width: calc(66.6666666667% - 1.2rem);
  }
  .dcolumns_sm_5 .dcol-sm-9 {
    width: -webkit-calc(75% - 1.2rem);
    width: -moz-calc(75% - 1.2rem);
    width: calc(75% - 1.2rem);
  }
  .dcolumns_sm_5 .dcol-sm-10 {
    width: -webkit-calc(83.3333333333% - 1.2rem);
    width: -moz-calc(83.3333333333% - 1.2rem);
    width: calc(83.3333333333% - 1.2rem);
  }
  .dcolumns_sm_5 .dcol-sm-11 {
    width: -webkit-calc(91.6666666667% - 1.2rem);
    width: -moz-calc(91.6666666667% - 1.2rem);
    width: calc(91.6666666667% - 1.2rem);
  }
  .dcolumns_sm_5 .dcol-sm-12 {
    width: -webkit-calc(100% - 1.2rem);
    width: -moz-calc(100% - 1.2rem);
    width: calc(100% - 1.2rem);
  }
  .dcolumns_sm_6 .dcol {
    width: -webkit-calc((100% - 7.5rem)/6);
    width: -moz-calc((100% - 7.5rem)/6);
    width: calc((100% - 7.5rem)/6);
  }
  .dcolumns_sm_6 .dcol-sm-1 {
    width: -webkit-calc(8.3333333333% - 1.25rem);
    width: -moz-calc(8.3333333333% - 1.25rem);
    width: calc(8.3333333333% - 1.25rem);
  }
  .dcolumns_sm_6 .dcol-sm-2 {
    width: -webkit-calc(16.6666666667% - 1.25rem);
    width: -moz-calc(16.6666666667% - 1.25rem);
    width: calc(16.6666666667% - 1.25rem);
  }
  .dcolumns_sm_6 .dcol-sm-3 {
    width: -webkit-calc(25% - 1.25rem);
    width: -moz-calc(25% - 1.25rem);
    width: calc(25% - 1.25rem);
  }
  .dcolumns_sm_6 .dcol-sm-4 {
    width: -webkit-calc(33.3333333333% - 1.25rem);
    width: -moz-calc(33.3333333333% - 1.25rem);
    width: calc(33.3333333333% - 1.25rem);
  }
  .dcolumns_sm_6 .dcol-sm-5 {
    width: -webkit-calc(41.6666666667% - 1.25rem);
    width: -moz-calc(41.6666666667% - 1.25rem);
    width: calc(41.6666666667% - 1.25rem);
  }
  .dcolumns_sm_6 .dcol-sm-6 {
    width: -webkit-calc(50% - 1.25rem);
    width: -moz-calc(50% - 1.25rem);
    width: calc(50% - 1.25rem);
  }
  .dcolumns_sm_6 .dcol-sm-7 {
    width: -webkit-calc(58.3333333333% - 1.25rem);
    width: -moz-calc(58.3333333333% - 1.25rem);
    width: calc(58.3333333333% - 1.25rem);
  }
  .dcolumns_sm_6 .dcol-sm-8 {
    width: -webkit-calc(66.6666666667% - 1.25rem);
    width: -moz-calc(66.6666666667% - 1.25rem);
    width: calc(66.6666666667% - 1.25rem);
  }
  .dcolumns_sm_6 .dcol-sm-9 {
    width: -webkit-calc(75% - 1.25rem);
    width: -moz-calc(75% - 1.25rem);
    width: calc(75% - 1.25rem);
  }
  .dcolumns_sm_6 .dcol-sm-10 {
    width: -webkit-calc(83.3333333333% - 1.25rem);
    width: -moz-calc(83.3333333333% - 1.25rem);
    width: calc(83.3333333333% - 1.25rem);
  }
  .dcolumns_sm_6 .dcol-sm-11 {
    width: -webkit-calc(91.6666666667% - 1.25rem);
    width: -moz-calc(91.6666666667% - 1.25rem);
    width: calc(91.6666666667% - 1.25rem);
  }
  .dcolumns_sm_6 .dcol-sm-12 {
    width: -webkit-calc(100% - 1.25rem);
    width: -moz-calc(100% - 1.25rem);
    width: calc(100% - 1.25rem);
  }
  .dcolumns_sm_7 .dcol {
    width: -webkit-calc((100% - 9rem)/7);
    width: -moz-calc((100% - 9rem)/7);
    width: calc((100% - 9rem)/7);
  }
  .dcolumns_sm_7 .dcol-sm-1 {
    width: -webkit-calc(8.3333333333% - 1.2857142857rem);
    width: -moz-calc(8.3333333333% - 1.2857142857rem);
    width: calc(8.3333333333% - 1.2857142857rem);
  }
  .dcolumns_sm_7 .dcol-sm-2 {
    width: -webkit-calc(16.6666666667% - 1.2857142857rem);
    width: -moz-calc(16.6666666667% - 1.2857142857rem);
    width: calc(16.6666666667% - 1.2857142857rem);
  }
  .dcolumns_sm_7 .dcol-sm-3 {
    width: -webkit-calc(25% - 1.2857142857rem);
    width: -moz-calc(25% - 1.2857142857rem);
    width: calc(25% - 1.2857142857rem);
  }
  .dcolumns_sm_7 .dcol-sm-4 {
    width: -webkit-calc(33.3333333333% - 1.2857142857rem);
    width: -moz-calc(33.3333333333% - 1.2857142857rem);
    width: calc(33.3333333333% - 1.2857142857rem);
  }
  .dcolumns_sm_7 .dcol-sm-5 {
    width: -webkit-calc(41.6666666667% - 1.2857142857rem);
    width: -moz-calc(41.6666666667% - 1.2857142857rem);
    width: calc(41.6666666667% - 1.2857142857rem);
  }
  .dcolumns_sm_7 .dcol-sm-6 {
    width: -webkit-calc(50% - 1.2857142857rem);
    width: -moz-calc(50% - 1.2857142857rem);
    width: calc(50% - 1.2857142857rem);
  }
  .dcolumns_sm_7 .dcol-sm-7 {
    width: -webkit-calc(58.3333333333% - 1.2857142857rem);
    width: -moz-calc(58.3333333333% - 1.2857142857rem);
    width: calc(58.3333333333% - 1.2857142857rem);
  }
  .dcolumns_sm_7 .dcol-sm-8 {
    width: -webkit-calc(66.6666666667% - 1.2857142857rem);
    width: -moz-calc(66.6666666667% - 1.2857142857rem);
    width: calc(66.6666666667% - 1.2857142857rem);
  }
  .dcolumns_sm_7 .dcol-sm-9 {
    width: -webkit-calc(75% - 1.2857142857rem);
    width: -moz-calc(75% - 1.2857142857rem);
    width: calc(75% - 1.2857142857rem);
  }
  .dcolumns_sm_7 .dcol-sm-10 {
    width: -webkit-calc(83.3333333333% - 1.2857142857rem);
    width: -moz-calc(83.3333333333% - 1.2857142857rem);
    width: calc(83.3333333333% - 1.2857142857rem);
  }
  .dcolumns_sm_7 .dcol-sm-11 {
    width: -webkit-calc(91.6666666667% - 1.2857142857rem);
    width: -moz-calc(91.6666666667% - 1.2857142857rem);
    width: calc(91.6666666667% - 1.2857142857rem);
  }
  .dcolumns_sm_7 .dcol-sm-12 {
    width: -webkit-calc(100% - 1.2857142857rem);
    width: -moz-calc(100% - 1.2857142857rem);
    width: calc(100% - 1.2857142857rem);
  }
  .dcolumns_sm_8 .dcol {
    width: -webkit-calc((100% - 10.5rem)/8);
    width: -moz-calc((100% - 10.5rem)/8);
    width: calc((100% - 10.5rem)/8);
  }
  .dcolumns_sm_8 .dcol-sm-1 {
    width: -webkit-calc(8.3333333333% - 1.3125rem);
    width: -moz-calc(8.3333333333% - 1.3125rem);
    width: calc(8.3333333333% - 1.3125rem);
  }
  .dcolumns_sm_8 .dcol-sm-2 {
    width: -webkit-calc(16.6666666667% - 1.3125rem);
    width: -moz-calc(16.6666666667% - 1.3125rem);
    width: calc(16.6666666667% - 1.3125rem);
  }
  .dcolumns_sm_8 .dcol-sm-3 {
    width: -webkit-calc(25% - 1.3125rem);
    width: -moz-calc(25% - 1.3125rem);
    width: calc(25% - 1.3125rem);
  }
  .dcolumns_sm_8 .dcol-sm-4 {
    width: -webkit-calc(33.3333333333% - 1.3125rem);
    width: -moz-calc(33.3333333333% - 1.3125rem);
    width: calc(33.3333333333% - 1.3125rem);
  }
  .dcolumns_sm_8 .dcol-sm-5 {
    width: -webkit-calc(41.6666666667% - 1.3125rem);
    width: -moz-calc(41.6666666667% - 1.3125rem);
    width: calc(41.6666666667% - 1.3125rem);
  }
  .dcolumns_sm_8 .dcol-sm-6 {
    width: -webkit-calc(50% - 1.3125rem);
    width: -moz-calc(50% - 1.3125rem);
    width: calc(50% - 1.3125rem);
  }
  .dcolumns_sm_8 .dcol-sm-7 {
    width: -webkit-calc(58.3333333333% - 1.3125rem);
    width: -moz-calc(58.3333333333% - 1.3125rem);
    width: calc(58.3333333333% - 1.3125rem);
  }
  .dcolumns_sm_8 .dcol-sm-8 {
    width: -webkit-calc(66.6666666667% - 1.3125rem);
    width: -moz-calc(66.6666666667% - 1.3125rem);
    width: calc(66.6666666667% - 1.3125rem);
  }
  .dcolumns_sm_8 .dcol-sm-9 {
    width: -webkit-calc(75% - 1.3125rem);
    width: -moz-calc(75% - 1.3125rem);
    width: calc(75% - 1.3125rem);
  }
  .dcolumns_sm_8 .dcol-sm-10 {
    width: -webkit-calc(83.3333333333% - 1.3125rem);
    width: -moz-calc(83.3333333333% - 1.3125rem);
    width: calc(83.3333333333% - 1.3125rem);
  }
  .dcolumns_sm_8 .dcol-sm-11 {
    width: -webkit-calc(91.6666666667% - 1.3125rem);
    width: -moz-calc(91.6666666667% - 1.3125rem);
    width: calc(91.6666666667% - 1.3125rem);
  }
  .dcolumns_sm_8 .dcol-sm-12 {
    width: -webkit-calc(100% - 1.3125rem);
    width: -moz-calc(100% - 1.3125rem);
    width: calc(100% - 1.3125rem);
  }
  .dcolumns_sm_9 .dcol {
    width: -webkit-calc((100% - 12rem)/9);
    width: -moz-calc((100% - 12rem)/9);
    width: calc((100% - 12rem)/9);
  }
  .dcolumns_sm_9 .dcol-sm-1 {
    width: -webkit-calc(8.3333333333% - 1.3333333333rem);
    width: -moz-calc(8.3333333333% - 1.3333333333rem);
    width: calc(8.3333333333% - 1.3333333333rem);
  }
  .dcolumns_sm_9 .dcol-sm-2 {
    width: -webkit-calc(16.6666666667% - 1.3333333333rem);
    width: -moz-calc(16.6666666667% - 1.3333333333rem);
    width: calc(16.6666666667% - 1.3333333333rem);
  }
  .dcolumns_sm_9 .dcol-sm-3 {
    width: -webkit-calc(25% - 1.3333333333rem);
    width: -moz-calc(25% - 1.3333333333rem);
    width: calc(25% - 1.3333333333rem);
  }
  .dcolumns_sm_9 .dcol-sm-4 {
    width: -webkit-calc(33.3333333333% - 1.3333333333rem);
    width: -moz-calc(33.3333333333% - 1.3333333333rem);
    width: calc(33.3333333333% - 1.3333333333rem);
  }
  .dcolumns_sm_9 .dcol-sm-5 {
    width: -webkit-calc(41.6666666667% - 1.3333333333rem);
    width: -moz-calc(41.6666666667% - 1.3333333333rem);
    width: calc(41.6666666667% - 1.3333333333rem);
  }
  .dcolumns_sm_9 .dcol-sm-6 {
    width: -webkit-calc(50% - 1.3333333333rem);
    width: -moz-calc(50% - 1.3333333333rem);
    width: calc(50% - 1.3333333333rem);
  }
  .dcolumns_sm_9 .dcol-sm-7 {
    width: -webkit-calc(58.3333333333% - 1.3333333333rem);
    width: -moz-calc(58.3333333333% - 1.3333333333rem);
    width: calc(58.3333333333% - 1.3333333333rem);
  }
  .dcolumns_sm_9 .dcol-sm-8 {
    width: -webkit-calc(66.6666666667% - 1.3333333333rem);
    width: -moz-calc(66.6666666667% - 1.3333333333rem);
    width: calc(66.6666666667% - 1.3333333333rem);
  }
  .dcolumns_sm_9 .dcol-sm-9 {
    width: -webkit-calc(75% - 1.3333333333rem);
    width: -moz-calc(75% - 1.3333333333rem);
    width: calc(75% - 1.3333333333rem);
  }
  .dcolumns_sm_9 .dcol-sm-10 {
    width: -webkit-calc(83.3333333333% - 1.3333333333rem);
    width: -moz-calc(83.3333333333% - 1.3333333333rem);
    width: calc(83.3333333333% - 1.3333333333rem);
  }
  .dcolumns_sm_9 .dcol-sm-11 {
    width: -webkit-calc(91.6666666667% - 1.3333333333rem);
    width: -moz-calc(91.6666666667% - 1.3333333333rem);
    width: calc(91.6666666667% - 1.3333333333rem);
  }
  .dcolumns_sm_9 .dcol-sm-12 {
    width: -webkit-calc(100% - 1.3333333333rem);
    width: -moz-calc(100% - 1.3333333333rem);
    width: calc(100% - 1.3333333333rem);
  }
  .dcolumns_sm_10 .dcol {
    width: -webkit-calc((100% - 13.5rem)/10);
    width: -moz-calc((100% - 13.5rem)/10);
    width: calc((100% - 13.5rem)/10);
  }
  .dcolumns_sm_10 .dcol-sm-1 {
    width: -webkit-calc(8.3333333333% - 1.35rem);
    width: -moz-calc(8.3333333333% - 1.35rem);
    width: calc(8.3333333333% - 1.35rem);
  }
  .dcolumns_sm_10 .dcol-sm-2 {
    width: -webkit-calc(16.6666666667% - 1.35rem);
    width: -moz-calc(16.6666666667% - 1.35rem);
    width: calc(16.6666666667% - 1.35rem);
  }
  .dcolumns_sm_10 .dcol-sm-3 {
    width: -webkit-calc(25% - 1.35rem);
    width: -moz-calc(25% - 1.35rem);
    width: calc(25% - 1.35rem);
  }
  .dcolumns_sm_10 .dcol-sm-4 {
    width: -webkit-calc(33.3333333333% - 1.35rem);
    width: -moz-calc(33.3333333333% - 1.35rem);
    width: calc(33.3333333333% - 1.35rem);
  }
  .dcolumns_sm_10 .dcol-sm-5 {
    width: -webkit-calc(41.6666666667% - 1.35rem);
    width: -moz-calc(41.6666666667% - 1.35rem);
    width: calc(41.6666666667% - 1.35rem);
  }
  .dcolumns_sm_10 .dcol-sm-6 {
    width: -webkit-calc(50% - 1.35rem);
    width: -moz-calc(50% - 1.35rem);
    width: calc(50% - 1.35rem);
  }
  .dcolumns_sm_10 .dcol-sm-7 {
    width: -webkit-calc(58.3333333333% - 1.35rem);
    width: -moz-calc(58.3333333333% - 1.35rem);
    width: calc(58.3333333333% - 1.35rem);
  }
  .dcolumns_sm_10 .dcol-sm-8 {
    width: -webkit-calc(66.6666666667% - 1.35rem);
    width: -moz-calc(66.6666666667% - 1.35rem);
    width: calc(66.6666666667% - 1.35rem);
  }
  .dcolumns_sm_10 .dcol-sm-9 {
    width: -webkit-calc(75% - 1.35rem);
    width: -moz-calc(75% - 1.35rem);
    width: calc(75% - 1.35rem);
  }
  .dcolumns_sm_10 .dcol-sm-10 {
    width: -webkit-calc(83.3333333333% - 1.35rem);
    width: -moz-calc(83.3333333333% - 1.35rem);
    width: calc(83.3333333333% - 1.35rem);
  }
  .dcolumns_sm_10 .dcol-sm-11 {
    width: -webkit-calc(91.6666666667% - 1.35rem);
    width: -moz-calc(91.6666666667% - 1.35rem);
    width: calc(91.6666666667% - 1.35rem);
  }
  .dcolumns_sm_10 .dcol-sm-12 {
    width: -webkit-calc(100% - 1.35rem);
    width: -moz-calc(100% - 1.35rem);
    width: calc(100% - 1.35rem);
  }
  .dcolumns_sm_11 .dcol {
    width: -webkit-calc((100% - 15rem)/11);
    width: -moz-calc((100% - 15rem)/11);
    width: calc((100% - 15rem)/11);
  }
  .dcolumns_sm_11 .dcol-sm-1 {
    width: -webkit-calc(8.3333333333% - 1.3636363636rem);
    width: -moz-calc(8.3333333333% - 1.3636363636rem);
    width: calc(8.3333333333% - 1.3636363636rem);
  }
  .dcolumns_sm_11 .dcol-sm-2 {
    width: -webkit-calc(16.6666666667% - 1.3636363636rem);
    width: -moz-calc(16.6666666667% - 1.3636363636rem);
    width: calc(16.6666666667% - 1.3636363636rem);
  }
  .dcolumns_sm_11 .dcol-sm-3 {
    width: -webkit-calc(25% - 1.3636363636rem);
    width: -moz-calc(25% - 1.3636363636rem);
    width: calc(25% - 1.3636363636rem);
  }
  .dcolumns_sm_11 .dcol-sm-4 {
    width: -webkit-calc(33.3333333333% - 1.3636363636rem);
    width: -moz-calc(33.3333333333% - 1.3636363636rem);
    width: calc(33.3333333333% - 1.3636363636rem);
  }
  .dcolumns_sm_11 .dcol-sm-5 {
    width: -webkit-calc(41.6666666667% - 1.3636363636rem);
    width: -moz-calc(41.6666666667% - 1.3636363636rem);
    width: calc(41.6666666667% - 1.3636363636rem);
  }
  .dcolumns_sm_11 .dcol-sm-6 {
    width: -webkit-calc(50% - 1.3636363636rem);
    width: -moz-calc(50% - 1.3636363636rem);
    width: calc(50% - 1.3636363636rem);
  }
  .dcolumns_sm_11 .dcol-sm-7 {
    width: -webkit-calc(58.3333333333% - 1.3636363636rem);
    width: -moz-calc(58.3333333333% - 1.3636363636rem);
    width: calc(58.3333333333% - 1.3636363636rem);
  }
  .dcolumns_sm_11 .dcol-sm-8 {
    width: -webkit-calc(66.6666666667% - 1.3636363636rem);
    width: -moz-calc(66.6666666667% - 1.3636363636rem);
    width: calc(66.6666666667% - 1.3636363636rem);
  }
  .dcolumns_sm_11 .dcol-sm-9 {
    width: -webkit-calc(75% - 1.3636363636rem);
    width: -moz-calc(75% - 1.3636363636rem);
    width: calc(75% - 1.3636363636rem);
  }
  .dcolumns_sm_11 .dcol-sm-10 {
    width: -webkit-calc(83.3333333333% - 1.3636363636rem);
    width: -moz-calc(83.3333333333% - 1.3636363636rem);
    width: calc(83.3333333333% - 1.3636363636rem);
  }
  .dcolumns_sm_11 .dcol-sm-11 {
    width: -webkit-calc(91.6666666667% - 1.3636363636rem);
    width: -moz-calc(91.6666666667% - 1.3636363636rem);
    width: calc(91.6666666667% - 1.3636363636rem);
  }
  .dcolumns_sm_11 .dcol-sm-12 {
    width: -webkit-calc(100% - 1.3636363636rem);
    width: -moz-calc(100% - 1.3636363636rem);
    width: calc(100% - 1.3636363636rem);
  }
  .dcolumns_sm_12 .dcol {
    width: -webkit-calc((100% - 16.5rem)/12);
    width: -moz-calc((100% - 16.5rem)/12);
    width: calc((100% - 16.5rem)/12);
  }
  .dcolumns_sm_12 .dcol-sm-1 {
    width: -webkit-calc(8.3333333333% - 1.375rem);
    width: -moz-calc(8.3333333333% - 1.375rem);
    width: calc(8.3333333333% - 1.375rem);
  }
  .dcolumns_sm_12 .dcol-sm-2 {
    width: -webkit-calc(16.6666666667% - 1.375rem);
    width: -moz-calc(16.6666666667% - 1.375rem);
    width: calc(16.6666666667% - 1.375rem);
  }
  .dcolumns_sm_12 .dcol-sm-3 {
    width: -webkit-calc(25% - 1.375rem);
    width: -moz-calc(25% - 1.375rem);
    width: calc(25% - 1.375rem);
  }
  .dcolumns_sm_12 .dcol-sm-4 {
    width: -webkit-calc(33.3333333333% - 1.375rem);
    width: -moz-calc(33.3333333333% - 1.375rem);
    width: calc(33.3333333333% - 1.375rem);
  }
  .dcolumns_sm_12 .dcol-sm-5 {
    width: -webkit-calc(41.6666666667% - 1.375rem);
    width: -moz-calc(41.6666666667% - 1.375rem);
    width: calc(41.6666666667% - 1.375rem);
  }
  .dcolumns_sm_12 .dcol-sm-6 {
    width: -webkit-calc(50% - 1.375rem);
    width: -moz-calc(50% - 1.375rem);
    width: calc(50% - 1.375rem);
  }
  .dcolumns_sm_12 .dcol-sm-7 {
    width: -webkit-calc(58.3333333333% - 1.375rem);
    width: -moz-calc(58.3333333333% - 1.375rem);
    width: calc(58.3333333333% - 1.375rem);
  }
  .dcolumns_sm_12 .dcol-sm-8 {
    width: -webkit-calc(66.6666666667% - 1.375rem);
    width: -moz-calc(66.6666666667% - 1.375rem);
    width: calc(66.6666666667% - 1.375rem);
  }
  .dcolumns_sm_12 .dcol-sm-9 {
    width: -webkit-calc(75% - 1.375rem);
    width: -moz-calc(75% - 1.375rem);
    width: calc(75% - 1.375rem);
  }
  .dcolumns_sm_12 .dcol-sm-10 {
    width: -webkit-calc(83.3333333333% - 1.375rem);
    width: -moz-calc(83.3333333333% - 1.375rem);
    width: calc(83.3333333333% - 1.375rem);
  }
  .dcolumns_sm_12 .dcol-sm-11 {
    width: -webkit-calc(91.6666666667% - 1.375rem);
    width: -moz-calc(91.6666666667% - 1.375rem);
    width: calc(91.6666666667% - 1.375rem);
  }
  .dcolumns_sm_12 .dcol-sm-12 {
    width: -webkit-calc(100% - 1.375rem);
    width: -moz-calc(100% - 1.375rem);
    width: calc(100% - 1.375rem);
  }
}
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.container::after {
  content: "";
  display: table;
  clear: both;
}
@media (min-width: 576px) {
  .container {
    width: 540px;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1140px;
    max-width: 100%;
  }
}

.container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.container-fluid::after {
  content: "";
  display: table;
  clear: both;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}
.row::after {
  content: "";
  display: table;
  clear: both;
}
@media (min-width: 576px) {
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media (min-width: 768px) {
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media (min-width: 992px) {
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media (min-width: 1200px) {
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-xl, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-lg, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-md, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col-sm, .col-xs-12, .col-xs-11, .col-xs-10, .col-xs-9, .col-xs-8, .col-xs-7, .col-xs-6, .col-xs-5, .col-xs-4, .col-xs-3, .col-xs-2, .col-xs-1, .col-xs {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 576px) {
  .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-xl, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-lg, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-md, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col-sm, .col-xs-12, .col-xs-11, .col-xs-10, .col-xs-9, .col-xs-8, .col-xs-7, .col-xs-6, .col-xs-5, .col-xs-4, .col-xs-3, .col-xs-2, .col-xs-1, .col-xs {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-xl, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-lg, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-md, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col-sm, .col-xs-12, .col-xs-11, .col-xs-10, .col-xs-9, .col-xs-8, .col-xs-7, .col-xs-6, .col-xs-5, .col-xs-4, .col-xs-3, .col-xs-2, .col-xs-1, .col-xs {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 992px) {
  .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-xl, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-lg, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-md, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col-sm, .col-xs-12, .col-xs-11, .col-xs-10, .col-xs-9, .col-xs-8, .col-xs-7, .col-xs-6, .col-xs-5, .col-xs-4, .col-xs-3, .col-xs-2, .col-xs-1, .col-xs {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 1200px) {
  .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-xl, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-lg, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-md, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col-sm, .col-xs-12, .col-xs-11, .col-xs-10, .col-xs-9, .col-xs-8, .col-xs-7, .col-xs-6, .col-xs-5, .col-xs-4, .col-xs-3, .col-xs-2, .col-xs-1, .col-xs {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.col-xs-1 {
  float: left;
  width: 8.3333333333%;
}

.col-xs-2 {
  float: left;
  width: 16.6666666667%;
}

.col-xs-3 {
  float: left;
  width: 25%;
}

.col-xs-4 {
  float: left;
  width: 33.3333333333%;
}

.col-xs-5 {
  float: left;
  width: 41.6666666667%;
}

.col-xs-6 {
  float: left;
  width: 50%;
}

.col-xs-7 {
  float: left;
  width: 58.3333333333%;
}

.col-xs-8 {
  float: left;
  width: 66.6666666667%;
}

.col-xs-9 {
  float: left;
  width: 75%;
}

.col-xs-10 {
  float: left;
  width: 83.3333333333%;
}

.col-xs-11 {
  float: left;
  width: 91.6666666667%;
}

.col-xs-12 {
  float: left;
  width: 100%;
}

.pull-xs-0 {
  right: auto;
}

.pull-xs-1 {
  right: 8.3333333333%;
}

.pull-xs-2 {
  right: 16.6666666667%;
}

.pull-xs-3 {
  right: 25%;
}

.pull-xs-4 {
  right: 33.3333333333%;
}

.pull-xs-5 {
  right: 41.6666666667%;
}

.pull-xs-6 {
  right: 50%;
}

.pull-xs-7 {
  right: 58.3333333333%;
}

.pull-xs-8 {
  right: 66.6666666667%;
}

.pull-xs-9 {
  right: 75%;
}

.pull-xs-10 {
  right: 83.3333333333%;
}

.pull-xs-11 {
  right: 91.6666666667%;
}

.pull-xs-12 {
  right: 100%;
}

.push-xs-0 {
  left: auto;
}

.push-xs-1 {
  left: 8.3333333333%;
}

.push-xs-2 {
  left: 16.6666666667%;
}

.push-xs-3 {
  left: 25%;
}

.push-xs-4 {
  left: 33.3333333333%;
}

.push-xs-5 {
  left: 41.6666666667%;
}

.push-xs-6 {
  left: 50%;
}

.push-xs-7 {
  left: 58.3333333333%;
}

.push-xs-8 {
  left: 66.6666666667%;
}

.push-xs-9 {
  left: 75%;
}

.push-xs-10 {
  left: 83.3333333333%;
}

.push-xs-11 {
  left: 91.6666666667%;
}

.push-xs-12 {
  left: 100%;
}

.offset-xs-1 {
  margin-left: 8.3333333333%;
}

.offset-xs-2 {
  margin-left: 16.6666666667%;
}

.offset-xs-3 {
  margin-left: 25%;
}

.offset-xs-4 {
  margin-left: 33.3333333333%;
}

.offset-xs-5 {
  margin-left: 41.6666666667%;
}

.offset-xs-6 {
  margin-left: 50%;
}

.offset-xs-7 {
  margin-left: 58.3333333333%;
}

.offset-xs-8 {
  margin-left: 66.6666666667%;
}

.offset-xs-9 {
  margin-left: 75%;
}

.offset-xs-10 {
  margin-left: 83.3333333333%;
}

.offset-xs-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm-1 {
    float: left;
    width: 8.3333333333%;
  }

  .col-sm-2 {
    float: left;
    width: 16.6666666667%;
  }

  .col-sm-3 {
    float: left;
    width: 25%;
  }

  .col-sm-4 {
    float: left;
    width: 33.3333333333%;
  }

  .col-sm-5 {
    float: left;
    width: 41.6666666667%;
  }

  .col-sm-6 {
    float: left;
    width: 50%;
  }

  .col-sm-7 {
    float: left;
    width: 58.3333333333%;
  }

  .col-sm-8 {
    float: left;
    width: 66.6666666667%;
  }

  .col-sm-9 {
    float: left;
    width: 75%;
  }

  .col-sm-10 {
    float: left;
    width: 83.3333333333%;
  }

  .col-sm-11 {
    float: left;
    width: 91.6666666667%;
  }

  .col-sm-12 {
    float: left;
    width: 100%;
  }

  .pull-sm-0 {
    right: auto;
  }

  .pull-sm-1 {
    right: 8.3333333333%;
  }

  .pull-sm-2 {
    right: 16.6666666667%;
  }

  .pull-sm-3 {
    right: 25%;
  }

  .pull-sm-4 {
    right: 33.3333333333%;
  }

  .pull-sm-5 {
    right: 41.6666666667%;
  }

  .pull-sm-6 {
    right: 50%;
  }

  .pull-sm-7 {
    right: 58.3333333333%;
  }

  .pull-sm-8 {
    right: 66.6666666667%;
  }

  .pull-sm-9 {
    right: 75%;
  }

  .pull-sm-10 {
    right: 83.3333333333%;
  }

  .pull-sm-11 {
    right: 91.6666666667%;
  }

  .pull-sm-12 {
    right: 100%;
  }

  .push-sm-0 {
    left: auto;
  }

  .push-sm-1 {
    left: 8.3333333333%;
  }

  .push-sm-2 {
    left: 16.6666666667%;
  }

  .push-sm-3 {
    left: 25%;
  }

  .push-sm-4 {
    left: 33.3333333333%;
  }

  .push-sm-5 {
    left: 41.6666666667%;
  }

  .push-sm-6 {
    left: 50%;
  }

  .push-sm-7 {
    left: 58.3333333333%;
  }

  .push-sm-8 {
    left: 66.6666666667%;
  }

  .push-sm-9 {
    left: 75%;
  }

  .push-sm-10 {
    left: 83.3333333333%;
  }

  .push-sm-11 {
    left: 91.6666666667%;
  }

  .push-sm-12 {
    left: 100%;
  }

  .offset-sm-0 {
    margin-left: 0%;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md-1 {
    float: left;
    width: 8.3333333333%;
  }

  .col-md-2 {
    float: left;
    width: 16.6666666667%;
  }

  .col-md-3 {
    float: left;
    width: 25%;
  }

  .col-md-4 {
    float: left;
    width: 33.3333333333%;
  }

  .col-md-5 {
    float: left;
    width: 41.6666666667%;
  }

  .col-md-6 {
    float: left;
    width: 50%;
  }

  .col-md-7 {
    float: left;
    width: 58.3333333333%;
  }

  .col-md-8 {
    float: left;
    width: 66.6666666667%;
  }

  .col-md-9 {
    float: left;
    width: 75%;
  }

  .col-md-10 {
    float: left;
    width: 83.3333333333%;
  }

  .col-md-11 {
    float: left;
    width: 91.6666666667%;
  }

  .col-md-12 {
    float: left;
    width: 100%;
  }

  .pull-md-0 {
    right: auto;
  }

  .pull-md-1 {
    right: 8.3333333333%;
  }

  .pull-md-2 {
    right: 16.6666666667%;
  }

  .pull-md-3 {
    right: 25%;
  }

  .pull-md-4 {
    right: 33.3333333333%;
  }

  .pull-md-5 {
    right: 41.6666666667%;
  }

  .pull-md-6 {
    right: 50%;
  }

  .pull-md-7 {
    right: 58.3333333333%;
  }

  .pull-md-8 {
    right: 66.6666666667%;
  }

  .pull-md-9 {
    right: 75%;
  }

  .pull-md-10 {
    right: 83.3333333333%;
  }

  .pull-md-11 {
    right: 91.6666666667%;
  }

  .pull-md-12 {
    right: 100%;
  }

  .push-md-0 {
    left: auto;
  }

  .push-md-1 {
    left: 8.3333333333%;
  }

  .push-md-2 {
    left: 16.6666666667%;
  }

  .push-md-3 {
    left: 25%;
  }

  .push-md-4 {
    left: 33.3333333333%;
  }

  .push-md-5 {
    left: 41.6666666667%;
  }

  .push-md-6 {
    left: 50%;
  }

  .push-md-7 {
    left: 58.3333333333%;
  }

  .push-md-8 {
    left: 66.6666666667%;
  }

  .push-md-9 {
    left: 75%;
  }

  .push-md-10 {
    left: 83.3333333333%;
  }

  .push-md-11 {
    left: 91.6666666667%;
  }

  .push-md-12 {
    left: 100%;
  }

  .offset-md-0 {
    margin-left: 0%;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg-1 {
    float: left;
    width: 8.3333333333%;
  }

  .col-lg-2 {
    float: left;
    width: 16.6666666667%;
  }

  .col-lg-3 {
    float: left;
    width: 25%;
  }

  .col-lg-4 {
    float: left;
    width: 33.3333333333%;
  }

  .col-lg-5 {
    float: left;
    width: 41.6666666667%;
  }

  .col-lg-6 {
    float: left;
    width: 50%;
  }

  .col-lg-7 {
    float: left;
    width: 58.3333333333%;
  }

  .col-lg-8 {
    float: left;
    width: 66.6666666667%;
  }

  .col-lg-9 {
    float: left;
    width: 75%;
  }

  .col-lg-10 {
    float: left;
    width: 83.3333333333%;
  }

  .col-lg-11 {
    float: left;
    width: 91.6666666667%;
  }

  .col-lg-12 {
    float: left;
    width: 100%;
  }

  .pull-lg-0 {
    right: auto;
  }

  .pull-lg-1 {
    right: 8.3333333333%;
  }

  .pull-lg-2 {
    right: 16.6666666667%;
  }

  .pull-lg-3 {
    right: 25%;
  }

  .pull-lg-4 {
    right: 33.3333333333%;
  }

  .pull-lg-5 {
    right: 41.6666666667%;
  }

  .pull-lg-6 {
    right: 50%;
  }

  .pull-lg-7 {
    right: 58.3333333333%;
  }

  .pull-lg-8 {
    right: 66.6666666667%;
  }

  .pull-lg-9 {
    right: 75%;
  }

  .pull-lg-10 {
    right: 83.3333333333%;
  }

  .pull-lg-11 {
    right: 91.6666666667%;
  }

  .pull-lg-12 {
    right: 100%;
  }

  .push-lg-0 {
    left: auto;
  }

  .push-lg-1 {
    left: 8.3333333333%;
  }

  .push-lg-2 {
    left: 16.6666666667%;
  }

  .push-lg-3 {
    left: 25%;
  }

  .push-lg-4 {
    left: 33.3333333333%;
  }

  .push-lg-5 {
    left: 41.6666666667%;
  }

  .push-lg-6 {
    left: 50%;
  }

  .push-lg-7 {
    left: 58.3333333333%;
  }

  .push-lg-8 {
    left: 66.6666666667%;
  }

  .push-lg-9 {
    left: 75%;
  }

  .push-lg-10 {
    left: 83.3333333333%;
  }

  .push-lg-11 {
    left: 91.6666666667%;
  }

  .push-lg-12 {
    left: 100%;
  }

  .offset-lg-0 {
    margin-left: 0%;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl-1 {
    float: left;
    width: 8.3333333333%;
  }

  .col-xl-2 {
    float: left;
    width: 16.6666666667%;
  }

  .col-xl-3 {
    float: left;
    width: 25%;
  }

  .col-xl-4 {
    float: left;
    width: 33.3333333333%;
  }

  .col-xl-5 {
    float: left;
    width: 41.6666666667%;
  }

  .col-xl-6 {
    float: left;
    width: 50%;
  }

  .col-xl-7 {
    float: left;
    width: 58.3333333333%;
  }

  .col-xl-8 {
    float: left;
    width: 66.6666666667%;
  }

  .col-xl-9 {
    float: left;
    width: 75%;
  }

  .col-xl-10 {
    float: left;
    width: 83.3333333333%;
  }

  .col-xl-11 {
    float: left;
    width: 91.6666666667%;
  }

  .col-xl-12 {
    float: left;
    width: 100%;
  }

  .pull-xl-0 {
    right: auto;
  }

  .pull-xl-1 {
    right: 8.3333333333%;
  }

  .pull-xl-2 {
    right: 16.6666666667%;
  }

  .pull-xl-3 {
    right: 25%;
  }

  .pull-xl-4 {
    right: 33.3333333333%;
  }

  .pull-xl-5 {
    right: 41.6666666667%;
  }

  .pull-xl-6 {
    right: 50%;
  }

  .pull-xl-7 {
    right: 58.3333333333%;
  }

  .pull-xl-8 {
    right: 66.6666666667%;
  }

  .pull-xl-9 {
    right: 75%;
  }

  .pull-xl-10 {
    right: 83.3333333333%;
  }

  .pull-xl-11 {
    right: 91.6666666667%;
  }

  .pull-xl-12 {
    right: 100%;
  }

  .push-xl-0 {
    left: auto;
  }

  .push-xl-1 {
    left: 8.3333333333%;
  }

  .push-xl-2 {
    left: 16.6666666667%;
  }

  .push-xl-3 {
    left: 25%;
  }

  .push-xl-4 {
    left: 33.3333333333%;
  }

  .push-xl-5 {
    left: 41.6666666667%;
  }

  .push-xl-6 {
    left: 50%;
  }

  .push-xl-7 {
    left: 58.3333333333%;
  }

  .push-xl-8 {
    left: 66.6666666667%;
  }

  .push-xl-9 {
    left: 75%;
  }

  .push-xl-10 {
    left: 83.3333333333%;
  }

  .push-xl-11 {
    left: 91.6666666667%;
  }

  .push-xl-12 {
    left: 100%;
  }

  .offset-xl-0 {
    margin-left: 0%;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* ------------------------------------------------------------
*
common parts
*
------------------------------------------------------------ */
body, html {
  height: 100%;
  font-family: "Shippori Mincho", serif;
}

body {
  background-color: #FFF;
  color: #333;
}

*,
::after,
::before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

#wrapper {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

a {
  color: #FFF;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
a:hover {
  color: #F00;
}
a:hover img,
a:hover i {
  opacity: 0.6;
}

.flwrap {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
}

.flcenter {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.scap {
  font-size: 0.6rem;
}

.cap {
  font-size: 0.94rem;
}

.caution {
  font-size: 0.9em;
}

.vbtm {
  vertical-align: bottom;
}

.vmid {
  vertical-align: middle;
}

.nobd {
  border: none !important;
}

ul:after {
  content: "";
  display: block;
  clear: both;
}

.inner {
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 986px;
  box-sizing: border-box;
}

br.sp {
  display: none;
}
br.pc {
  display: block;
}

.switch_pc {
  display: block;
}

.switch_sp {
  display: none;
}

img {
  max-width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  vertical-align: middle;
}

.center {
  text-align: center;
}

.btn {
  margin: 0 auto;
  width: 260px;
}
.btn a {
  display: block;
  padding: 0.55rem 0;
  background: #1a4fa3;
  color: #FFF;
  font-size: 1.2rem;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  line-height: 1.6;
}
.btn a:hover {
  opacity: 0.7;
}

.btn-outline {
  display: inline-block;
}
.btn-outline a {
  padding: 0.49rem 2.2rem;
  border-radius: 10px;
  font-size: 0.9rem;
}
.btn-outline a:hover {
  text-decoration: none;
}

.btn-cat a {
  width: 135px;
  border-radius: 5px;
  font-size: 0.8rem;
}
.btn-outline a, .btn-cat a {
  display: block;
  border: 1px solid #1a4fa3;
  background-color: transparent;
  color: #1a4fa3;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  line-height: 1.6;
}

.btn-outline a:hover, .btn-cat a:hover {
  opacity: 0.7;
  color: #1a4fa3;
  text-decoration: none;
}

.btn_wrap {
  text-align: center;
}

/* ------------------------------------------------------------
*
header
*
------------------------------------------------------------ */
#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 2.5rem;
  background-color: rgba(26, 79, 163, 0.9);
  font-family: "Noto Sans JP", sans-serif;
  z-index: 2;
  -webkit-transition: top 0.3s;
  -moz-transition: top 0.3s;
  -o-transition: top 0.3s;
  transition: top 0.3s;
}
#header .inner {
  padding: 0;
}

#gnav {
  padding: 0.3rem 0;
}
#gnav:after {
  content: "";
  display: block;
  clear: both;
}
#gnav .h_sns {
  float: right;
}
#gnav .h_sns li {
  margin-bottom: 0;
  line-height: 1;
  font-weight: normal;
}
#gnav .h_sns li a {
  padding: 0 1rem;
  color: #FFF;
  font-size: 1.88rem;
}
#gnav li {
  float: left;
  margin-bottom: 0;
  font-size: 1.13rem;
  font-weight: bold;
  line-height: 1;
}
#gnav li a {
  display: block;
  padding: 0.4rem 1rem;
  text-decoration: none;
  color: #FFF;
  font-family: "Noto Sans JP", sans-serif;
}

/* ------------------------------------------------------------
*
common 
*
------------------------------------------------------------ */
.tit_wrp {
  position: relative;
  margin-bottom: 3rem;
}
.tit_wrp .title {
  display: block;
  margin-bottom: 0;
  padding: 0;
  height: auto;
  color: #fff500;
  font-size: 6rem;
  font-family: "Pinyon Script", cursive;
  font-weight: normal;
}
.tit_wrp .title:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  width: 100%;
  height: 73px;
  border-bottom: 2px solid #fff500;
  box-sizing: border-box;
  clear: both;
}
.tit_wrp .title_jp {
  position: absolute;
  bottom: -2px;
  left: 0;
  margin: 0;
  text-align: left;
  color: #000;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.3;
}

.color_tit {
  margin-bottom: 2.4rem;
  color: #1a4fa3;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.46;
}

.bg {
  background-color: #1a4fa3;
}

.bg_2 {
  background-color: #fff500;
}

.button-sns {
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  top: 50%;
  left: 0;
  padding: 18px 0 20px;
  width: 32px;
  background-color: rgba(255, 255, 255, 0.9);
  color: #555;
  font-family: "Shippori Mincho", serif;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  transform: translateY(-50%);
  z-index: 9;
}
.button-sns a {
  display: -ms-flexbox;
  display: flex;
  color: #555;
  white-space: nowrap;
  text-decoration: none;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  transition: opacity 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  transform: rotate(90deg);
}
.button-sns a i {
  font-size: 1.25rem;
}
.button-sns a svg {
  font-size: 1.25rem !important;
}
.button-sns a.ico_twit {
  margin-top: 0.8rem;
}
.button-sns a.ico_insta {
  margin-top: 0.6rem;
}

.button-sns__text,
.button-share__text {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  font-size: 1.13rem;
  line-height: 1;
  letter-spacing: 0.1em;
}

.button-share {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  margin-top: 20px;
  padding-top: 20px;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
.button-share .list-share {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  z-index: 2;
  display: block;
  position: absolute;
  left: 100%;
  top: calc(100% - 2.2rem);
  margin-top: 0;
  padding-left: 12px;
}
.button-share.on .list-share {
  opacity: 1;
  pointer-events: auto;
}

.button-share__icon {
  margin-top: 0.7rem;
  font-size: 1.25rem;
  transform: rotate(90deg);
}

.list-share__item {
  line-height: 0;
  margin-left: 0.2rem;
}
.list-share__item:nth-of-type(2) {
  margin-top: 0.5rem;
  margin-left: 0;
}

.th {
  padding: 3.2rem 0 2.5rem;
  text-align: center;
  font-size: 0.88rem;
  color: #333;
}
.th .pff {
  margin-bottom: 1.2rem;
  font-size: 1rem;
  font-weight: normal;
}
.th .director {
  margin-bottom: 0.3rem;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.6;
}
.th .actor {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0 0 0.4rem;
}
.th .other, .th .billing {
  font-size: 0.88rem;
  font-weight: normal;
  line-height: 1.4;
  margin-bottom: 1.2rem;
}
.th .billing {
  margin-bottom: 2rem;
}

.th_wrap {
  background-color: #E40011;
  height: 40px;
}
.th_wrap .th_theatre {
  padding-top: 0.15rem;
  width: 100%;
  color: #fff;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
}

.th_theatre {
  color: #333;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.4;
}
.th_theatre span {
  font-size: 1.2rem;
  line-height: 2.1;
}

.th_icon {
  position: absolute;
  top: 5rem;
  right: 0;
  width: 138px;
}

.news_list {
  margin-bottom: 2.1rem;
  padding-left: 0;
}
.news_list li {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  margin: 0 -0.93rem;
}
.news_list li p {
  margin-bottom: 1rem;
  padding: 0 0.93rem;
  font-size: 1rem;
  line-height: 1.65;
  align-self: center;
}
.news_list li .tit {
  width: 65%;
}
.news_list li .tit a {
  color: #333;
}

/* ------------------------------------------------------------
*
footer 
*
------------------------------------------------------------ */
#footer {
  margin-top: 0;
  padding: 5rem 0 4.3rem;
  height: 695px;
  background: url(/elements/img/ft_bg.jpg) no-repeat bottom;
  background-size: 100% auto;
  color: #333;
  font-size: 1rem;
  line-height: 1.4;
  text-align: center;
}
#footer #ft_sns {
  padding: 2rem 0 0;
}
#footer #ft_sns ul {
  margin-bottom: 0;
  padding-left: 0;
  text-align: center;
}
#footer #ft_sns ul li {
  display: inline-block;
  padding: 0 1.6rem;
  font-size: 2.25rem;
  line-height: 1;
}
#footer #ft_sns ul li a {
  color: #FFF;
}
#footer #ft_sns ul li a:hover {
  opacity: 0.75;
}
#footer #ft_sns ul li i {
  font-size: 2.25rem;
}
#footer .ft_logo {
  margin: 0 0 2rem;
}
#footer .ft_logo img {
  width: 16rem;
}
#footer .th {
  padding-top: 0.5rem;
}
#footer .th .other, #footer .th .billing {
  margin-bottom: 1.5rem;
}
#footer .th .billing {
  margin-bottom: 2.2rem;
}
#footer .scap {
  color: #333;
  line-height: 1.8;
}
#footer #copyright {
  padding: 1.4em 0 3.5rem;
  color: #FFF;
  font-size: 0.8rem;
  letter-spacing: initial;
}

@media (min-width: 768px) and (max-width: 1100px) {
  #header .inner {
    width: 95%;
  }
}
@media (min-width: 767px) and (max-width: 991px) {
  #gnav li {
    font-size: 1rem;
  }
  #gnav li a {
    padding: 0.4rem 0.9rem;
  }
}
@media (max-width: 767px) {
  .switch_pc {
    display: none;
  }

  .switch_sp {
    display: block;
  }

  .mt_sp_0 {
    margin-top: 0rem !important;
  }

  .mb_sp_0 {
    margin-bottom: 0rem !important;
  }

  .ml_sp_0 {
    margin-left: 0rem !important;
  }

  .mr_sp_0 {
    margin-right: 0rem !important;
  }

  .pt_sp_0 {
    padding-top: 0rem !important;
  }

  .pb_sp_0 {
    padding-bottom: 0rem !important;
  }

  .pl_sp_0 {
    padding-left: 0rem !important;
  }

  .pr_sp_0 {
    padding-right: 0rem !important;
  }

  .mt_sp_1 {
    margin-top: 1rem !important;
  }

  .mb_sp_1 {
    margin-bottom: 1rem !important;
  }

  .ml_sp_1 {
    margin-left: 1rem !important;
  }

  .mr_sp_1 {
    margin-right: 1rem !important;
  }

  .pt_sp_1 {
    padding-top: 1rem !important;
  }

  .pb_sp_1 {
    padding-bottom: 1rem !important;
  }

  .pl_sp_1 {
    padding-left: 1rem !important;
  }

  .pr_sp_1 {
    padding-right: 1rem !important;
  }

  .mt_sp_2 {
    margin-top: 2rem !important;
  }

  .mb_sp_2 {
    margin-bottom: 2rem !important;
  }

  .ml_sp_2 {
    margin-left: 2rem !important;
  }

  .mr_sp_2 {
    margin-right: 2rem !important;
  }

  .pt_sp_2 {
    padding-top: 2rem !important;
  }

  .pb_sp_2 {
    padding-bottom: 2rem !important;
  }

  .pl_sp_2 {
    padding-left: 2rem !important;
  }

  .pr_sp_2 {
    padding-right: 2rem !important;
  }

  .mt_sp_3 {
    margin-top: 3rem !important;
  }

  .mb_sp_3 {
    margin-bottom: 3rem !important;
  }

  .ml_sp_3 {
    margin-left: 3rem !important;
  }

  .mr_sp_3 {
    margin-right: 3rem !important;
  }

  .pt_sp_3 {
    padding-top: 3rem !important;
  }

  .pb_sp_3 {
    padding-bottom: 3rem !important;
  }

  .pl_sp_3 {
    padding-left: 3rem !important;
  }

  .pr_sp_3 {
    padding-right: 3rem !important;
  }

  .mt_sp_4 {
    margin-top: 4rem !important;
  }

  .mb_sp_4 {
    margin-bottom: 4rem !important;
  }

  .ml_sp_4 {
    margin-left: 4rem !important;
  }

  .mr_sp_4 {
    margin-right: 4rem !important;
  }

  .pt_sp_4 {
    padding-top: 4rem !important;
  }

  .pb_sp_4 {
    padding-bottom: 4rem !important;
  }

  .pl_sp_4 {
    padding-left: 4rem !important;
  }

  .pr_sp_4 {
    padding-right: 4rem !important;
  }

  .mt_sp_5 {
    margin-top: 5rem !important;
  }

  .mb_sp_5 {
    margin-bottom: 5rem !important;
  }

  .ml_sp_5 {
    margin-left: 5rem !important;
  }

  .mr_sp_5 {
    margin-right: 5rem !important;
  }

  .pt_sp_5 {
    padding-top: 5rem !important;
  }

  .pb_sp_5 {
    padding-bottom: 5rem !important;
  }

  .pl_sp_5 {
    padding-left: 5rem !important;
  }

  .pr_sp_5 {
    padding-right: 5rem !important;
  }

  .mt_sp_6 {
    margin-top: 6rem !important;
  }

  .mb_sp_6 {
    margin-bottom: 6rem !important;
  }

  .ml_sp_6 {
    margin-left: 6rem !important;
  }

  .mr_sp_6 {
    margin-right: 6rem !important;
  }

  .pt_sp_6 {
    padding-top: 6rem !important;
  }

  .pb_sp_6 {
    padding-bottom: 6rem !important;
  }

  .pl_sp_6 {
    padding-left: 6rem !important;
  }

  .pr_sp_6 {
    padding-right: 6rem !important;
  }

  .mt_sp_7 {
    margin-top: 7rem !important;
  }

  .mb_sp_7 {
    margin-bottom: 7rem !important;
  }

  .ml_sp_7 {
    margin-left: 7rem !important;
  }

  .mr_sp_7 {
    margin-right: 7rem !important;
  }

  .pt_sp_7 {
    padding-top: 7rem !important;
  }

  .pb_sp_7 {
    padding-bottom: 7rem !important;
  }

  .pl_sp_7 {
    padding-left: 7rem !important;
  }

  .pr_sp_7 {
    padding-right: 7rem !important;
  }

  .mt_sp_8 {
    margin-top: 8rem !important;
  }

  .mb_sp_8 {
    margin-bottom: 8rem !important;
  }

  .ml_sp_8 {
    margin-left: 8rem !important;
  }

  .mr_sp_8 {
    margin-right: 8rem !important;
  }

  .pt_sp_8 {
    padding-top: 8rem !important;
  }

  .pb_sp_8 {
    padding-bottom: 8rem !important;
  }

  .pl_sp_8 {
    padding-left: 8rem !important;
  }

  .pr_sp_8 {
    padding-right: 8rem !important;
  }

  br.pc {
    display: none;
  }
  br.sp {
    display: block;
  }

  #wrapper {
    min-width: inherit;
  }

  .inner {
    width: 100%;
  }

  /* ------------------------------------------------------------
  *
  header
  *
  ------------------------------------------------------------ */
  #h_top {
    display: none !important;
  }

  #header {
    background: none;
  }
  #header.top {
    top: 0;
  }
  #header .inner {
    width: 100%;
  }
  #header .h_gnav {
    height: 100vh;
  }
  #header .logo {
    padding: 0.88rem 1.25rem 2rem;
  }
  #header .logo img {
    width: 10.5rem;
  }
  #header #gnav {
    position: absolute;
    padding: 0;
    width: 100%;
    height: 100vh;
    background-color: #E4EBEF;
    clear: both;
    z-index: 200;
  }
  #header #gnav .menu {
    margin: 0 0.95rem 2.5rem;
    padding: 1rem 0;
    width: auto;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
  }
  #header #gnav .menu li {
    float: none;
    width: 100%;
    font-weight: normal;
  }
  #header #gnav .menu li a {
    padding: 0.3rem 0;
    color: #333;
    text-align: center;
    font-size: 1.6rem;
    font-family: "Shippori Mincho", serif;
    line-height: 1.6;
  }
  #header #gnav .h_sns {
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    float: none;
    width: 100%;
    justify-content: center;
    margin: 0 auto;
  }
  #header #gnav .h_sns svg {
    font-size: 2.5rem;
  }
  #header #gnav .h_sns li {
    width: auto;
  }
  #header #gnav .h_sns li a {
    margin: 0;
    padding: 0 1.45rem;
    color: #1a4fa3;
    font-size: 2.25rem;
  }
  #header #gnav .h_sns li:last-child {
    padding-bottom: 0;
  }
  #header #gnav .h_sns li:last-child a {
    border-bottom: none;
  }

  .btn-toggle-menu {
    position: fixed;
    top: 15px;
    right: 15px;
    padding: 0.6rem 0;
    width: 55px;
    height: 55px;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    z-index: 201;
    outline: none;
  }
  .btn-toggle-menu.close {
    position: absolute;
  }
  .btn-toggle-menu.close .icon-bar {
    position: relative;
    top: 1px;
    left: 4px;
    right: 15px;
  }
  .btn-toggle-menu .icon-bar {
    display: block;
    margin: 0 0.94rem;
    width: 26px;
    height: 4px;
    border-radius: 1.6px;
    background-color: #1a4fa3;
    color: #408EA5;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
  }
  .btn-toggle-menu .icon-bar + .icon-bar {
    margin-top: 0.3rem;
  }
  .btn-toggle-menu.collapsed .top-bar {
    height: 6px;
    border-radius: 3px;
    background-color: #1a4fa3;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform-origin: 15% 140%;
    -webkit-transform-origin: 15% 140%;
    -moz-transform-origin: 15% 140%;
    -ms-transform-origin: 15% 140%;
  }
  .btn-toggle-menu.collapsed .middle-bar {
    opacity: 0;
  }
  .btn-toggle-menu.collapsed .bottom-bar {
    height: 6px;
    border-radius: 3px;
    background-color: #1a4fa3;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform-origin: 0% -70%;
    -webkit-transform-origin: 0% -70%;
    -moz-transform-origin: 0% -70%;
    -ms-transform-origin: 0% -70%;
  }
  .btn-toggle-menu.collapsed span.toggle-txt {
    margin-top: 0.1rem;
    color: #1a4fa3;
  }
  .btn-toggle-menu .top-bar {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  .btn-toggle-menu .middle-bar {
    opacity: 1;
  }
  .btn-toggle-menu .bottom-bar {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  .btn-toggle-menu:focus {
    outline: none;
  }

  span.toggle-txt {
    display: block;
    margin-top: 0.3rem;
    color: #1a4fa3;
    text-align: center;
    font-size: 0.75rem;
  }

  .nav-menu-enable {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.7s;
    transition: max-height 0.7s;
  }
  .nav-menu-enable.is-hide .nav-menu-content {
    display: none;
  }
  .nav-menu-enable.is-show {
    display: block;
  }

  .btn-cat a {
    width: 115px;
  }

  /* ------------------------------------------------------------
  *
  common
  *
  ------------------------------------------------------------ */
  .tit_wrp {
    margin-bottom: 2.5rem;
  }
  .tit_wrp .title {
    position: relative;
    padding: 0;
    font-size: 3.75rem;
    line-height: 0.6;
  }
  .tit_wrp .title::after {
    top: -2px;
    height: 100%;
  }
  .tit_wrp .title_jp {
    position: relative;
    margin-bottom: 2.4rem;
  }

  .button-sns {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    padding: 1.1rem 0 1.5rem;
    width: 100%;
    transform: translateY(0);
    text-align: center;
    z-index: initial;
  }
  .button-sns a {
    display: inline-block;
    padding: 0 0.8rem;
    color: #0072E4;
    transform: rotate(0deg);
  }
  .button-sns a.ico_twit, .button-sns a.ico_insta {
    margin-top: 0.6rem;
  }
  .button-sns a i {
    font-size: 2.5rem;
  }
  .button-sns a svg {
    font-size: 2.5rem !important;
  }

  .button-sns__text,
.button-share__text {
    writing-mode: horizontal-tb;
    font-size: 1.3rem;
    letter-spacing: 1px;
  }

  .button-share {
    display: block;
    margin-top: 1.6rem;
    padding-top: 0;
  }
  .button-share .list-share {
    display: flex;
    position: relative;
    left: auto;
    top: auto;
    margin-top: 0.8rem;
    padding: 0;
    opacity: 1;
    pointer-events: auto;
    justify-content: center;
    align-items: center;
  }
  .button-share .list-share .list-share__item {
    margin-left: 0.5rem;
  }
  .button-share .list-share .list-share__item:nth-of-type(2) {
    margin-top: 0.6rem;
  }

  .th {
    padding: 1.2rem 0 0;
  }
  .th .actor {
    margin-bottom: 1.1rem;
  }
  .th .director {
    margin-bottom: 0.1rem;
  }
  .th .other, .th .billing {
    margin-bottom: 1.2rem;
  }
  .th .billing {
    margin-bottom: 2.1rem;
    line-height: 1.6;
  }
  .th .about {
    padding-bottom: 1.5rem;
    text-align: left;
  }
  .th .about p {
    padding-bottom: 2rem;
  }
  .th .about p:last-child {
    padding-bottom: 0;
  }
  .th .btn {
    margin-bottom: 1.6rem;
  }

  .th_theatre {
    padding-bottom: 1.75rem;
  }

  .th_icon {
    position: relative;
    float: right;
    top: 0;
    padding-bottom: 1rem;
    width: 115px;
  }

  .news_list {
    margin-bottom: 0;
  }
  .news_list li {
    flex-wrap: wrap;
  }
  .news_list li p {
    margin-bottom: 0.6rem;
  }
  .news_list li .tit {
    display: block;
    margin-bottom: 2rem;
    width: 100%;
  }

  /* ------------------------------------------------------------
  *
  footer
  *
  ------------------------------------------------------------ */
  #footer {
    padding: 4rem 0 5rem;
    background-size: 120%;
    height: auto;
  }
  #footer .ft_logo {
    margin-bottom: 2.8rem;
    padding: 0 1rem;
  }
  #footer .ft_logo img {
    width: 13rem;
  }
  #footer .th {
    padding: 0 0 0.5rem;
  }
  #footer .th .actor {
    margin-bottom: 0.5rem;
  }
  #footer .th .other, #footer .th .billing {
    margin-bottom: 0.9rem;
  }
  #footer .th .billing {
    margin: 1.5rem 0 2.8rem;
  }
  #footer .th ul {
    padding-bottom: 0.7rem;
  }
  #footer .th ul .support {
    font-size: 0.6rem;
    padding-bottom: 0.8rem;
    text-align: left;
    line-height: 1.6;
  }
  #footer .th ul .staff {
    margin-bottom: 1.5rem;
    text-align: left;
    line-height: 1.6;
  }
  #footer .th_theatre {
    line-height: 1.45;
  }
  #footer .scap {
    text-align: left;
  }
  #footer #copyright {
    padding-bottom: 1.7em;
  }
}
@media (max-width: 375px) {
  #header #gnav {
    overflow-y: auto;
  }
  #header #gnav .h_sns {
    padding-bottom: 6.3rem;
  }
}