/* === Noto Sans JP - regular */
@font-face {
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("./../css/font//NotoSansJp-Regular.eot");
	src: local('Noto Sans JP'),
		url("./../css/font//NotoSansJp-Regular.eot") format("embedded-opentype"),
		url("./../css/font//NotoSansJp-Regular.woff2") format("woff2"),
		url("./../css/font//NotoSansJp-Regular.woff") format("woff"),
		url("./../css/font//NotoSansJp-Regular.svg") format("svg");
}

/* === Noto Sans JP - 500 */
@font-face {
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url("./../css/font//NotoSansJp-Medium.eot");
	src: local('Noto Sans JP'),
		url("./../css/font//NotoSansJp-Medium.eot") format("embedded-opentype"),
		url("./../css/font//NotoSansJp-Medium.woff2") format("woff2"),
		url("./../css/font//NotoSansJp-Medium.woff") format("woff"),
		url("./../css/font//NotoSansJp-Medium.svg") format("svg");
}

/* === Noto Sans JP - 700 */
@font-face {
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("./../css/font//NotoSansJp-Bold.eot");
	src: local('Noto Sans JP'),
		url("./../css/font//NotoSansJp-Bold.eot") format("embedded-opentype"),
		url("./../css/font//NotoSansJp-Bold.woff2") format("woff2"),
		url("./../css/font//NotoSansJp-Bold.woff") format("woff"),
		url("./../css/font//NotoSansJp-Bold.svg") format("svg");
}

/* === Pinyon Script - regular */
@font-face {
	font-family: 'Pinyon Script';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("./../css/font//PinyonScript-Regular.eot");
	src: local('Pinyon Script'),
		url("./../css/font//PinyonScript-Regular.eot") format("embedded-opentype"),
		url("./../css/font//PinyonScript-Regular.woff2") format("woff2"),
		url("./../css/font//PinyonScript-Regular.woff") format("woff"),
		url("./../css/font//PinyonScript-Regular.ttf") format("truetype"),
		url("./../css/font//PinyonScript-Regular.svg") format("svg");
}

/* === Shippori Mincho - regular */
@font-face {
	font-family: 'Shippori Mincho';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("./../css/font//ShipporiMincho-Regular.eot");
	src: local('Shippori Mincho'),
		url("./../css/font//ShipporiMincho-Regular.eot") format("embedded-opentype"),
		url("./../css/font//ShipporiMincho-Regular.woff2") format("woff2"),
		url("./../css/font//ShipporiMincho-Regular.woff") format("woff"),
		url("./../css/font//ShipporiMincho-Regular.ttf") format("truetype"),
		url("./../css/font//ShipporiMincho-Regular.svg") format("svg");
}

/* === Shippori Mincho - 500 */
@font-face {
	font-family: 'Shippori Mincho';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url("./../css/font//ShipporiMincho-Medium.eot");
	src: local('Shippori Mincho'),
		url("./../css/font//ShipporiMincho-Medium.eot") format("embedded-opentype"),
		url("./../css/font//ShipporiMincho-Medium.woff2") format("woff2"),
		url("./../css/font//ShipporiMincho-Medium.woff") format("woff"),
		url("./../css/font//ShipporiMincho-Medium.ttf") format("truetype"),
		url("./../css/font//ShipporiMincho-Medium.svg") format("svg");
}

/* === Shippori Mincho - 700 */
@font-face {
	font-family: 'Shippori Mincho';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("./../css/font//ShipporiMincho-Bold.eot");
	src: local('Shippori Mincho'),
		url("./../css/font//ShipporiMincho-Bold.eot") format("embedded-opentype"),
		url("./../css/font//ShipporiMincho-Bold.woff2") format("woff2"),
		url("./../css/font//ShipporiMincho-Bold.woff") format("woff"),
		url("./../css/font//ShipporiMincho-Bold.ttf") format("truetype"),
		url("./../css/font//ShipporiMincho-Bold.svg") format("svg");
}
