@import 'variable';
@import 'mixin';
/* ------------------------------------------------------------
*
common parts
*
------------------------------------------------------------ */

%html {
    height: 100%;
    font-family: $base_font;
}

html {
    @extend %html;
}

body {
    @extend %html;
    background-color: $site_bg_color;
    color: $main_color_2;
}

*,
::after,
::before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

#wrapper {
    margin: 0 auto;
    width: 100%;
    height: 100%;
}

a {
    color: $link_color;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;

    &:hover {
        color: $link_color_hover;

        img,
        i {
            opacity: .6;
        }
    }
}

.flwrap {
    @include flex();
    flex-wrap: wrap;
}

.flcenter {
    @include flex();
    flex-wrap: wrap;
    justify-content: center;
}

.scap {
    font-size: .6rem;
}

.cap {
    font-size: .94rem;
}

.caution {
    font-size: .9em;
}

.vbtm {
    vertical-align: bottom;
}

.vmid {
    vertical-align: middle;
}

.nobd {
    border: none !important;
}

ul {
    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

.inner {
    margin: 0 auto;
    padding: 0 1rem;
    max-width: $inner_width;
    box-sizing: border-box;
}

br {
    &.sp {
        display: none;
    }

    &.pc {
        display: block;
    }
}

.switch_pc {
    display: block;
}

.switch_sp {
    display: none;
}

img {
    max-width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    vertical-align: middle;
}

.center {
    text-align: center;
}

.btn {
    margin: 0 auto;
    width: 260px;

    a {
        display: block;
        padding: .55rem 0;
        background: $key_color_1;
        color: #FFF;
        font-size: 1.2rem;
        text-align: center;
        text-decoration: none;
        font-weight: bold;
        line-height: 1.6;

        &:hover {
            opacity: .7;
        }
    }
}

.btn-outline {
    display: inline-block;

    a {
        padding: .49rem 2.2rem;
        @extend %btn_outline;
        border-radius: 10px;
        font-size: .9rem;

        &:hover {
            @extend %btn_outline_hov;
            text-decoration: none;
        }
    }
}

.btn-cat {
    a {
        width: 135px;
        @extend %btn_outline;
        border-radius: 5px;
        font-size: .8rem;

        &:hover {
            @extend %btn_outline_hov;
        }
    }
}

%btn_outline {
    display: block;
    border: 1px solid $key_color_1;
    background-color: transparent;
    color: $key_color_1;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    line-height: 1.6;
}

%btn_outline_hov {
    opacity: .7;
    color: $key_color_1;
    text-decoration: none;
}

.btn_wrap {
    text-align: center;
}


/* ------------------------------------------------------------
*
header
*
------------------------------------------------------------ */

#header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 2.5rem;
    background-color: $gnav_color_bg_pc;
    font-family: $nav_font;
    z-index: 2;
    -webkit-transition: top 0.3s;
    -moz-transition: top 0.3s;
    -o-transition: top 0.3s;
    transition: top 0.3s;

    // &.top {
    //     top: -5.5rem;
    // }

    .inner {
        padding: 0;
        // max-width: 1280px;
    }
}

// #h_top {
//     position: fixed;
//     top: 0;
//     padding: 0;
//     width: 100%;
//     min-height: 2.5rem;
//     z-index: 4;

//     .inner {
//         padding: 0;
//     }

//     #gnav {
//         ul {
//             li {
//                 a {
//                     color: $gnav_color;
//                 }
//             }
//         }
//     }

//     .logo {
//         img {
//             &.logo_sp {
//                 display: none;
//             }
//         }
//     }
// }

#gnav {
    padding: .3rem 0;

    &:after {
        content: "";
        display: block;
        clear: both;
    }

    .h_sns {
        float: right;

        li {
            margin-bottom: 0;
            line-height: 1;
            font-weight: normal;

            a {
                padding: 0 $gnav_margin;
                color: $gnav_color_txt;
                font-size: 1.88rem;
            }
        }
    }

    li {
        float: left;
        margin-bottom: 0;
        font-size: 1.13rem;
        font-weight: bold;
        line-height: 1;

        a {
            display: block;
            padding: .4rem $gnav_margin;
            text-decoration: none;
            color: $gnav_color_txt;
            font-family: $nav_font;
        }
    }
}

/* ------------------------------------------------------------
*
common 
*
------------------------------------------------------------ */
// .t_wrap {
//     position: relative;
//     padding-bottom: .5rem;
//     margin-bottom: 3rem;
//     border-bottom: 1px solid $border_color;
// }

.tit_wrp {
    position: relative;
    margin-bottom: 3rem;
    .title {
        display: block;
        margin-bottom: 0;
        padding: 0;
        height: auto;
        color: $key_color_2;
        font-size: 6rem;
        font-family: $tit_font;
        font-weight: normal;
    }
    .title:after{
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        display: block;
        width: 100%;
        height: 73px;
        border-bottom: 2px solid $key_color_2;
        box-sizing: border-box;
        clear: both;
    }
    
    .title_jp {
        position: absolute;
        bottom: -2px;
        left: 0;
        margin: 0;
        text-align: left;
        color: #000;
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.3;
    }
}

.color_tit {
    margin-bottom: 2.4rem;
    color: $key_color_1;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.46;
}

.bg {
    background-color: $key_color_1;
}

.bg_2 {
    background-color: $key_color_2;
}

.button-sns {
	display: -ms-flexbox;
	display: flex;
	position: fixed;
	top: 50%;
	left: 0;
    padding: 18px 0 20px;
    width: 32px;
    background-color: rgba($color: #fff, $alpha: .9);
    color: #555;
	font-family: 'Shippori Mincho', serif;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	transform: translateY(-50%);
	z-index: 9;

    a {
        display: -ms-flexbox;
        display: flex;
        color: #555;
        white-space: nowrap;
        text-decoration: none;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        transition: opacity .2s cubic-bezier(.39, .575, .565, 1);
        transform: rotate(90deg);

        i {
            font-size: 1.25rem;
        }

        svg {
            font-size: 1.25rem !important;
        }

        &.ico_twit {
            margin-top: .8rem;
        }

        &.ico_insta {
            margin-top: .6rem;
        }
    }
}

.button-sns__text,
.button-share__text {
	-ms-writing-mode: tb-rl;
	writing-mode: vertical-rl;
	font-size: 1.13rem;
	line-height: 1;
	letter-spacing: .1em;
}

.button-share {
	display: -ms-flexbox;
	display: flex;
	position: relative;
	margin-top: 20px;
	padding-top: 20px;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;

    .list-share {
        opacity: 0;
        pointer-events: none;
        transition: opacity .2s cubic-bezier(.39, .575, .565, 1);
        z-index: 2;
        display: block;
        position: absolute;
        left: 100%;
        top: calc(100% - 2.2rem);
        margin-top: 0;
        padding-left: 12px;
    }
    &.on {
        .list-share {
            opacity: 1;
            pointer-events: auto;
        }
    }
}

.button-share__icon {
	margin-top: .7rem;
	font-size: 1.25rem;
	transform: rotate(90deg);
}

.list-share__item {
	line-height: 0;
	margin-left: .2rem;
    &:nth-of-type(2) {
        margin-top: .5rem;
        margin-left: 0;
    }
}

.th {
    padding: 3.2rem 0 2.5rem;
    text-align: center;
    font-size: .88rem;
    color: $main_color_2;

    .pff {
        margin-bottom: 1.2rem;
        font-size: 1rem;
        font-weight: normal;
    }

    .director {
        margin-bottom: .3rem;
        font-size: 1.4rem;
        font-weight: normal;
        line-height: 1.6;
    }

    .actor {
        font-size: 1rem;
        line-height: 1.5;
        margin: 0 0 .4rem;
    }

    .other {
        font-size: .88rem;
        font-weight: normal;
        line-height: 1.4;
        margin-bottom: 1.2rem;
    }

    .billing {
        margin-bottom: 2rem;
        @extend .other;
        // opacity: .75;
    }
}

.th_wrap {
    background-color: #E40011;
    height: 40px;

    .th_theatre {
        padding-top: 0.15rem;
        width: 100%;
        color: #fff;
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1.25;
        text-align: center;
    }
}

.th_theatre {
    color: $main_color_2;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.4;

    span {
        font-size: 1.2rem;
        line-height: 2.1;
    }
}

.th_icon {
    position: absolute;
    top: 5rem;
    right: 0;
    width: 138px;
}

.news_list {
    margin-bottom: 2.1rem;
    padding-left: 0;

    li {
        @include flex;
        margin: 0 -.93rem;

        p {
            margin-bottom: 1rem;
            padding: 0 .93rem;
            font-size: 1rem;
            line-height: 1.65;
            align-self: center;
        }

        .tit {
            width: 65%;

            a {
                color: $main_color_2;
            }
        }
    }
}


/* ------------------------------------------------------------
*
footer 
*
------------------------------------------------------------ */

#footer {
    margin-top: 0;
    padding: 5rem 0 4.3rem;
    height: 695px;
    background: url(/elements/img/ft_bg.jpg) no-repeat bottom;
    background-size: 100% auto;
    color: $main_color_2;
    font-size: 1rem;
    line-height: 1.4;
    text-align: center;
    // letter-spacing: 1px;

    #ft_sns {
        padding: 2rem 0 0;

        ul {
            margin-bottom: 0;
            padding-left: 0;
            text-align: center;

            li {
                display: inline-block;
                padding: 0 1.6rem;
                font-size: 2.25rem;
                line-height: 1;

                a {
                    color: $main_color;

                    &:hover {
                        opacity: .75;
                    }
                }

                i {
                    font-size: 2.25rem;
                }
            }
        }
    }

    .ft_logo {
        margin: 0 0 2rem;
        img {
            width: 16rem;
        }
    }

    .th {
        padding-top: .5rem;
        .other {
            margin-bottom: 1.5rem;
        }
        .billing {
            margin-bottom: 2.2rem;
        }
    }

    .scap {
        color: $main_color_2;
        line-height: 1.8;
    }

    #copyright {
        padding: 1.4em 0 3.5rem;
        color: $main_color;
        font-size: .8rem;
        letter-spacing: initial;
    }
}

@media (min-width: 768px) and (max-width: 1100px) {
    #header {
        .inner {
            width: 95%;
        }
    }
}

@media (min-width: 767px) and (max-width: 991px) {
    #gnav {
        li {
            font-size: 1rem;
            a {
                padding: .4rem .9rem;
            }
        }
    }
}

@media (max-width: 767px) {
    .switch_pc {
        display: none;
    }

    .switch_sp {
        display: block;
    }

    @include space-sp-list;

    br {
        &.pc {
            display: none;
        }

        &.sp {
            display: block;
        }
    }

    #wrapper {
        min-width: inherit;
    }

    .inner {
        width: 100%;
    }

    /* ------------------------------------------------------------
    *
    header
    *
    ------------------------------------------------------------ */
    #h_top {
        display: none !important;
    }

    #header {
        background: none;

        &.top {
            top: 0;
        }

        .inner {
            width: 100%;
        }

        .h_gnav {
            height: 100vh;
        }

        .logo {
            padding: .88rem 1.25rem 2rem;

            img {
                width: 10.5rem;
            }
        }

        #gnav {
            position: absolute;
            padding: 0;
            width: 100%;
            height: 100vh;
            background-color: $gnav_color_bg_sp;
            clear: both;
            z-index: 200;

            .menu {
                margin: 0 .95rem 2.5rem;
                padding: 1rem 0;
                width: auto;
                border-top: 1px solid $main_color_2;
                border-bottom: 1px solid $main_color_2;

                li {
                    float: none;
                    width: 100%;
                    font-weight: normal;

                    a {
                        padding: .3rem 0;
                        color: $main_color_2;
                        text-align: center;
                        font-size: 1.6rem;
                        font-family: $base_font;
                        line-height: 1.6;
                    }
                }
            }

            .h_sns {
                @include flex();
                float: none;
                width: 100%;
                justify-content: center;
                margin: 0 auto;

                svg {
                    font-size: 2.5rem;
                }

                li {
                    width: auto;

                    a {
                        margin: 0;
                        padding: 0 1.45rem;
                        color: $key_color_1;
                        font-size: 2.25rem;
                    }

                    &:last-child {
                        padding-bottom: 0;

                        a {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }

    .btn-toggle-menu {
        position: fixed;
        top: 15px;
        right: 15px;
        padding: .6rem 0;
        width: 55px;
        height: 55px;
        border-radius: 3px;
        background-color: rgba($color: $main_color, $alpha: .8);
        cursor: pointer;
        z-index: 201;
        outline: none;

        &.close {
            position: absolute;

            .icon-bar {
                position: relative;
                top: 1px;
                left: 4px;
                right: 15px;
            }
        }

        .icon-bar {
            display: block;
            margin: 0 .94rem;
            width: 26px;
            height: 4px;
            border-radius: 1.6px;
            background-color: $key_color_1;
            color: $gnav_color;
            transition: all .2s;
            -webkit-transition: all .2s;

            &+.icon-bar {
                margin-top: .3rem;
            }
        }

        &.collapsed {

            .top-bar {
                height: 6px;
                border-radius: 3px;
                background-color: $key_color_1;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                transform-origin: 15% 140%;
                -webkit-transform-origin: 15% 140%;
                -moz-transform-origin: 15% 140%;
                -ms-transform-origin: 15% 140%;
            }

            .middle-bar {
                opacity: 0;
            }

            .bottom-bar {
                height: 6px;
                border-radius: 3px;
                background-color: $key_color_1;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                transform-origin: 0% -70%;
                -webkit-transform-origin: 0% -70%;
                -moz-transform-origin: 0% -70%;
                -ms-transform-origin: 0% -70%;
            }

            span {
                &.toggle-txt {
                    margin-top: .1rem;
                    color: $key_color_1;
                }
            }
        }

        .top-bar {
            transform: rotate(0);
            -webkit-transform: rotate(0);
        }

        .middle-bar {
            opacity: 1;
        }

        .bottom-bar {
            transform: rotate(0);
            -webkit-transform: rotate(0);
        }

        &:focus {
            outline: none;
        }
    }

    span {
        &.toggle-txt {
            display: block;
            margin-top: .3rem;
            color: $key_color_1;
            text-align: center;
            font-size: .75rem;
            // font-weight: bold;
        }
    }

    .nav-menu-enable {
        max-height: 0;
        overflow: hidden;
        -webkit-transition: max-height .7s;
        transition: max-height .7s;

        &.is-hide {
            .nav-menu-content {
                display: none;
            }
        }

        &.is-show {
            display: block;
        }
    }

    .btn-cat {
        a {
            width: 115px;
        }
    }

    /* ------------------------------------------------------------
    *
    common
    *
    ------------------------------------------------------------ */

    .tit_wrp {
        margin-bottom: 2.5rem;
        .title {
            position: relative;
            padding: 0;
            font-size: 3.75rem;
            line-height: .6;
            &::after{
                top: -2px;
                height: 100%;
            }
        }
    
        .title_jp {
            position: relative;
            margin-bottom: 2.4rem;
        }
    }


    .button-sns {
		position: relative;
		top: 0;
		left: 0;
		display: block;
        padding: 1.1rem 0 1.5rem;
        width: 100%;
		transform: translateY(0);
		text-align: center;
        z-index: initial;

        a {
            display: inline-block;
            padding: 0 .8rem;
            color: #0072E4;
            transform: rotate(0deg);

            &.ico_twit,
            &.ico_insta {
                margin-top: .6rem;
            }

            i {
                font-size: 2.5rem;
            }

            svg {
                font-size: 2.5rem !important;
            }
        }
	}

	.button-sns__text,
	.button-share__text {
		writing-mode: horizontal-tb;
		font-size: 1.3rem;
		letter-spacing: 1px;
	}

	.button-share {
		display: block;
		margin-top: 1.6rem;
		padding-top: 0;

        .list-share {
            display: flex;
            position: relative;
            left: auto;
            top: auto;
            margin-top: .8rem;
            padding: 0;
            opacity: 1;
            pointer-events: auto;
            justify-content: center;
            align-items: center;

            .list-share__item {
                margin-left: .5rem;
                &:nth-of-type(2) {
                    margin-top: .6rem;
                }
            }
        }
	}

    .th {
        padding: 1.2rem 0 0;

        .actor {
            margin-bottom: 1.1rem;
        }

        .director {
            margin-bottom: .1rem;
        }

        .other {
            margin-bottom: 1.2rem;
        }

        .billing {
            margin-bottom: 2.1rem;
            line-height: 1.6;
        }

        .about {
            padding-bottom: 1.5rem;
            text-align: left;

            p {
                padding-bottom: 2rem;

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }

        .btn {
            margin-bottom: 1.6rem;
        }
    }

    .th_theatre {
        padding-bottom: 1.75rem;
    }

    .th_icon {
        position: relative;
        float: right;
        top: 0;
        padding-bottom: 1rem;
        width: 115px;
    }

    .news_list {
        margin-bottom: 0;

        li {
            flex-wrap: wrap;

            p {
                margin-bottom: .6rem;
            }

            .tit {
                display: block;
                margin-bottom: 2rem;
                width: 100%;
            }
        }
    }


    /* ------------------------------------------------------------
    *
    footer
    *
    ------------------------------------------------------------ */

    #footer {
        padding: 4rem 0 5rem;
        background-size: 120%;
        height: auto;

        .ft_logo {
            margin-bottom: 2.8rem;
            padding: 0 1rem;

            img {
                width: 13rem;
            }
        }

        .th {
            padding: 0 0 .5rem;

            .actor {
                margin-bottom: .5rem;
            }

            .other {
                margin-bottom: .9rem;
            }

            .billing {
                margin: 1.5rem 0 2.8rem;
            }

            ul {
                padding-bottom: .7rem;

                .support {
                    font-size: .6rem;
                    padding-bottom: .8rem;
                    text-align: left;
                    line-height: 1.6;
                }

                .staff {
                    margin-bottom: 1.5rem;
                    text-align: left;
                    line-height: 1.6;
                }
            }
        }

        .th_theatre {
            line-height: 1.45;
        }

        .scap {
            text-align: left;
        }

        #copyright {
            padding-bottom: 1.7em;
        }
    }
}

@media (max-width: 375px) {
    #header {
        #gnav {
            overflow-y: auto;

            .h_sns {
                padding-bottom: 6.3rem;
            }
        }
    }
}